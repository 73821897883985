import React from 'react'

const LinkList = ({data, aleles, calcRiskScore}) => {
  const tableData = data
    .map((item, index) => {
      const [variantInfo, details] = item
       const rsID = variantInfo[0] // rsID
      const chromosome = details[0] // Kromozom numarası
      const position = details[1] // Pozisyon
      const change = `${details[3]}>${details[2]}` // Alel değişimi
      const betaZ = calcRiskScore[index] // β*z değeri
      const allelesValue = variantInfo[3] // Aleller (AG, TT, vb.)
      return {
        rank: index + 1, // Sıralama numarası
        betaZ: betaZ !== undefined ? betaZ.toFixed(3) : 0, // β*z değeri, 3 basamaklı olarak
        variant: `Chr${chromosome}.${position}:g.${change}`, // Variant formatı
        variantLink: `chr${chromosome}:g.${position}${change}`, // Variant formatı
        rsID,
        allelesValue,
      }

    })
    .filter((_, index) => aleles[index] !== 0 && aleles[index] !== undefined) 
    .sort((a, b) => b.betaZ - a.betaZ)

  return (
    <div
      style={{
        maxHeight: '400px',
        overflowY: 'auto',
      }}
    >
      <table style={{width: '100%', borderCollapse: 'collapse'}}>
        <thead>
          <tr style={{borderBottom: '2px solid black'}}>
            <th className='text-center'>#</th>
            <th className='text-center'>Rank </th>
            <th className='text-center'> β*z</th>
            <th className='text-center'>Variant</th>
            <th className='text-center'>SNP</th>
            <th className='text-center'>SNPedia</th>
            <th className='text-center'> Genotypes</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} style={{textAlign: 'center', borderBottom: '1px solid #ccc'}}>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                {index +1}
                {')'}
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >{`(${row.rank})`}</td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                {row.betaZ}
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                <a
                  href={`https://myvariant.info/v1/variant/${row.variantLink}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {row.variant}
                </a>
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                <a
                  href={`https://www.ncbi.nlm.nih.gov/snp/${row.rsID}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {row.rsID}
                </a>
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                <a
                  href={`https://www.snpedia.com/index.php/${row.rsID}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  snpedia
                </a>
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                {row.allelesValue}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LinkList
