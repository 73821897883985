import React from 'react'

const ReportButtons = ({getReport, setSelectedPreset, presets}) => {
  return (
    <div className='card px-5'>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2'>
        <h3 className='card-title align-items-start flex-column'>Reports</h3>
      </div>

      <div className='card-body pt-0  d-flex justify-content-center flex-column gap-3'>
        <div>
          <select
            className='form-select px-2'
            onChange={(e) => {
              if (e.target.value === '0') {
                setSelectedPreset({
                  id:0,
                  name: '',
                })
                return
              }
              setSelectedPreset(presets.find((preset) => preset.id === e.target.value))
            }}
          >
            <option value='0'>Select Template</option>
            {presets.map((preset) => (
              <option key={preset.id} value={preset.id}>
                {preset.name}
              </option>
            ))}
          </select>
        </div>

        <button className='btn btn-primary mx-2'>JSON</button>
        <button className='btn btn-secondary mx-2' onClick={() => getReport()}>
          Report
        </button>
      </div>
    </div>
  )
}

export {ReportButtons}
