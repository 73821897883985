import { useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../_metronic/layout/core'
import somaticService from '../../service/somatic'
import { useParams } from 'react-router'
import { Loader } from '../../components/Loader'
import { ClinicDrawer } from '../../components/SomaticTable/Widgets/ClinicDrawer'
import { Tooltip } from 'react-tooltip'
import ClinicAdvancedFilterModal from '../../components/SomaticTable/Widgets/ClinicAdvancedFilterModal'
import { canverEvTooltips } from '../../components/TableComponent/Formatters'
import TableComponentClinicalPage from '../../components/TableComponent/TableComponentClinicalPage'
import Swal from 'sweetalert2'
import PillIcon from '../../components/Icons/pillIcon'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import CosmicChart from '../../components/SomaticTable/Widgets/CosmicChart'
import RunsService from '../../service/runs'
import ProteinModal from './ProteinModal'
import { OverlayTrigger } from 'react-bootstrap'
const SomaticDetailClinicPage = () => {
  const { id } = useParams()
  const [tableData, setTableData] = useState({ snvData: [], svData: [], fusionsData: [] })
  const [originalData, setOriginalData] = useState([])
  const [runDetail, setRunDetail] = useState({})
  const [proteinModalOpen, setProteinModalOpen] = useState(false)
  const [selectedGene, setSelectedGene] = useState('')
  const [selectedFilteredData, setSelectedFilteredData] = useState([])
  const [totalVariants, setTotalVariants] = useState(0)
  const [filteredTotalVariants, setFilteredTotalVariants] = useState(0)
  const [adScore, setAdScore] = useState({
    min: 0,
    max: 0,
  })
  const [afScore, setAfScore] = useState({
    min: 0,
    max: 0,
  })
  const [sortStates, setSortStates] = useState({
    gene: 0,
    drugs: 1,
    consequence: 0,
  })
  const sortByDrugs = (data, sortState) => {
    const sortOrder = sortState === 1 ? 1 : -1 // 1: Artan, -1: Azalan

    return data.sort((a, b) => {
      // Kategorilere ayır
      const aHasResistance = a.HighestResistanceLevel !== null
      const aHasSensitive = a.HighestSensitiveLevel !== null
      const bHasResistance = b.HighestResistanceLevel !== null
      const bHasSensitive = b.HighestSensitiveLevel !== null

      const aCategory =
        aHasResistance && aHasSensitive ? 3 : aHasResistance ? 2 : aHasSensitive ? 1 : 0
      const bCategory =
        bHasResistance && bHasSensitive ? 3 : bHasResistance ? 2 : bHasSensitive ? 1 : 0

      // Öncelik sırasına göre sıralama (önce büyük olan gelsin)
      if (aCategory !== bCategory) {
        return (bCategory - aCategory) * sortOrder
      }

      // Aynı kategorideyse HighestResistanceLevel'e göre sırala
      if (a.HighestResistanceLevel !== b.HighestResistanceLevel) {
        return sortOrder * (a.HighestResistanceLevel - b.HighestResistanceLevel)
      }

      // Eğer HighestResistanceLevel eşitse, HighestSensitiveLevel'e göre sırala
      return sortOrder * (a.HighestSensitiveLevel - b.HighestSensitiveLevel)
    })
  }

  const sortByGene = (data, sortState) => {
    const sortOrder = sortState === 1 ? 1 : -1 // 1: A-Z, -1: Z-A

    return data.sort((a, b) => {
      const geneA = a.Gene_refGene[0] || ''
      const geneB = b.Gene_refGene[0] || ''
      return geneA.localeCompare(geneB) * sortOrder
    })
  }
  const sortByConsequence = (data, sortState) => {
    const sortOrder = sortState === 1 ? 1 : -1 // 1: Normal sıralama, -1: Ters sıralama

    // Öncelik sıralaması (yüksek öncelik küçük değer alsın)
    const consequencePriority = {
      plof: 4,
      missense: 3,
      others: 2,
      synonymous: 1,
    }

    return data.sort((a, b) => {
      // Öncelik sıralaması (yüksek olanlar önce)
      const aPriority = consequencePriority[a.type] || 0
      const bPriority = consequencePriority[b.type] || 0

      if (aPriority !== bPriority) {
        return (bPriority - aPriority) * sortOrder
      }

      // Eğer öncelikler eşitse, Gene_refGene[0] alfabetik sıralansın
      const geneA = a.Gene_refGene[0] || ''
      const geneB = b.Gene_refGene[0] || ''
      return geneA.localeCompare(geneB) * sortOrder
    })
  }
  const toggleSort = (field) => {
    setSortStates((prevState) => {
      // Önce mevcut state'i koruyalım
      const newState = { ...prevState }

      // Tıklanan sütunu sıfırlıyoruz, diğer sütunları değiştirmiyoruz
      newState[field] = prevState[field] === 1 ? 2 : 1

      // Diğer sütunları sıfırlıyoruz
      Object.keys(newState).forEach((key) => {
        if (key !== field) {
          newState[key] = 0
        }
      })

      return newState
    })
  }

  const fetchRunDetail = async () => {
    try {
      const requestId = id.split('&')
      const runDetailResponse = await RunsService.getRunDetail(requestId[0])
      if (runDetailResponse.status === 200) {
        setRunDetail(runDetailResponse.data)
      }
      return runDetailResponse.data
    } catch (error) {
      console.error('Failed to fetch run details:', error)
    }
  }

  const [openCosmicDropdown, setOpenCosmicDropdown] = useState(null)
  const cosmicDropdownRef = useRef(null) // Dropdown'un referansı

  const [openAccordion, setOpenAccordion] = useState(null)
  const [totalGenes, setTotalGenes] = useState(0)
  const [groupedData, setGroupedData] = useState({})
  const [filteredTotalGenes, setFilteredTotalGenes] = useState(0)
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [cosmicData, setCosmicData] = useState([])
  const [gridOptions, setGridOptions] = useState(undefined)
  // const [lodSwitch, setLodSwitch] = useState(true)
  const levelPriority = {
    LEVEL_1: { priority: 1, bgColor: '#35a12c' },
    LEVEL_2: { priority: 2, bgColor: '#1f78b6' },
    LEVEL_3A: { priority: 3, bgColor: '#984ea3' },
    LEVEL_3B: { priority: 4, bgColor: '#be98d0' },
    LEVEL_4: { priority: 5, bgColor: '#424242' },
    LEVEL_R1: { priority: 6, bgColor: '#f03523' },
    LEVEL_R2: { priority: 7, bgColor: '#f99a92' },
  }
  const levelReadable = {
    LEVEL_1: 'Level1',
    LEVEL_2: 'Level2',
    LEVEL_3A: 'Level3A',
    LEVEL_3B: 'Level3B',
    LEVEL_4: 'Level4',
    LEVEL_R1: 'LevelR1',
    LEVEL_R2: 'LevelR2',
  }
  const [snvHeader, setSnvHeader] = useState({
    // 'ExonicFunc_refGene',
    // 'txChange',
    // 'aaChange',
    // 'AD',
    // 'DP',
    // 'REF',
    // 'ALT',
    reportSelect: {
      value: 'Report',
    },
    external:{
      value: 'External',
    },
    igv: {
      value: 'IGV',
    },
    // vampp: {
    //   value: 'vampp',
    // },
    Variant_Type: {
      value: 'Variant Type',
    },
    consequence: {
      value: 'Consequence',
    },
  
    txChange: {
      value: 'Transcript Change',
    },
    aaChange: {
      value: 'AA Change',
    },
    adrd: {
      value: 'AD/DP',
    },
    vaf: {
      value: 'VAF',
    },
    Ref: {
      value: 'Reference',
    },
    Alt: {
      value: 'Alternative',
    },
    CancerVar_Tier: {
      value: 'CancerVar Tier',
    },
  })

  const [selectedTab, setSelectedTab] = useState()
  const [selectedRow, setSelectedRow] = useState({})
  const [isClinic, setIsClinic] = useState(true)
  const [snvSelectedTableDatas, setSnvSelectedTableDatas] = useState([])
  const [allelicWidgetDatas, setAllelicWidgetDatas] = useState([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({
    plof: true,
    missense: true,
    synonymous: true,
    others: true,
    ongocenic: true,
    likelyOncogenic: true,
    tier1: true,
    tier2: true,
    tier3: false,
  })
  const getUniqueGenesCount = (data) => {
    let genes = data.map((item) => item.Gene_refGene).flat()

    let uniqueGenes = [...new Set(genes)]
    return uniqueGenes.length
  }
  const getUniqueGenes = (data) => {
    let genes = data.map((item) => item.Gene_refGene).flat()

    let uniqueGenes = [...new Set(genes)]
    return uniqueGenes
  }
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8 // Sayfa başına gösterilecek öğe sayısı

  // **groupedData'nın key'lerini sayfalara bölme**
  const groupedDataKeys = Object.keys(groupedData)
  const totalPages = Math.ceil(groupedDataKeys.length / itemsPerPage)
  const paginatedKeys = groupedDataKeys.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const groupByGene = (data) => {
    let geneGroups = {}

    data.forEach((item) => {
      item.Gene_refGene.forEach((gene) => {
        if (geneGroups[gene]) {
          geneGroups[gene].push(item)
        } else {
          geneGroups[gene] = [item]
        }
      })
    })

    return geneGroups
  }
  const fetchVariantData = async (variantType) => {
    try {
      const requestId = id.split('&')
      const variantReq = await somaticService.getSomaticVariants(requestId[0], requestId[1], [
        variantType,
      ])
      if (variantReq.status === 200) {
        var newData = variantReq.data[`${variantType}Data`].rows
        await fetchRunDetail()

        setOriginalData(newData)
        if (newData.length === 0) {
          setIsLoadingData(false)
          return
        }

        const plofKeywords = [
          'stopgain',
          'frameshift deletion',
          'frameshift insertion',
          'stoploss',
          'startlost',
          'start gained',
          'splicing site',
        ]
        const missenseKeywords = ['nonsynonymous SNV']
        const synonymousKeywords = ['synonymous SNV']
        const otherKeywords = [
          'nonframeshift deletion',
          'nonframeshift substitution',
          'nonframeshift insertion',
          'unknown',
        ]

        newData = newData.map((item) => {
          let exonicFuncs = item.ExonicFunc_refGene

          if (!Array.isArray(exonicFuncs)) {
            exonicFuncs = [exonicFuncs]
          }
          let type = 'others'
          exonicFuncs.forEach((func) => {
            if (plofKeywords.includes(func)) {
              type = 'plof'
            } else if (missenseKeywords.includes(func)) {
              type = 'missense'
            } else if (synonymousKeywords.includes(func)) {
              type = 'synonymous'
            }
          })
          item.vampp = item.Chrom + ':' + item.Start + '-' + item.Ref + '-' + item.Alt
          const start = item.Start
          const end = item.End ? item.End : item.Start
          item.igv = item.Chrom + ':' + start + '-' + end
          item.adrd = item.AD + '/' + item.DP
          item.vaf = item.AF
          item.reportSelect = ''
          return {
            ...item,
            type,
          }
        })

        let adScoreArray = newData.map((item) => item.AD)
        let minAdScore = Math.min(...adScoreArray)
        let maxAdScore = Math.max(...adScoreArray)
        setAdScore({
          min: minAdScore,
          max: maxAdScore,
        })
        let afScoreArray = newData.map((item) => item.AF)
        let minAfScore = Math.min(...afScoreArray)
        let maxAfScore = Math.max(...afScoreArray)
        setAfScore({
          min: minAfScore,
          max: maxAfScore,
        })

        setTotalVariants(newData.length)

        setTotalGenes(getUniqueGenesCount(newData))

        // sort by sortStates.drugs  a.HighestSensitiveLevel or a.HighestResistanceLevel sort

        // filter by plof, missense, synonymous, others
        newData = newData.filter((item) => {
          if (filters.plof && item.type === 'plof') return true
          if (filters.missense && item.type === 'missense') return true
          if (filters.synonymous && item.type === 'synonymous') return true
          if (filters.others && item.type === 'others') return true
          return false
        })

        var forCosmic = newData.filter((item) => {
          return (
            item?.Filter[0] === 'PASS' &&
            (item?.CancerVar_Tier === 'Tier I strong ' ||
              item?.CancerVar_Tier === 'Tier II potential ' ||
              item?.CancerVar_Tier === 'Tier III Uncertain ' ||
              item?.Oncogenic === 'Likely Oncogenic' ||
              item?.Oncogenic === 'Oncogenic')
          )
        })
        newData = newData.filter((item) => {
          return (
            item?.Filter[0] === 'PASS' &&
            ((filters.tier1 && item?.CancerVar_Tier === 'Tier I strong ') || // filters.tier1 true ise kontrol edilir
              (filters.tier2 && item?.CancerVar_Tier === 'Tier II potential ') || // filters.tier2 true ise kontrol edilir
              (filters.tier3 && item?.CancerVar_Tier === 'Tier III Uncertain ') || // filters.tier3 true ise kontrol edilir
              (filters.ongocenic && item?.Oncogenic === 'Oncogenic') || // filters.ongocenic true ise kontrol edilir
              (filters.likelyOncogenic && item?.Oncogenic === 'Likely Oncogenic')) // filters.likelyOncogenic true ise kontrol edilir
          )
        })

        var uniqueGenes = getUniqueGenes(newData)
        var forCosmicUniqueGenes = getUniqueGenes(forCosmic)

        const cosmicData = await somaticService.getClinicCosmic(forCosmicUniqueGenes)

        setCosmicData(cosmicData)

        // if (lodSwitch) {
        //   newData = newData.filter((item) => item.DET === 'Yes')
        // }
        adScoreArray = newData.map((item) => item.AD)
        minAdScore = Math.min(...adScoreArray)
        maxAdScore = Math.max(...adScoreArray)
        afScoreArray = newData.map((item) => item.AF)
        minAfScore = Math.min(...afScoreArray)
        maxAfScore = Math.max(...afScoreArray)

        setFilters((prev) => ({
          ...prev,
          minDepth: minAdScore,
          maxDepth: maxAdScore,
          minAf: minAfScore,
          maxAf: maxAfScore,
        }))

        // sort newData by drugs HighestResistanceLevel or HighestSensitiveLevel
        newData = newData.sort((a, b) => {
          const sortOrder = sortStates.drugs === 1 ? 1 : -1

          // Kategorilere ayır
          const aHasResistance = a.HighestResistanceLevel !== null
          const aHasSensitive = a.HighestSensitiveLevel !== null
          const bHasResistance = b.HighestResistanceLevel !== null
          const bHasSensitive = b.HighestSensitiveLevel !== null

          const aCategory =
            aHasResistance && aHasSensitive ? 3 : aHasResistance ? 2 : aHasSensitive ? 1 : 0
          const bCategory =
            bHasResistance && bHasSensitive ? 3 : bHasResistance ? 2 : bHasSensitive ? 1 : 0

          // Öncelik sırasına göre sıralama (önce büyük olan gelsin)
          if (aCategory !== bCategory) {
            return bCategory - aCategory
          }

          // Aynı kategorideyse HighestResistanceLevel'e göre sırala
          if (a.HighestResistanceLevel !== b.HighestResistanceLevel) {
            return sortOrder * (a.HighestResistanceLevel - b.HighestResistanceLevel)
          }

          // Eğer HighestResistanceLevel eşitse, HighestSensitiveLevel'e göre sırala
          return sortOrder * (a.HighestSensitiveLevel - b.HighestSensitiveLevel)
        })

        const groupedData = groupByGene(newData)
        // cosmicData.data.forEach((item) => {
        //   groupedData[item.gene_name].cosmic = item
        // })
        // console.log(groupedData)

        setFilteredTotalVariants(newData.length)
        setFilteredTotalGenes(getUniqueGenesCount(newData))

        setGroupedData(groupedData)
        setTableData((prev) => ({
          ...prev,
          [`${variantType}Data`]: newData,
        }))
        setIsLoadingData(false)
      }
    } catch (error) {
      setIsLoadingData(false)
      Swal.fire({
        title: 'Failed to fetch variant data',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Refresh',
        showCloseButton: false,
        allowOutsideClick: false,
      }).then(() => {
        window.location.reload()
      })
      console.error('Failed to fetch variant data:', error)
    }
  }

  useEffect(() => {
    const loadVariantData = async () => {
      if (tableData[`snvData`] && tableData[`snvData`].length > 0) {
        return
      }
      setIsLoadingData(true)
      await fetchVariantData('snv')
    }

    loadVariantData()
  }, [])
  useEffect(() => {
    if (Object.keys(selectedRow).length > 0) {
      setDrawerOpen(true) // Eğer selectedRow boş değilse, Drawer'ı aç
    } else {
      setDrawerOpen(false) // Eğer selectedRow boşsa, Drawer'ı kapat
    }
  }, [selectedRow]) // selectedRow değiştiğinde çalışacak

  // const handleDepthChange = (values) => {
  //   setFilters((prev) => ({...prev, minDepth: values[0], maxDepth: values[1]}))
  // }
  const handleDepthChange = (values) => {
    setFilters((prev) => ({ ...prev, minDepth: values[0] }))
  }
  const handleAfChange = (values) => {
    setFilters((prev) => ({ ...prev, minAf: values[0], maxAf: values[1] }))
  }
  const categorizeExonicFunc = (data) => {
    const plofKeywords = [
      'stopgain',
      'frameshift deletion',
      'frameshift insertion',
      'stoploss',
      'startlost',
      'start gained',
      'splicing site',
    ]
    const missenseKeywords = ['nonsynonymous SNV']
    const synonymousKeywords = ['synonymous SNV']
    const otherKeywords = [
      'nonframeshift deletion',
      'nonframeshift substitution',
      'nonframeshift insertion',
      'unknown',
    ]

    let categorizedCounts = {
      plof: 0,
      missense: 0,
      synonymous: 0,
      others: 0,
    }

    data.forEach((item) => {
      let exonicFuncs = item.ExonicFunc_refGene

      // Eğer exonicFuncs bir array değilse, onu array yapalım ki forEach ile gezebilelim
      if (!Array.isArray(exonicFuncs)) {
        exonicFuncs = [exonicFuncs]
      }

      exonicFuncs.forEach((func) => {
        if (plofKeywords.includes(func)) {
          categorizedCounts.plof += 1
        } else if (missenseKeywords.includes(func)) {
          categorizedCounts.missense += 1
        } else if (synonymousKeywords.includes(func)) {
          categorizedCounts.synonymous += 1
        } else if (otherKeywords.includes(func)) {
          categorizedCounts.others += 1
        } else {
          categorizedCounts.others += 1 // Uyuşmayan kategorileri "others" kategorisine ekle
        }
      })
    })

    return categorizedCounts
  }
  const applyFilters = (data) => {
    return data.filter((item) => {
      if (filters.plof && item.type === 'plof') return true
      if (filters.missense && item.type === 'missense') return true
      if (filters.synonymous && item.type === 'synonymous') return true
      if (filters.others && item.type === 'others') return true
      return false // Hiçbir filtreye uymayanlar gösterilmeyecek
    })
  }
  useEffect(() => {
    const filteredGeneCounts = () => {
      // Tüm genler üzerinde filtreleri uygula ve filtreye uyan genleri say
      let filteredGenes = Object.keys(groupedData).filter((gene) => {
        const filteredVariants = applyFilters(groupedData[gene])
        return filteredVariants.length > 0 // Filtrelenmiş veri varsa, bu geni say
      })

      // Filtrelenmiş toplam gen sayısını state'e set et
      setFilteredTotalGenes(filteredGenes.length)
    }
    const updateFilteredTotalVariants = () => {
      // Her bir gene ait varyantları filtrele ve sadece filtreye uyan varyantların toplamını al
      let filteredVariantsCount = 0

      Object.keys(groupedData).forEach((gene) => {
        const filteredVariants = applyFilters(groupedData[gene])
        filteredVariantsCount += filteredVariants.length // Filtreye uyan varyantların sayısını ekle
      })

      // Filtrelenmiş toplam varyant sayısını state'e set et
      setFilteredTotalVariants(filteredVariantsCount)
    }
    const updateDepthScores = () => {
      let filteredDepths = []

      // Filtrelenmiş verilerdeki AD değerlerini topla
      Object.keys(groupedData).forEach((gene) => {
        const filteredVariants = applyFilters(groupedData[gene])
        filteredDepths.push(...filteredVariants.map((item) => item.AD)) // AD değerlerini topluyoruz
      })

      //

      // Eğer herhangi bir veri varsa min ve max değerlerini hesapla
      if (filteredDepths.length > 0) {
        const minAdScore = Math.min(...filteredDepths)
        const maxAdScore = Math.max(...filteredDepths)

        // Depth skorlarını güncelle
        setAdScore({
          min: minAdScore,
          max: maxAdScore,
        })
        setFilters((prev) => ({
          ...prev,
          minDepth: minAdScore,
          maxDepth: maxAdScore,
        }))
      } else {
        // Eğer filtrelenmiş veri yoksa min ve max değerleri sıfır olabilir
        setAdScore({
          min: 0,
          max: 0,
        })
        setFilters((prev) => ({
          ...prev,
          minDepth: 0,
          maxDepth: 0,
        }))
      }
    }

    // updateDepthScores()
    updateFilteredTotalVariants()
    filteredGeneCounts()
  }, [filters, groupedData])

  // set adScore when filtered data changes by filters.minDepth and filters.maxDepth

  // lodSwitch değiştiğinde filtre işlemi
  useEffect(() => {
    let filteredData = originalData

    const plofKeywords = [
      'stopgain',
      'frameshift deletion',
      'frameshift insertion',
      'stoploss',
      'startlost',
      'start gained',
      'splicing site',
    ]
    const missenseKeywords = ['nonsynonymous SNV']
    const synonymousKeywords = ['synonymous SNV']
    const otherKeywords = [
      'nonframeshift deletion',
      'nonframeshift substitution',
      'nonframeshift insertion',
      'unknown',
    ]

    let categorizedCounts = {
      plof: 0,
      missense: 0,
      synonymous: 0,
      others: 0,
    }

    filteredData = filteredData.map((item) => {
      // filter by minDepth and maxDepth filters
      if (item.AD < filters.minDepth || item.AD > filters.maxDepth) {
        return null
      }
      if (item.AF < filters.minAf || item.AF > filters.maxAf) {
        return null
      }

      let exonicFuncs = item.ExonicFunc_refGene

      if (!Array.isArray(exonicFuncs)) {
        exonicFuncs = [exonicFuncs]
      }

      // Varsayılan tür "others" olarak belirleniyor
      let type = 'others'

      exonicFuncs.forEach((func) => {
        if (plofKeywords.includes(func)) {
          type = 'plof'
        } else if (missenseKeywords.includes(func)) {
          type = 'missense'
        } else if (synonymousKeywords.includes(func)) {
          type = 'synonymous'
        }
      })

      return {
        ...item,
        type, // Satıra 'type' özelliğini ekliyoruz
      }
    })

    filteredData = filteredData.filter((item) => {
      return (
        item?.Filter[0] === 'PASS' &&
        ((filters.tier1 && item?.CancerVar_Tier === 'Tier I strong ') || // filters.tier1 true ise kontrol edilir
          (filters.tier2 && item?.CancerVar_Tier === 'Tier II potential ') || // filters.tier2 true ise kontrol edilir
          (filters.tier3 && item?.CancerVar_Tier === 'Tier III Uncertain ') || // filters.tier3 true ise kontrol edilir
          (filters.ongocenic && item?.Oncogenic === 'Oncogenic') || // filters.ongocenic true ise kontrol edilir
          (filters.likelyOncogenic && item?.Oncogenic === 'Likely Oncogenic')) // filters.likelyOncogenic true ise kontrol edilir
      )
    })
    // if (lodSwitch) {
    //   filteredData = filteredData.filter((item) => item.DET === 'Yes')
    // }
    filteredData = filteredData.filter((item) => {
      if (item?.type !== undefined) {
        if (filters.plof && item?.type === 'plof') return true
        if (filters.missense && item?.type === 'missense') return true
        if (filters.synonymous && item?.type === 'synonymous') return true
        if (filters.others && item?.type === 'others') return true
        return false
      } else {
        return true
      }
    })
    if (sortStates.gene !== 0) {
      filteredData = sortByGene(filteredData, sortStates.gene)
    } else if (sortStates.consequence !== 0) {
      filteredData = sortByConsequence(filteredData, sortStates.consequence)
    } else {
      filteredData = sortByDrugs(filteredData, sortStates.drugs)
    }
    // Filtrelenmiş veriyi tableData'ya set ediyoruz
    setTableData((prev) => ({
      ...prev,
      snvData: filteredData,
    }))

    setFilteredTotalVariants(filteredData.length)
    // setTotalGenes(getUniqueGenesCount(filteredData))
    setFilteredTotalGenes(getUniqueGenesCount(filteredData))
    setOpenAccordion(null)
    setGroupedData(groupByGene(filteredData))
    // }, [lodSwitch, originalData, filters])
  }, [originalData, filters, sortStates])
  const adjustGridHeight = () => {
    const slickGridContainer = document.querySelector('.slickgrid-container')
    const slickGridCanvas = document.querySelector('.grid-canvas')
    const cancerVarTierColumn = document.querySelector(
      '.slick-header-column[data-id="CancerVar_Tier"]'
    )

    if (cancerVarTierColumn) {
      cancerVarTierColumn.setAttribute('data-tooltip-id', 'cancervar-tooltip')
      cancerVarTierColumn.setAttribute('data-tooltip-content', `sa`)
      cancerVarTierColumn.setAttribute('data-tooltip-place', `left`)
    }
    if (slickGridContainer && slickGridCanvas) {
      const rowCount = slickGridCanvas.children.length

      if (rowCount < 10) {
        // Dinamik sınıf oluştur
        const dynamicClassName = `grid-height-${rowCount}`
        const res = rowCount > 6 ? 30 : rowCount > 3 ? 40 : 50
        const dynamicHeight = `${rowCount * res}px`

        // Dinamik CSS oluştur
        const style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = `
          .${dynamicClassName} {
            height: ${dynamicHeight} !important;
            min-height: 100px !important;
          }
        `

        // Daha önceki dinamik sınıfları temizlemek için
        const existingStyle = document.getElementById('dynamic-grid-height')
        if (existingStyle) {
          existingStyle.remove()
        }

        // Yeni stil etiketini head'e ekleyin
        style.id = 'dynamic-grid-height'
        document.head.appendChild(style)

        // Dinamik sınıfı slickGridContainer'a ekle
        slickGridContainer.classList.add(dynamicClassName)
        document.querySelector('#grid-container').style.height = `${rowCount === 1 ? '135px' : rowCount * res + 75
          }px`
      }
    }
  }

  useEffect(() => {
    if (openAccordion !== null) {
      setTimeout(() => {
        adjustGridHeight() // Accordion açıldıktan sonra yükseklik ayarını yap
      }, 100) // 100ms gecikme ile grid'in tamamen render edilmesini bekle

      const observer = new MutationObserver(() => {
        adjustGridHeight()
      })

      const slickGridCanvas = document.querySelector('.grid-canvas')
      if (slickGridCanvas) {
        observer.observe(slickGridCanvas, { childList: true })
      }

      return () => {
        observer.disconnect()
      }
    }
  }, [openAccordion])
  useEffect(() => {
    if (openAccordion !== null) {
      setTimeout(() => {
        document.querySelectorAll('input.reportSelect').forEach((checkbox) => {
          const rowId = checkbox.getAttribute('data-id')
          if (rowId) {
            checkbox.checked = snvSelectedTableDatas.some((row) => row.id.toString() === rowId)
          }
        })
      }, 100) // Küçük bir gecikme ekleyerek DOM’un tamamen yüklenmesini bekliyoruz
    } else {
      // click to id='ClinicDrawer_close' button
      document.getElementById('ClinicDrawer_close').click()
    }
  }, [openAccordion, snvSelectedTableDatas])


  // click outside cosmicDropdownRef to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cosmicDropdownRef.current && !cosmicDropdownRef.current.contains(event.target)) {
        setOpenCosmicDropdown(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cosmicDropdownRef])

  const defaultFilters = {
    plof: true,
    missense: true,
    synonymous: true,
    others: true,
    ongocenic: true,
    likelyOncogenic: true,
    tier1: true,
    tier2: true,
    tier3: false,
  }

  const resetSection = (keys) => {
    setFilters((prev) => ({
      ...prev,
      ...keys.reduce((acc, key) => ({ ...acc, [key]: defaultFilters[key] }), {}),
    }));
  };
  return (
    <>
      <div className='row'>
        <div className='card card-custom'>
          <div className='card-body table-card'>
            <PageTitle breadcrumbs={[]}>
              {runDetail?.id !== undefined ? runDetail.samples[0].sampleId : ''}
            </PageTitle>

            {/* <ProteinPaintChart /> */}
            {!isLoadingData ? (
              <div className=' '>
                <div className="">
                  <div className="row d-flex align-items-center justify-content-between">
                    {/* Sol Taraf */}
                    <div className="col-lg-6 col-md-12 d-flex flex-wrap gap-3 align-items-center">
                      <div className="circle-genes position-relative d-flex flex-column text-center">
                        <span style={{ marginTop: "-10px" }}>
                          {filteredTotalGenes}/{totalGenes}
                        </span>
                        <small className="position-absolute" style={{ bottom: "25px", fontWeight: "normal" }}>
                          Genes
                        </small>
                      </div>

                      {/* Variant Boxes */}
                      <div className="border border-gray-300 border-dashed rounded d-flex flex-wrap gap-3 p-3">
                        <div className="text-center px-3" style={{ borderRight: "1px solid #e0e0e0" }}>
                          <div className="fs-6 text-gray-800 fw-bolder">{totalVariants}</div>
                          <div className="fw-bold text-gray-500 fs-8">Variants</div>
                        </div>
                        <div className="text-center px-3" style={{ borderRight: "1px solid #e0e0e0" }}>
                          <div className="fs-6 text-gray-800 fw-bolder">{filteredTotalVariants}</div>
                          <div className="fw-bold text-gray-500 fs-8">Retained</div>
                        </div>
                        <div className="text-center px-3">
                          <div className="fs-6 text-gray-800 fw-bolder">{totalVariants - filteredTotalVariants}</div>
                          <div className="fw-bold text-gray-500 fs-8">Low Confidence</div>
                        </div>
                      </div>

                      {/* Depth Min/Max */}
                      <div className="border border-gray-300 border-dashed rounded d-flex flex-wrap gap-3 p-3">
                        <div className="text-center px-3" style={{ borderRight: "1px solid #e0e0e0" }}>
                          <div className="fs-6 text-gray-800 fw-bolder">{filters.minDepth}</div>
                          <div className="fw-bold text-gray-500 fs-8">Depth Min</div>
                        </div>
                        <div className="text-center px-3">
                          <div className="fs-6 text-gray-800 fw-bolder">{filters.maxDepth}</div>
                          <div className="fw-bold text-gray-500 fs-8">Depth Max</div>
                        </div>
                      </div>
                    </div>

                    {/* Sağ Taraf */}
                    <div className="col-lg-6 col-md-12 d-flex  align-items-center justify-content-end">
                      {/* <span className="filter-title text-center d-none d-md-block"
                        style={{
                          writingMode: "vertical-rl",
                          textOrientation: "mixed",
                          color: "#99a1b7",
                          letterSpacing: "0.1em",
                        }}>
                        - FILTER -
                      </span> */}

                      <div className="pt-5 pb-2 px-4 bg-light rounded border border-gray-300">
                        <div className="d-flex  gap-6 align-items-center">
                          {/* Sol Filtre Kısmı */}
                          <div className="w-100">
                            <div className="mb-3">
                              <label className="d-flex align-items-center">
                                <strong>Minimum Allele Frequency: {(filters.minAf * 100).toFixed(0)}%</strong>
                              </label>
                              <input type="range" className="form-range custom-range" min={0} max={1} step={0.01} value={filters.minAf}
                                onChange={(e) => { handleAfChange([e.target.value, filters.maxAf]) }} />
                              <div className="d-flex justify-content-between">
                                <span>0%</span>
                                <span>100%</span>
                              </div>
                            </div>

                            <div className="mb-3">
                              <label className="d-flex align-items-center ">
                                <strong>Minimum Read Depth : {filters.minDepth}</strong>
                              </label>
                              <input type="range" className="form-range custom-range" min={adScore.min} max={filters.maxDepth} value={filters.minDepth}
                                onChange={(e) => handleDepthChange([Number(e.target.value), filters.maxDepth])} />
                              <div className="d-flex justify-content-between">
                                <span>{adScore.min}</span>
                                <span>{filters.maxDepth}</span>
                              </div>
                            </div>
                          </div>

                          {/* Aktif Filtreler */}
                          <div className="w-100">
                            <div className='mb-4 '>
                              <strong >ACTIVE FILTERS</strong>
                            </div>
                            <p><strong>Function:</strong>
                              {filters.plof && 'pLoF'}
                              {filters.missense && ', Missense'}
                              {filters.synonymous && ', Others'}
                              {filters.others && ', Synonymous'}
                            </p>
                            <p><strong>CancerVar:</strong>
                              {filters.tier1 && 'Tier 1'}
                              {filters.tier2 && ', Tier 2'}
                              {filters.tier3 && ', Tier 3'}
                            </p>
                            <p><strong>OncoKB:</strong>
                              {filters.ongocenic && 'Oncogenic'}
                              {filters.likelyOncogenic && ', Likely Oncogenic'}
                            </p>
                          </div>

                          {/* Filtre Düğmesi */}
                          <div className="position-relative ml-auto">
                            <button className="btn btn-light border-0" data-bs-toggle='dropdown' data-bs-auto-close='outside'
                              aria-expanded='false'>
                              <i className="fa fa-chevron-right text-primary fs-1"></i>
                            </button>
                            <div className='dropdown-menu rounded pt-0 pb-4' style={{ minWidth: '700px' }}>
                              <div className='py-0 '>
                                <div className="filter-header bg-light">
                                  <div className="d-flex justify-content-between border-bottom   align-items-center px-7 py-4">
                                    <div className="">
                                      <h4 className="fw-bold mb-0">Advanced Filters</h4>
                                      <span className='fs-8 text-gray-500'>
                                        Filter somatic variants based on genomic properties
                                      </span>
                                    </div>
                                    <button
                                      className="btn btn-light btn-sm"
                                      onClick={() => {
                                        setFilters((prev) => ({
                                          ...prev,
                                          ...defaultFilters,
                                        }))
                                      }
                                      }
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items center gap-5 px-7">

                                  <div className='type-filter border-bottom  py-2 w-50  '>
                                    <label className='fw-bold text-gray-500 fs-6 border-bottom d-block pb-2 d-flex align-items-center justify-content-between'> Functional Impact
                                      <span
                                        className=''
                                        onClick={() => resetSection(['plof', 'missense', 'others', 'synonymous'])}
                                      >
                                        <i className='fa fa-undo ml-5'></i>
                                      </span>

                                    </label>
                                    <div className='d-flex flex-column gap-1 mt-3 bg-light rounded border border-gray-300 p-5' style={{
                                      minHeight: '140px'
                                    }}>

                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='plof'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, plof: e.target.checked }))
                                          }
                                          checked={filters.plof}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>pLoF </span>
                                        {/* <span className='fw-bold ps-2 fs-7'>pLoF <span className='fw-normal fs-8'>(Loss of Function)</span></span> */}
                                      </label>

                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='missense'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, missense: e.target.checked }))
                                          }
                                          checked={filters.missense}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Missense  </span>
                                        {/* <span className='fw-bold ps-2 fs-7'>Missense  <span className='fw-normal fs-8'>(Amino acid change)</span></span> */}
                                      </label>
                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='others'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, others: e.target.checked }))
                                          }
                                          checked={filters.others}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Others  </span>
                                        {/* <span className='fw-bold ps-2 fs-7'>Others  <span className='fw-normal fs-8'>(e.g., Splice, UTR)</span></span> */}
                                      </label>
                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='synonymous'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, synonymous: e.target.checked }))
                                          }
                                          checked={filters.synonymous}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Synonymous </span>
                                        {/* <span className='fw-bold ps-2 fs-7'>Synonymous <span className='fw-normal fs-8'>(No amino acid change)</span></span> */}
                                      </label>

                                    </div>
                                  </div>


                                  <div className='type-filter border-bottom  py-2 w-50  '>
                                    <label className='fw-bold text-gray-500 fs-6 border-bottom d-flex justify-content-between align-items-center pb-2 '>
                                      CancerVar Evaluation
                                      <span
                                        className=''
                                        onClick={() => resetSection(['tier1', 'tier2', 'tier3'])}
                                      >
                                        <i className='fa fa-undo ml-5'></i>
                                      </span>
                                    </label>
                                    <div className='d-flex flex-column gap-1 mt-3 bg-light rounded border border-gray-300 p-5' style={{
                                      minHeight: '140px'
                                    }}>
                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='tier1'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, tier1: e.target.checked }))
                                          }
                                          checked={filters.tier1}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Tier I strong </span>
                                      </label>

                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='tier2'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, tier2: e.target.checked }))
                                          }
                                          checked={filters.tier2}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Tier II potential</span>
                                      </label>

                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='tier3'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({
                                              ...prev,
                                              tier3: e.target.checked,
                                            }))
                                          }
                                          checked={filters.tier3}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Tier III Uncertain</span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className='type-filter border-bottom  py-2 w-50  '>
                                    <label className='fw-bold text-gray-500 fs-6 border-bottom d-flex justify-content-between align-itmes-center pb-2'>
                                      OncoKB Evaluation
                                      <span
                                        className=''
                                        onClick={() => resetSection(['ongocenic', 'likelyOncogenic'])}
                                      >
                                        <i className='fa fa-undo ml-5'></i>
                                      </span>
                                    </label>
                                    <div className='d-flex flex-column gap-1 mt-3 bg-light rounded border border-gray-300 p-5 ' style={{
                                      minHeight: '140px'
                                    }}>
                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='ongocenic'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({ ...prev, ongocenic: e.target.checked }))
                                          }
                                          checked={filters.ongocenic}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Oncogenic </span>
                                      </label>

                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          name='likelyOncogenic'
                                          type='checkbox'
                                          onChange={(e) =>
                                            setFilters((prev) => ({
                                              ...prev,
                                              likelyOncogenic: e.target.checked,
                                            }))
                                          }
                                          checked={filters.likelyOncogenic}
                                        />
                                        <span className='fw-bold ps-2 fs-7'>Likely Oncogenic</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Object.keys(groupedData).length === 0 && (
                  <div className='alert alert-custom alert-light-danger text-center' role='alert'>
                    <div className='alert-text'>
                      <h3 className='fw-bolder'>No data found!</h3>
                      <p>There is no data to display.</p>
                    </div>
                  </div>
                )}
                <div className='accordion mt-5' id='kt_accordion_1'>
                  <div
                    className='d-flex align-items-center fw-bold mb-2'
                    style={{
                      padding: '0px 1.5rem',
                      marginLeft: '15px',
                    }}
                  >
                    <span
                      className='fw-bold text-gray-500 fs-6'
                      style={{ minWidth: '85px', cursor: 'pointer' }}
                      onClick={() => toggleSort('gene')}
                    >
                      Gene
                      {sortStates.gene > 0 && (
                        <img
                          src='/media/sort-triangle.png'
                          alt='Sort'
                          style={{
                            width: '10px',
                            marginLeft: '5px',
                            transform: sortStates.gene === 1 ? 'rotate(90deg)' : 'none',
                          }}
                        />
                      )}
                    </span>

                    <span
                      className='fw-bold text-gray-500 fs-6'
                      style={{ minWidth: '75px', cursor: 'pointer' }}
                      onClick={() => toggleSort('drugs')}
                    >
                      Drugs
                      {sortStates.drugs > 0 && (
                        <img
                          src='/media/sort-triangle.png'
                          alt='Sort'
                          style={{
                            width: '10px',
                            marginLeft: '5px',
                            transform: sortStates.drugs === 1 ? 'rotate(90deg)' : 'none',
                          }}
                        />
                      )}
                    </span>

                    <span
                      className='d-flex gap-3 fw-bold text-gray-500 fs-6'
                      style={{ marginLeft: '20px', cursor: 'pointer' }}
                      onClick={() => toggleSort('consequence')}
                    >
                      <label style={{ minWidth: '100px' }}>
                        Consequence
                        {sortStates.consequence > 0 && (
                          <img
                            src='/media/sort-triangle.png'
                            alt='Sort'
                            style={{
                              width: '10px',
                              marginLeft: '5px',
                              transform: sortStates.consequence === 1 ? 'rotate(90deg)' : 'none',
                            }}
                          />
                        )}
                        <i
                          className='fas fa-info-circle ms-1'
                          data-tooltip-id='my-tooltip'
                          data-tooltip-html='
                            <center>
                                  <div class="d-flex gap-2">
                                    <div class="d-flex gap-2 align-items-center">
                                      <div class="legend-circle" style="background-color: #FDA99C; width:10px; height:10px;"></div>
                                      <div class="legend-text">pLoF</div>
                                    </div>
                                    <div class="d-flex gap-2 align-items-center mx-2">
                                      <div class="legend-circle" style="background-color: #F5E2A4; width:10px; height:10px;"></div>
                                      <div class="legend-text">Missense</div>
                                    </div>
                                    <div class="d-flex gap-2 align-items-center mx-2">
                                      <div class="legend-circle" style="background-color: #d5d5d5; width:10px; height:10px;"></div>
                                      <div class="legend-text">Others</div>
                                    </div>
                                    <div class="d-flex gap-2 align-items-center">
                                      <div class="legend-circle" style="background-color: #7DBD7D; width:10px; height:10px;"></div>
                                      <div class="legend-text">Synonymous</div>
                                    </div>
                                  </div>
                                </center>'
                        ></i>
                      </label>
                    </span>

                    <span style={{ marginLeft: '35px' }} className='fw-bold text-gray-500 fs-6'>
                      Cosmic
                    </span>
                    <span style={{ marginLeft: '170px' }} className='fw-bold text-gray-500 fs-6'>
                      Tools
                    </span>
                  </div>

                  {paginatedKeys.length > 0 &&
                    paginatedKeys.map((gene, index) => {
                      const filteredDataa = applyFilters(groupedData[gene])

                      if (filteredDataa.length === 0) {
                        return null
                      }
                      const categorizedCounts = categorizeExonicFunc(groupedData[gene])
                      const isAccordionOpen = openAccordion === index

                      const geneCosmicData =
                        cosmicData.data && cosmicData.data.find((item) => item.gene_name === gene)
                      const validSensitiveLevels = filteredDataa.filter(
                        (item) => item.HighestSensitiveLevel !== null
                      )
                      const validResistanceLevels = filteredDataa.filter(
                        (item) => item.HighestResistanceLevel !== null
                      )

                      const highestSensitiveLevel =
                        validSensitiveLevels.length > 0
                          ? validSensitiveLevels
                            .sort(
                              (a, b) =>
                                levelPriority[a.HighestSensitiveLevel]?.priority -
                                levelPriority[b.HighestSensitiveLevel]?.priority
                            )
                            .at(0).HighestSensitiveLevel
                          : null

                      const highestResistanceLevel =
                        validResistanceLevels.length > 0
                          ? validResistanceLevels
                            .sort(
                              (a, b) =>
                                levelPriority[a.HighestResistanceLevel]?.priority -
                                levelPriority[b.HighestResistanceLevel]?.priority
                            )
                            .at(0).HighestResistanceLevel
                          : null

                      // 1. En yüksek seviyeye sahip ilaçları al
                      const highestSensitiveDrugs = highestSensitiveLevel
                        ? [
                          ...new Set(
                            filteredDataa
                              .map((item) => item[levelReadable[highestSensitiveLevel]])
                              .filter((drug) => drug !== null && drug !== '') // Null ve boş stringleri çıkar
                          ),
                        ]
                        : []

                      const highestResistanceDrugs = highestResistanceLevel
                        ? [
                          ...new Set(
                            filteredDataa
                              .map((item) => item[levelReadable[highestResistanceLevel]])
                              .filter((drug) => drug !== null && drug !== '') // Null ve boş stringleri çıkar
                          ),
                        ]
                        : []

                      // 2. Tooltip için ilaç listesini string olarak hazırla
                      const sensitiveDrugList =
                        highestSensitiveDrugs.length > 0
                          ? highestSensitiveDrugs.join(', ')
                          : 'No drugs available'
                      const resistanceDrugList =
                        highestResistanceDrugs.length > 0
                          ? highestResistanceDrugs.join(', ')
                          : 'No drugs available'

                      const sensitiveBgColor = highestSensitiveLevel
                        ? levelPriority[highestSensitiveLevel].bgColor
                        : null
                      const resistanceBgColor = highestResistanceLevel
                        ? levelPriority[highestResistanceLevel].bgColor
                        : null

                      const plofCount = filters.plof ? categorizedCounts.plof : 0
                      const missenseCount = filters.missense ? categorizedCounts.missense : 0
                      const synonymousCount = filters.synonymous ? categorizedCounts.synonymous : 0
                      const othersCount = filters.others ? categorizedCounts.others : 0
                      return (
                        <div
                          className='accordion-item clinic'
                          key={index}
                          style={{ position: 'relative' }}
                        >
                          <h2
                            className='accordion-header '
                            id={`kt_accordion_1_header_${index}`}
                            style={{
                              borderLeft: resistanceBgColor
                                ? `15px solid ${resistanceBgColor}`
                                : sensitiveBgColor
                                  ? `15px solid ${sensitiveBgColor}`
                                  : '15px solid #e5e5e5',
                            }}
                          >
                            <button
                              className='accordion-button fs-4  fw-bold collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#kt_accordion_1_body_${index}`}
                              aria-expanded={isAccordionOpen}
                              aria-controls={`kt_accordion_1_body_${index}`}
                              onClick={() => setOpenAccordion(isAccordionOpen ? null : index)} // Tıklayınca accordion aç/kapa
                            >
                              <span
                                style={{
                                  minWidth: '85px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 5,
                                }}
                              >
                                <a
                                  href={`https://www.genecards.org/cgi-bin/carddisp.pl?gene=${gene}`}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <span className='gene-name fst-italic d-flex gap-5'>{gene}</span>
                                </a>
                              </span>

                              <span
                                style={{
                                  minWidth: '75px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <span
                                  data-tooltip-id='my-tooltip'
                                  style={{
                                    opacity: highestResistanceLevel ? 1 : 0.1,
                                  }}
                                  data-tooltip-html={`
                                      <center>
                                      ${highestResistanceLevel
                                      ? `
                                        ${levelReadable[highestResistanceLevel]} <br>
                                      ${resistanceDrugList}
                                        `
                                      : `No associated drug for listed variants.`
                                    } 
                                      </center>
                                      `}
                                >
                                  <PillIcon
                                    color={highestResistanceLevel ? resistanceBgColor : '#000'}
                                  />
                                </span>
                                <span
                                  data-tooltip-id='my-tooltip'
                                  style={{
                                    opacity: highestSensitiveLevel ? 1 : 0.1,
                                  }}
                                  data-tooltip-html={`
                                      <center>
                                      ${highestSensitiveLevel
                                      ? `
                                        ${levelReadable[highestSensitiveLevel]} <br>
                                      ${sensitiveDrugList}
                                        `
                                      : `No associated drug for listed variants.`
                                    } 
                                      </center>
                                      `}
                                >
                                  <PillIcon
                                    color={highestSensitiveLevel ? sensitiveBgColor : '#000'}
                                  />
                                </span>
                                {/* <span
                                  data-tooltip-id='my-tooltip'
                                  data-tooltip-html={`
                                      <center>
                                      ${levelReadable[highestSensitiveLevel]} <br>
                                      ${sensitiveDrugList}
                                      </center>
                                      `}
                                  data-tooltip-content={highestSensitiveLevel}
                                >
                                  <PillIcon color={sensitiveBgColor} />
                                </span> */}
                              </span>

                              <span
                                className='exonics d-flex gap-3 fw-normal'
                                style={{ margin: '0px 20px' }}
                              >
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      padding: '5px 5px',
                                      borderRadius: '2px',
                                      minWidth: '20px',
                                      backgroundColor: '#FDA99C',
                                      textAlign: 'center',
                                      color: 'white',
                                    }}
                                  >
                                    {plofCount}
                                  </div>{' '}
                                </label>
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      padding: '5px 5px',
                                      borderRadius: '2px',
                                      minWidth: '20px',
                                      backgroundColor: '#F5E2A4',
                                      textAlign: 'center',
                                      color: 'white',
                                    }}
                                  >
                                    {missenseCount}
                                  </div>
                                </label>
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      padding: '5px 5px',
                                      borderRadius: '2px',
                                      minWidth: '20px',
                                      backgroundColor: '#d5d5d5',
                                      textAlign: 'center',
                                      color: 'white',
                                    }}
                                  >
                                    {othersCount}
                                  </div>
                                </label>
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      padding: '5px 5px',
                                      borderRadius: '2px',
                                      minWidth: '20px',
                                      backgroundColor: '#7DBD7D',
                                      textAlign: 'center',
                                      color: 'white',
                                    }}
                                  >
                                    {synonymousCount}
                                  </div>
                                </label>
                              </span>
                              <span
                                className='cosmic  fw-normal'
                                style={{
                                  margin: '0px 20px ',
                                  minWidth: '150px',
                                }}
                              >
                                {/* <span className='gene-name' style={{minWidth: '75px'}}>
                                  <label className='fw-bold text-gray-500 fs-8 mx-2'>
                                    {' '}
                                    Cosmic:
                                  </label>
                                </span> */}
                                {geneCosmicData && (
                                  <label
                                    onClick={() => setOpenCosmicDropdown(index)}
                                    style={{
                                      position: 'relative',
                                    }}
                                    className='fs-7 cursor-pointer'
                                  // onMouseLeave={(e) => {
                                  //   if (!cosmicDropdownRef.current?.contains(e.relatedTarget)) {
                                  //     setOpenCosmicDropdown(null); // Eğer dropdown içinde değilse kapat
                                  //   }
                                  // }}
                                  >
                                    Site :{' '}
                                    {geneCosmicData?.sites ? geneCosmicData.sites.length : ''}
                                    <span className='mx-3'>| </span>
                                    Count :{' '}
                                    {geneCosmicData?.total_count ? geneCosmicData.total_count : ''}
                                    {openCosmicDropdown === index && (
                                      <div
                                        ref={cosmicDropdownRef}
                                        onMouseEnter={() => setOpenCosmicDropdown(index)} // Dropdown'un içinde gezinirken açık kalmasını sağla
                                        onMouseLeave={() => setOpenCosmicDropdown(null)} // Dropdown dışına çıkınca kapat
                                        className='dropdown-cosmic'
                                        style={{
                                          position: 'absolute',
                                          top: '100%',
                                          left: '50%',
                                          transform: 'translateX(-50%)',
                                          background: 'white',
                                          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                                          padding: '10px',
                                          borderRadius: '8px',
                                          zIndex: 1000,
                                          minWidth: '300px',
                                        }}
                                      >
                                        <CosmicChart cosmicData={geneCosmicData?.sites || []} />
                                      </div>
                                    )}
                                  </label>
                                )}
                              </span>
                              <span
                                className='cosmic  fw-normal
                                d-flex gap-3 align-items-center'
                                style={{
                                  marginLeft: '45px',
                                }}
                              >
                                <a
                                  href={`https://vamppscore.com/search-gene/${gene}`}
                                  target='_blank'
                                  data-tooltip-id='my-tooltip'
                                  data-tooltip-content={`VAMPP Score`}
                                  rel='noreferrer'
                                >
                                  <img
                                    src='/media/vampp-logo-icon.png'
                                    width={26}
                                    height={18}
                                    alt='vampp'
                                  />
                                </a>
                                <span
                                  className='protein-modal'
                                  onClick={() => {
                                    setSelectedGene(gene)
                                    setSelectedFilteredData(filteredDataa)
                                    setProteinModalOpen(true)
                                  }}
                                  data-tooltip-id='my-tooltip'
                                  data-tooltip-content={`Protein Paint Visualization`}
                                >
                                  <img
                                    src='/media/protein-pant-logo-icon.png'
                                    width={25}
                                    height={16.5}
                                    alt='vampp'
                                  />
                                </span>
                              </span>
                            </button>
                          </h2>
                          <div
                            id={`kt_accordion_1_body_${index}`}
                            className={`accordion-collapse collapse ${isAccordionOpen ? 'show' : ''
                              }`} // Açık olan accordion için "show" class'ı
                            aria-labelledby={`kt_accordion_1_header_${index}`}
                            data-bs-parent='#kt_accordion_1'
                          >
                            <div className='accordion-body'>
                              {/* Sadece açık olan accordion için TableComponent render ediliyor */}
                              {isAccordionOpen && (
                                <TableComponentClinicalPage
                                  tableHeader={snvHeader}
                                  data={filteredDataa} // Filtrelenmiş veriyi tabloya gönderiyoruz
                                  isTreeData={false}
                                  isFilterable={false}
                                  selectedRows={snvSelectedTableDatas}
                                  autoHeight={false}
                                  setSelectedRows={setSnvSelectedTableDatas}
                                  gridId={'snvTableClinic'}
                                  runId={runDetail.id}
                                  gridOptions={gridOptions}
                                  canMultiSelect={false}
                                  setAllelicWidgetDatas={setAllelicWidgetDatas}
                                  isClinic={isClinic}
                                  setGridOptions={setGridOptions}
                                  selectedWidgetItem={selectedRow}
                                  setSelectedWidgetItem={setSelectedRow}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  <div className='pagination-controls d-flex justify-content-center align-items-center mt-4'>
                    <button
                      className='btn btn-primary'
                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                    <span className='mx-3'>
                      {currentPage} / {totalPages}
                    </span>
                    <button
                      className='btn btn-primary'
                      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
                {/* <div className='col-md-4   d-flex  align-items-center gap-3'>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <span> 335</span> VARIANTS
                  </div>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <span> 52</span> RETAINED
                  </div>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <span> 283</span> LOW CONFIDENCE
                  </div>
                </div>
                <div className='col-md-4 d-flex  align-items-center'>
                  <p>
                    <strong>VARIANT DEPTHS</strong>
                    <br />
                    Depth Min: 31
                    <br />
                    Depth Max: 4090
                  </p>
                </div> */}
                {/* <div className='col'>
                    <div className='circle'>32/32</div>
                    <p>WARNINGS</p>
                  </div> */}
              </div>
            ) : (
              <div className='card-body table-card text-center d-flex align-items-start justify-content-center'>
                <Loader />
              </div>
            )}
            <ClinicDrawer
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              allelicWidgetDatas={allelicWidgetDatas}
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            />
          </div>
          {snvSelectedTableDatas.length > 0 && (
            <button
              className='btn position-absolute   btn-warning btn-sm'
              style={{
                left: '15px',
                bottom: '20px',
              }}
            >
              {' '}
              Generate Report
            </button>
          )}
        </div>
      </div >
      <Tooltip id='my-tooltip' />

      <Tooltip
        id='cancervar-tooltip'
        render={({ content, activeAnchor }) => {
          // Eğer content veya canverEvTooltips eksikse null döndür
          if (!content || !canverEvTooltips) return null

          // Tooltip içeriğini canverEvTooltips'ten alıyoruz
          const data = Object.entries(canverEvTooltips)

          return (
            <ul
              className='row'
              style={{
                paddingLeft: '0',
                maxWidth: '600px',
              }}
            >
              {data.map(([key, value], index) => (
                <li
                  key={index}
                  className='col-md-12 d-flex justify-content-between align-items-center'
                  style={{
                    borderBottom: '0.5px solid rgb(163, 164, 170)',
                  }}
                >
                  <span>{key}:</span>{' '}
                  <span
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    {value}
                  </span>
                </li>
              ))}
            </ul>
          )
        }}
      />

      <Tooltip id='cosmicTooltip' />
      {/* <Tooltip
        id='cosmicTooltip'
        render={({ content, activeAnchor }) => {
          if (!content || !cosmicData) return null
          const data = cosmicData.data.filter((item) => item.gene_name === content)[0].sites
          console.log(data, 'cosmicData')
          return (
            <>
              <CosmicChart cosmicData={data} />
            </>
          )
        }}
        style={{ backgroundColor: "#FFF", }}

      /> */}
      <ProteinModal
        sampleName={runDetail?.id !== undefined ? runDetail.samples[0].sampleId : ''}
        isOpen={proteinModalOpen}
        gene={selectedGene}
        data={selectedFilteredData}
        onClose={() => setProteinModalOpen(false)}
      />

      <ClinicAdvancedFilterModal filters={filters} setFilters={setFilters} />
    </>
  )
}

export { SomaticDetailClinicPage }
