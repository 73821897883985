import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import carboneSDK from 'carbone-sdk-js'
import GenerateReportModal from '../../components/SomaticTable/GenerateReportModal'
import Swal from 'sweetalert2'
import somaticService from '../../service/somatic'
import runsService from '../../service/runs'
import {useParams} from 'react-router'
import {Loader} from '../../components/Loader'
import axios from 'axios'
import IgvTable from '../../components/SomaticTable/IgvTable'
import TableComponent from '../../components/TableComponent/TableComponent'
import TableComponentTreeData from '../../components/TableComponent/TableComponentTreeData'
import {convertJSONtoTSV, downloadTSV} from '../../utils'
import RunsService from '../../service/runs'
import {Tooltip} from 'react-tooltip'

const SomaticDetailTable = () => {
  const {id} = useParams()
  const [tableData, setTableData] = useState({snvData: [], svData: [], fusionsData: []})
  const [snvData, setSnvData] = useState([])
  const [runDetail, setRunDetail] = useState({})
  // const [filteredData, setFilteredData] = useState([])
  const [gridOptions, setGridOptions] = useState(undefined)
  const [snvHeader, setSnvHeader] = useState([])
  const [svHeader, setSvHeader] = useState([])
  const [fusionHeader, setFusionHeader] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(true)
  // const [isAdvanced, setIsAdvanced] = useState(false)
  const [selectedTab, setSelectedTab] = useState()
  const [selectedRow, setSelectedRow] = useState({})
  const [allelicWidgetDatas, setAllelicWidgetDatas] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isDrawerHide, setIsDrawerHide] = useState(false)
  const [isClinic, setIsClinic] = useState(false)
  const [lodSwitch, setLodSwitch] = useState(true)
  const getMatchingData = (array1, array2, variantType) => {
    const matchingData = []
    var filtered = array1.filter((elm) => elm)
    array2.forEach((item) => {
      var matchedChildren = []
      if (variantType === 'snv') {
        matchedChildren = item.Gene_refGene.filter((child) => filtered.includes(child))
        if (matchedChildren.length > 0) {
          matchingData.push({
            ...item,
          })
        }
      } else {
        matchedChildren = item.GeneName.filter((child) => filtered.includes(child))
        if (matchedChildren.length > 0) {
          matchingData.push({
            ...item,
          })
        }
      }
    })
    return matchingData
  }
  const toggleDrawer = () => {
    if (selectedRow.Chrom === undefined) {
      return
    }
    if (isDrawerOpen === true) {
      setIsDrawerHide(!isDrawerHide)
    } else {
      setIsDrawerOpen(true)
    }
  }

  const fetchRunDetail = async () => {
    try {
      const requestId = id.split('&')
      const runDetailResponse = await runsService.getRunDetail(requestId[0])
      if (runDetailResponse.status === 200) {
        setRunDetail(runDetailResponse.data)
      }
      return runDetailResponse.data
    } catch (error) {
      console.error('Failed to fetch run details:', error)
    }
  }

  const fetchVariantData = async (variantType) => {
    try {
      const requestId = id.split('&')
      const variantReq = await somaticService.getSomaticVariants(requestId[0], requestId[1], [
        variantType,
      ])
      if (variantReq.status === 200) {
        var newData = variantReq.data[`${variantType}Data`].rows.map((item) => ({
          ...item,
          reportSelect: '',
        }))
        if (requestId.includes('clinic') && variantType === 'snv') {
          newData = newData.filter((item) => {
            return (
              item?.Filter[0] === 'PASS' &&
              (item?.CancerVar_Tier === 'Tier I strong ' ||
                item?.CancerVar_Tier === 'Tier II potential ' ||
                item?.Oncogenic === 'Oncogenic')
            )
          })
          setIsClinic(true)
        } else if (requestId[3] !== undefined && requestId[3] !== 'clinic') {
          if (variantType !== 'fusions') {
            const geneReq = await RunsService.getGenesListSingle(requestId[3])
            var genesLists = []
            if (geneReq.status === 200) {
              genesLists = geneReq.data.genesLists.map((item) => item.genesList).flat()
            }

            const geneFilteredResult = await getMatchingData(genesLists, newData, variantType)
            newData = geneFilteredResult
          }
        }

        const sortedHeader = variantReq?.data[`${variantType}Data`]?.order.reduce(
          (acc, {name, columnGroup}) => {
            if (variantReq?.data[`${variantType}Data`]?.header.hasOwnProperty(name)) {
              acc[name] = {
                value: variantReq?.data[`${variantType}Data`]?.header[name],
                columnGroup,
              }
            }
            return acc
          },
          {}
        )
        var reportSelect = {
          reportSelect: 'Child',
          columnGroup: null,
        }
        const finalHeader = {reportSelect, ...sortedHeader}

        if (variantType === 'snv') {
          setSnvData(newData)
          // if (requestId.includes('clinic')) {
            newData = newData.filter((item) => {
              return item.DET === 'Yes'
            })
          // }

          setSnvHeader(finalHeader)
        }
        if (variantType === 'sv') {
          setSvHeader(finalHeader)
        }
        if (variantType === 'fusions') {
          setFusionHeader(finalHeader)
        }

        setTableData((prev) => ({
          ...prev,
          [`${variantType}Data`]: newData,
        }))
        setIsLoadingData(false)
      }
    } catch (error) {
      console.error('Failed to fetch variant data:', error)
    }
  }
  useEffect(() => {
    const initializeData = async () => {
      setIsLoadingData(true)
      const requestId = id.split('&')
      const detail = await fetchRunDetail()
      if (!detail) return

      let initialTab = 1
      if (detail.processType === 'som-test-rna') {
        initialTab = 3
      } else if (requestId[2] === 'sv') {
        initialTab = 2
      } else if (requestId[2] === 'fusion') {
        initialTab = 3
      }

      setSelectedTab(initialTab)
      setIsLoadingData(false)
    }

    initializeData()
  }, [])

  useEffect(() => {
    const loadVariantData = async () => {
      const tabMapping = {1: 'snv', 2: 'sv', 3: 'fusions'}
      const variantType = tabMapping[selectedTab]
      if (variantType === undefined) return
      if (tableData[`${variantType}Data`] && tableData[`${variantType}Data`].length > 0) {
        return
      }
      setIsLoadingData(true)
      await fetchVariantData(variantType)
    }

    loadVariantData()
  }, [selectedTab])

  const [snvSelectedTableDatas, setSnvSelectedTableDatas] = useState([])
  const [svSelectedTableDatas, setSvSelectedTableDatas] = useState([])
  const [fusionSelectedTableDatas, setFusionSelectedTableDatas] = useState([])

  const [isLoadingReport, setIsLoadingReport] = useState('0')
  const changeSelectedTab = (id) => {
    if (id === selectedTab) return
    setSelectedTab(id)
    setGridOptions(undefined)
  }
  const [reportPayload, setReportPayload] = useState({})

  const generateReportFormHandler = async () => {
    setIsLoadingReport(true)
    const _carboneService = carboneSDK(
      'eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3MjIyMTU0MzI3NTgxMTY2MzkiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzU3ODEwNDYyLCJkYXRhIjp7InR5cGUiOiJwcm9kIn19.ASss8BruqhOwcUx3kQZvnKWVZdmUcyaDEeRmokkLqqSEsOE-mriBuwlw8V8yEHiW-eIo_eNYhO_NT_ZS0A0vkJ32Ab5q2kvT3UusqFuiUEpkpOHE5-Ebs-AACTpFnfOERlAbYut4dXczq_eHnYOhOuRCc0_oI1lKJKf4yI0BEoems1Y5'
    )
    try {
      if (
        !reportPayload.name ||
        !reportPayload.protocolNo ||
        !reportPayload.referralDate ||
        !reportPayload.referringCenter ||
        !reportPayload.patientName ||
        !reportPayload.referringDoctor
      ) {
        Swal.fire(' Please fill out all fields in the form!', '', 'error')
        setIsLoadingReport(false)
        return
      }
      reportPayload.date = Math.floor(Date.now() / 1000)

      var tableRowItem = []
      var contentRowItem = []
      const requestId = id.split('&')

      const somaticDetailReq = await somaticService.getSomaticDetail(requestId[1])
      reportPayload.clinicalDiagnosis = somaticDetailReq.data.clinicalDiagnosis
      reportPayload.collectionDate = somaticDetailReq.data.collectionDate
      reportPayload.receivedDate = somaticDetailReq.data.receivedDate
      reportPayload.panelType = somaticDetailReq.data.panelType
      reportPayload.dataType = somaticDetailReq.data.dataType
      reportPayload.specimenType = somaticDetailReq.data.specimenType
      reportPayload.specimenSite = somaticDetailReq.data.specimenSite
      reportPayload.loh = 'N/A'
      // reportPayload.loh = somaticDetailReq.data.somaticInfo.lohPercentage
      reportPayload.tmbLevel = somaticDetailReq.data?.somaticInfo?.tmbValue?.toFixed(2) + ' Muts/MB'
      if (somaticDetailReq.data?.somaticInfo?.msiTotalNumberOfSites > 230) {
        reportPayload.msiLevel =
          somaticDetailReq.data?.somaticInfo?.msiTotalNumberOfSites +
          ',' +
          somaticDetailReq.data?.somaticInfo?.msiValue
      } else {
        reportPayload.msiLevel = 'Undeterminable'
      }
      reportPayload.tmbTitle = 'Tumor Mutational Burden (TMB) '

      if (parseFloat(somaticDetailReq.data?.somaticInfo?.tmbValue).toFixed(2) > 200) {
        reportPayload.tmbScore = 'High TMB'
        reportPayload.tmbDesc =
          'Microsatellite instability (MSI) testing and tumor mutational burden (TMB) are genomic biomarkers used to identify patients who are likely to benefit from immune checkpoint inhibitors. Pembrolizumab was recently approved by the Food and Drug Administration for use in TMB-high (TMB-H) tumors, regardless of histology, based on KEYNOTE-158. The primary objective of this retrospective study was real-world applicability and use of immunotherapy in TMB/MSI-high patients to lend credence to and refine this biomarker.       '
      } else {
        reportPayload.tmbScore = 'Low TMB'
      }

      let arrays = [...snvSelectedTableDatas, ...svSelectedTableDatas, ...fusionSelectedTableDatas]
      await arrays.reduce(async (a, item) => {
        await a
        var contentRowData
        var oncoReq
        var tableRowData
        if ('SVType' in item) {
          oncoReq = await somaticService.getSelectedOncokbDatasSVandFusion(
            item.OncoKBGene,
            item.OncoKBGene,
            item.OncoKBSVType,
            'false'
          )
          tableRowData = {
            Ref_Gene: item.OncoKBGene,
            txChange: 'N/A',
            CanverVar: 'N/A',
            DET: 'N/A',
            VAF: 'N/A',
          }
          contentRowData = {
            columns: {
              first: item.OncoKBGene,
              second: '',
              third: item.OncoKBSVType,
              fourth: '',
              fifth: '',
            },
            Ref_Gene: item.OncoKBGene,
            geneSummary: oncoReq.data.geneSummary,
            variantSummary: oncoReq.data.variantSummary,
            highestLevel: item.HighestLevel,
            description: oncoReq?.data?.mutationEffect?.description
              ? oncoReq?.data?.mutationEffect?.description
              : ' Null ',
            svType: true,
          }
        } else if ('Fusion' in item) {
          oncoReq = await somaticService.getSelectedOncokbDatasSVandFusion(
            item.Fusion.split('-')[0],
            item.Fusion.split('-')[1],
            'FUSION',
            'true'
          )
          tableRowData = {
            Ref_Gene: item.Fusion,
            txChange: 'N/A',
            CanverVar: 'N/A',
            DET: 'N/A',
            VAF: 'N/A',
          }
          contentRowData = {
            columns: {
              first: item.Fusion,
              second: '',
              third: 'FUSION',
              fourth: '',
              fifth: '',
            },
            Ref_Gene: item.Fusion,
            geneSummary: oncoReq.data.geneSummary,
            variantSummary: oncoReq.data.variantSummary,
            highestLevel: item.HighestLevel,
            description: oncoReq.data.mutationEffect.description,
            svType: true,
            fusion: true,
          }
        } else {
          var vaf = parseFloat(item.AF) * 100
          var genomicLocation =
            item.Chrom + ',' + item.Start + ',' + item.End + ',' + item.Ref + ',' + item.Alt
          oncoReq = await somaticService.getSelectedOncokbDatasSNV(genomicLocation)
          var finding =
            item.txChange && item.aaChange === null
              ? 'N/A'
              : item.txChange + '(' + item.aaChange + ')'
          tableRowData = {
            Ref_Gene: item.Gene_refGene,
            finding: finding,
            lod: item.DET + '(' + item.DETS + ')',
            CanverVar: item.CancerVar_Tier,
            canverVarEVS: item.CancerVar_Evidence,
            DET: item.DET,
            DETS: item.DETS,
            VAF: vaf.toFixed(1) + ' % ',
          }
          contentRowData = {
            columns: {
              first: item.Gene_refGene,
              second: 'Request',
              third: item.txChange,
              fourth: item.aaChange,
              fifth: vaf.toFixed(1) + ' % ',
            },
            Ref_Gene: item.Gene_refGene,
            txChange: item.txChange,
            aaChange: item.aaChange,
            CanverVar: item.CancerVar_Tier,
            DET: item.DET,
            DETS: item.DETS,
            VAF: parseFloat(item.AF) * 100 + ' % ',
            highestLevel: item.HighestLevel,
            geneSummary: oncoReq.data.geneSummary,
            variantSummary: oncoReq.data.variantSummary,
            CanverVarTierEvs:
              '<b> Variant Interpretation: </b>' +
              item.CancerVar_Tier +
              '(' +
              item.CancerVar_Evidence +
              ')',
            variantInterpretation: 'Variant Interpretation:' + item.CancerVar_and_Evidence,
            description: oncoReq.data.mutationEffect.description
              ? oncoReq.data.mutationEffect.description
              : ' Null ',
          }
        }
        var getSymbol
        var getSymbol2
        var symbol1
        var symbol2
        if (contentRowData.fusion === true) {
          getSymbol = await axios
            .get(`https://rest.ensembl.org/lookup/symbol/homo_sapiens/${item.Fusion.split('-')[0]}`)
            .then((response) => {
              symbol1 = response.data.id
            })
            .catch((error) => {
              symbol1 = item.Fusion.split('-')[0]
            })
          getSymbol2 = await axios
            .get(`https://rest.ensembl.org/lookup/symbol/homo_sapiens/${item.Fusion.split('-')[1]}`)
            .then((response) => {
              symbol2 = response.data.id
            })
            .catch((error) => {
              symbol2 = item.Fusion.split('-')[1]
            })
          contentRowData.columns.second = symbol1 + '-' + symbol2
        } else {
          getSymbol = await axios.get(
            `https://rest.ensembl.org/lookup/symbol/homo_sapiens/${contentRowData.columns.first}`
          )
          contentRowData.columns.second = getSymbol.data.id
        }

        if (item.HighestLevel === 'LEVEL_2' || item.HighestLevel === 'LEVEL_1') {
          tableRowData.highestLevel = 'Yes'
        } else {
          tableRowData.highestLevel = 'No'
        }

        if (item.HighestLevel === 'LEVEL_1') {
          contentRowData.color = '#549b4e'
        } else if (item.HighestLevel === 'LEVEL_2') {
          contentRowData.color = '#1f78b6'
        } else if (item.HighestLevel === 'LEVEL_3A') {
          contentRowData.color = '#984ea3'
        } else if (item.HighestLevel === 'LEVEL_3B') {
          contentRowData.color = '#be98d0'
        } else if (item.HighestLevel === 'LEVEL_4') {
          contentRowData.color = '#424242'
        } else if (item.HighestLevel === 'LEVEL_Dx1') {
          contentRowData.color = '#35a12c'
        } else if (item.HighestLevel === 'LEVEL_Dx2') {
          contentRowData.color = '#1f78b6'
        } else if (item.HighestLevel === 'LEVEL_Dx3') {
          contentRowData.color = '#984ea3'
        } else if (item.HighestLevel === 'LEVEL_Px1') {
          contentRowData.color = '#35a12c'
        } else if (item.HighestLevel === 'LEVEL_Px2') {
          contentRowData.color = '#1f78b6'
        } else if (item.HighestLevel === 'LEVEL_Px3') {
          contentRowData.color = '#984ea3'
        } else if (item.HighestLevel === 'LEVEL_R1') {
          contentRowData.color = '#f03523'
        } else if (item.HighestLevel === 'LEVEL_R2') {
          contentRowData.color = '#f99a92'
        } else {
          contentRowData.color = '#000000'
        }
        let drugsDesc = []
        let alterations = ''
        let drugsStr = ''
        if (oncoReq.status === 200) {
          oncoReq.data.treatments?.forEach((drugItem) => {
            drugItem.drugs?.forEach((drug) => {
              drugsStr += drug.drugName + ','
            })
            let drugArray = drugsStr.split(',').map((drug) => drug.trim())

            let uniqueDrugsSet = new Set(drugArray)
            let uniqueDrugsArray = Array.from(uniqueDrugsSet)

            drugsDesc.push({
              description: drugItem.description,
              cancerType: drugItem.levelAssociatedCancerType.mainType.name,
              drugTitle: uniqueDrugsArray.join(' + ').slice(0, -2),
            })
            drugItem.alterations?.forEach((alteration) => {
              alterations += alteration + ','
            })
          })
        }
        if (contentRowData.svType === true) {
          let alterationsArray = alterations.split(',').map((drug) => drug.trim())
          let uniqueAlterationsSet = new Set(alterationsArray)
          let uniqueAlterationsArray = Array.from(uniqueAlterationsSet)
          contentRowData.columns.fourth = uniqueAlterationsArray.join(' , ').slice(0, -2)
        }

        let drugArray = drugsStr.split(',').map((drug) => drug.trim())

        let uniqueDrugsSet = new Set(drugArray)
        let uniqueDrugsArray = Array.from(uniqueDrugsSet)

        tableRowData.drugNames = uniqueDrugsArray.join(', ')
        contentRowData.drugNames = uniqueDrugsArray.join(' + ').slice(0, -2)
        const uniqueDescriptions = [...new Set(drugsDesc.map((item) => item.description))]

        const newData = uniqueDescriptions.map((description) =>
          drugsDesc.find((item) => item.description === description)
        )
        // var uniqueArray = [...new Set(drugsDesc)]
        contentRowData.drugsDesc = newData
        contentRowItem.push(contentRowData)
        tableRowItem.push(tableRowData)
      }, Promise.resolve())

      reportPayload.testContent =
        'Agilent SureSelect Cancer CGP Assay, a targeted, pan-cancer panel based on hybridization capture and the next generation sequencing technology, enables running two parallel assays; 679  genes on DNA level and 80 genes on RNA level associated to solid cancers are analysed in this test for detecting different types of variations, including single nucleotide variants (SNV), copy number variations (CNV), small insertions and deletions (Indel), translocations (TLs). Immuno-oncology biomarkers like Tumora Mutational Burden and Microsatellite Instability (MSI) and RNA gene fusions are also covered. Please see https://rb.gy/o3sg9. '
      reportPayload.oncoTable = tableRowItem
      reportPayload.oncoDesc = contentRowItem
      console.log(reportPayload)
      try {
        _carboneService
          .render('b3c32c5c46f38d29f22f504f320bf554e8cdc5e315224746d33bf1cca2772a09', {
            data: reportPayload,
            convertTo: 'pdf',
          })
          .then((res) => {
            var link = window.document.createElement('a')
            link.href = window.URL.createObjectURL(res.content)
            link.download = link.href.split('/').pop() + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
      } catch (error) {
        console.log('json is not valid')
      }
    } catch (error) {
      console.log('something went wrong', error)
    }
    setIsLoadingReport(false)
  }

  useEffect(() => {
    if (isLoadingReport === true) {
      Swal.fire({
        title: 'Generating Report...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
    } else if (isLoadingReport === false) {
      Swal.close()
      Swal.fire({
        title: 'Report Generated',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isLoadingReport])
  const hiddenColumns = [
    'Chrom',
    'Start',
    'End',
    'Ref',
    'Alt',
    'Func_refGene',
    'OMIM',
    'Phenotype_MIM',
    'OrphaNumber',
    'Orpha',
    'ICGC_Id',
    'GT',
    'AD',
    'DET',
  ]
  // if (isLoadingData) {
  //   return (
  //     <div className='row'>
  //       <div className='card card-custom'>
  //         <div className='card-body table-card text-center d-flex align-items-center justify-content-center'>
  //           <Loader />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  // if lodSwitch is true filter the data DET === Yes else show all data
  useEffect(() => {
    setIsLoadingData(true)
    if (lodSwitch) {
      const filteredData = tableData.snvData.filter((item) => item.DET === 'Yes')
      setTableData((prev) => ({
        ...prev,
        snvData: filteredData,
      }))
    } else {
      setTableData((prev) => ({
        ...prev,
        snvData: snvData,
      }))
    }
    setIsLoadingData(false)
  }, [lodSwitch])

  return (
    <>
      <div className='row'>
        <div className='card card-custom'>
          <div className='card-body table-card'>
            <div className='somatic-detail-table-buttons col-md-12'>
              <h6 className='mb-5 mx-3'>
                {' '}
                {runDetail?.id !== undefined ? runDetail.samples[0].sampleId : ''}
              </h6>

              {(runDetail.processType === 'som-test-tumor-rna' ||
                runDetail.processType === 'som-test-tumor') && (
                <>
                  <span
                    className={` mx-2 btn btn-${selectedTab === 1 ? 'primary' : 'secondary'}`}
                    onClick={() => {
                      if (isLoadingData) return

                      changeSelectedTab(1)
                    }}
                  >
                    SNVs/Short INDELs
                  </span>
                  <span
                    className={` mx-2 btn btn-${selectedTab === 2 ? 'primary' : 'secondary'}`}
                    onClick={() => {
                      if (isLoadingData) return

                      changeSelectedTab(2)
                    }}
                  >
                    Structural Variants/CNVs
                  </span>
                </>
              )}

              {(runDetail.processType === 'som-test-tumor-rna' ||
                runDetail.processType === 'som-test-rna') && (
                <span
                  className={` mx-2 btn btn-${selectedTab === 3 ? 'primary' : 'secondary'} `}
                  onClick={() => {
                    if (isLoadingData) return

                    changeSelectedTab(3)
                  }}
                >
                  Fusion Genes
                </span>
              )}
              {selectedTab === 1  && (
                <div
                  className=' form-check form-switch form-switch-sm form-check-custom form-check-solid'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content='Within Limit of Detection parameters.'
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <label className='font-weight-bold mx-3'> LoD </label>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => setLodSwitch(e.target.checked)}
                    defaultChecked={lodSwitch}
                    name='lodSwitch'
                  />
                </div>
              )}

              <span
                className={` mx-2 btn pull-right btn-dark `}
                onClick={() => {
                  const convertData =
                    selectedTab === 1
                      ? tableData.snvData
                      : selectedTab === 2
                      ? tableData.svData
                      : tableData.fusionsData
                  const tsvData = convertJSONtoTSV(convertData)

                  downloadTSV(tsvData, 'data.tsv')
                }}
              >
                Download as TSV
              </span>
              {snvSelectedTableDatas.length > 0 ||
              svSelectedTableDatas.length > 0 ||
              fusionSelectedTableDatas.length > 0 ? (
                <button
                  className='btn btn-warning pull-right'
                  data-bs-toggle='modal'
                  data-bs-target='#GenerateReportModal'
                >
                  Generate Report
                </button>
              ) : null}
            </div>
            <PageTitle breadcrumbs={[]}>Somatic Details</PageTitle>
            {!isLoadingData ? (
              <>
                <>
                  {selectedTab === 1 && (
                    <TableComponent
                      tableHeader={snvHeader}
                      data={tableData.snvData}
                      isTreeData={false}
                      selectedRows={snvSelectedTableDatas}
                      setSelectedRows={setSnvSelectedTableDatas}
                      gridId={'snvTable'}
                      gridOptions={gridOptions}
                      hiddenColumns={hiddenColumns}
                      setAllelicWidgetDatas={setAllelicWidgetDatas}
                      isClinic={isClinic}
                      setGridOptions={setGridOptions}
                      selectedWidgetItem={selectedRow}
                      setSelectedWidgetItem={setSelectedRow}
                    />
                  )}
                  {selectedTab === 2 && (
                    <TableComponentTreeData
                      tableHeader={svHeader}
                      data={tableData.svData}
                      gridOptions={gridOptions}
                      setGridOptions={setGridOptions}
                      selectedRows={svSelectedTableDatas}
                      setSelectedRows={setSvSelectedTableDatas}
                      gridId={'svTable'}
                      selectedWidgetItem={selectedRow}
                      setSelectedWidgetItem={setSelectedRow}
                    />
                  )}
                  {selectedTab === 3 && (
                    <TableComponent
                      tableHeader={fusionHeader}
                      data={tableData.fusionsData}
                      isTreeData={false}
                      selectedRows={fusionSelectedTableDatas}
                      setSelectedRows={setFusionSelectedTableDatas}
                      gridId={'fusionsTable'}
                      hiddenColumns={[]}
                      setAllelicWidgetDatas={setAllelicWidgetDatas}
                      gridOptions={gridOptions}
                      setGridOptions={setGridOptions}
                      selectedWidgetItem={selectedRow}
                      setSelectedWidgetItem={setSelectedRow}
                    />
                  )}
                </>
              </>
            ) : (
              <div className='card-body table-card text-center d-flex align-items-start justify-content-center'>
                <Loader />
              </div>
            )}
          </div>
        </div>
        <GenerateReportModal
          generateReportFormHandler={generateReportFormHandler}
          reportPayload={reportPayload}
          setReportPayload={setReportPayload}
        />

        {selectedTab === 1 && (
          <div className={`table-ivg`} style={{position: 'relative'}}>
            <IgvTable
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              allelicWidgetDatas={allelicWidgetDatas}
              selectedTab={selectedTab}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          </div>
        )}
        <Tooltip id='my-tooltip' />
      </div>
    </>
  )
}

export {SomaticDetailTable}
