import {KTIcon} from '../../../_metronic/helpers'

const ResultStep = () => {
  return (
    <div className='w-100'>
      <div className='pb-2'></div>
      <div className='mb-0'>
        {/* <div className='fs-6 text-gray-600 mb-5'>
          Writing headlines for blog posts is as much an art as it is a science and probably
          warrants its own post, but for all advise is with what works for your great & amazing
          audience.
        </div> */}

        <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6'>
          <KTIcon iconName='check' className='fs-2tx text-success me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Transfer is completed!</h4>
              <div className='fs-6 text-gray-600'>Run Created Successfully.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ResultStep}
