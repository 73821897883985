/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {CustomCheck} from '../../CustomCheck'

const TrioFamilyFilter = ({tinyRuns, sampleId, familyFilterDatas, setFamilyFilterDatas}) => {
  const [filters, setFilter] = useState({
    fatherRun: null,
    motherRun: null,
  })

  const [motherChecked, setMotherChecked] = useState(
    familyFilterDatas.filterDetails.motherSelectCheckStatus
  )
  const [fatherChecked, setFatherChecked] = useState(
    familyFilterDatas.filterDetails.fatherSelectCheckStatus
  )
  const [recessiveChecked, setRecessiveChecked] = useState(
    familyFilterDatas.filterDetails.recessive
  )
  const [strictChecked, setStrictChecked] = useState(familyFilterDatas.filterDetails.strict)
  console.log(recessiveChecked)

  familyFilterDatas.filterDetails.motherSelectCheckStatus = motherChecked
  familyFilterDatas.filterDetails.fatherSelectCheckStatus = fatherChecked
  console.log(filters)
  setFamilyFilterDatas(familyFilterDatas)
  return (
    <div className='familyFilterBody my-5'>
      <div className='familyFilterParents col-md-6'>
        <div className='familyItem d-flex align-items-center my-2'>
          <label className='w-55'>Proband :</label>
          <span className='w-75'> {sampleId}</span>
          <div className='form-check form-switch form-switch-sm mx-2 form-check-custom form-check-solid invisible '>
            <CustomCheck checkStatus={motherChecked} setCheckStatus={setMotherChecked} />
          </div>
        </div>
        <div className='familyItem d-flex align-items-center'>
          <label className='w-55'>Father : </label>
          <select
            onChange={(e) => {
              setFilter({
                motherRun: filters.motherRun,
                fatherRun: tinyRuns.find((run) => {
                  return run.samples[0].id === e.target.value
                }),
              })

              familyFilterDatas.filterDetails.fatherSelect = e.target.value
              setFamilyFilterDatas(familyFilterDatas)
            }}
            className='form-select  form-select-solid form-select-sm w-75 '
          >
            <option>Select</option>
            {tinyRuns
              ?.filter((run) => run?.id !== filters?.motherRun?.id)
              ?.map((run, key) => (
                <option
                  key={key}
                  // disabled={selectedOption2 === run.samples[0]?.sampleId}
                  selected={familyFilterDatas.filterDetails.fatherSelect === run?.samples[0].id}
                  value={run?.samples[0].id}
                >
                  {run?.samples[0]?.sampleId}
                </option>
              ))}
          </select>
          <div className='form-check form-switch form-switch-sm mx-2 form-check-custom form-check-solid'>
            <CustomCheck checkStatus={fatherChecked} setCheckStatus={setFatherChecked} />
          </div>
        </div>
        <div className='familyItem d-flex align-items-center my-2'>
          <label className='w-55'>Mother :</label>
          <select
            onChange={(e) => {
              setFilter({
                fatherRun: filters.fatherRun,
                motherRun: tinyRuns.find((run) => run.samples[0].id === e.target.value),
              })
              familyFilterDatas.filterDetails.motherSelect = e.target.value
              setFamilyFilterDatas(familyFilterDatas)
            }}
            className='form-select  form-select-solid form-select-sm w-75  '
          >
            <option>Select</option>
            {tinyRuns
              ?.filter((run) => run?.id !== filters?.fatherRun?.id)
              ?.map((run, key) => (
                <option
                  key={key}
                  selected={familyFilterDatas.filterDetails.motherSelect === run?.samples[0].id}
                  value={run?.samples[0].id}
                >
                  {run?.samples[0]?.sampleId}
                </option>
              ))}
          </select>
          <div className='form-check form-switch form-switch-sm mx-2 form-check-custom form-check-solid'>
            <CustomCheck checkStatus={motherChecked} setCheckStatus={setMotherChecked} />
          </div>
        </div>
      </div>
      <div className='familyFilterCheckboxes  '>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              onChange={(e) => {
                familyFilterDatas.filterDetails.dominant = e.target.checked
                setFamilyFilterDatas(familyFilterDatas)
              }}
              defaultChecked={familyFilterDatas.filterDetails.dominant}
            />
            <span
              className='fw-bold ps-2 fs-6'
              data-tooltip-id='my-tooltip'
              data-tooltip-content='  Return all heterozygous variants of the proband'
            >
              Dominant
            </span>
          </label>
        </div>

        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              disabled={motherChecked === 1 && fatherChecked === 1 && strictChecked === true}
              onChange={(e) => {
                setRecessiveChecked(e.target.checked)
                familyFilterDatas.filterDetails.recessive = e.target.checked
                setFamilyFilterDatas(familyFilterDatas)
              }}
              defaultChecked={familyFilterDatas.filterDetails.recessive}
            />
            <span
              className='fw-bold ps-2 fs-6'
              data-tooltip-id='my-tooltip'
              data-tooltip-content='  Returns homozygous, hemizygous and compound heterozygous variants of the proband.'
            >
              Recessive
            </span>
          </label>
          {motherChecked === 1 && fatherChecked === 1 && recessiveChecked === true ? (
            <div className='checkboxesItem my-3 mx-3'>
              <label className='form-check form-check-inline form-check-solid'>
                <input
                  className='form-check-input'
                  name='communication[]'
                  type='checkbox'
                  onChange={(e) => {
                    setStrictChecked(e.target.checked)
                    familyFilterDatas.filterDetails.strict = e.target.checked
                    setFamilyFilterDatas(familyFilterDatas)
                  }}
                  defaultChecked={familyFilterDatas.filterDetails.strict}
                />
                <span
                  className=' ps-2 fs-7'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content=' Identify only variants that show evidence of inheritance from both parents.'
                >
                  Strict
                </span>
              </label>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* <div className='familyFilterCheckboxes '>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <span className='fw-bold ps-2 fs-6'>In Filter : 0</span>
          </label>
        </div>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <span className='fw-bold ps-2 fs-6 text-success'>Include : 0</span>
          </label>
        </div>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <span className='fw-bold ps-2 fs-6 text-danger'>Exclude : 0</span>
          </label>
        </div>
      </div> */}
      <Tooltip id='my-tooltip' />
    </div>

    //  Card End
  )
}

export {TrioFamilyFilter}
