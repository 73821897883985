import React, {useEffect} from 'react'
import FileInput from '../FileInput'
import OptionsSelect from '../OptionsSelect'
import SwitchInput from '../SwitchInput'

const PrsFormFiles = ({sampleData, setSampleData, selectedProcessType}) => {
  return (
    <div>
      {sampleData.map((sample, index) => (
        <div
          className=' d-flex align-items-center  flex-wrap justify-content-between mt-5 p-2'
          key={index}
        >
          <FileInput label={'Upload Genotype File'} />
        </div>
      ))}
    </div>
  )
}

export default PrsFormFiles
