import Chart from 'react-apexcharts'

const IsppChart = ({chartData, color}) => {
  const options = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true, // Animasyonu etkinleştir
        speed: 150, // 500 milisaniye animasyon süresi (isteğinize göre değiştirebilirsiniz)
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: chartData.groupScores.map((score) => score.title),
      labels: {
        style: {
          fontSize: '8px',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      labels: {
        style: {
          fontSize: '8px',
          fontWeight: 'bold',
        },
      },
    },
    title: {
      text: chartData.title,
      align: 'center',
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '8px',
        fontWeight: 'bold',
      },
    },
    tooltip: {
      y: {
        formatter: (val, {seriesIndex, dataPointIndex, w}) => {
          const score = chartData.groupScores[dataPointIndex].score
          return ` Ranked Score : ${val}<br> Score: ${score}`
        },
      },
      style: {
        fontSize: '12px',
      },
    },
    colors: color,
  }

  const series = [
    {
      name: '',
      data: chartData.groupScores.map((score) => score.scoreRs),
    },
  ]

  return (
    <div className='bar-chart'>
      <Chart options={options} series={series} type='bar' height='250px' />
    </div>
  )
}

export {IsppChart}
