import {useEffect} from 'react'
import {marked} from 'marked'
import {Loader} from '../Loader'

const CustomAnnotationItemDetailModal = ({
  currentNode,
  subDataIsLoading,
  onClose,
  selectedSubTag,
}) => {
  const addPrefixToImageUrls = (markdownText, prefix) => {
    return markdownText.replace(/!\[([^\]]*)\]\(([^)]+)\)/g, (match, alt, url) => {
      if (!url.startsWith('http')) {
        url = prefix + url
      }
      return `![${alt}](${url})`
    })
  }

  const MarkdownRenderer = ({markdownText, prefix}) => {
    const updatedMarkdown = addPrefixToImageUrls(markdownText, prefix)
    const createMarkup = () => {
      return {__html: marked(updatedMarkdown)}
    }

    useEffect(() => {
      const images = document.querySelectorAll('.markdown-content img')
      images.forEach((img) => {
        img.style.maxWidth = '100%'
      })
    }, [markdownText])

    return <div className='markdown-content' dangerouslySetInnerHTML={createMarkup()} />
  }

  return (
    <>
      {/* Modalın arka planı */}
      <div className='custom-modal-backdrop' onClick={onClose}></div>

      {/* Modalın kendisi */}
      <div className='custom-modal'>
        <div className='custom-modal-header'>
          <button className='custom-modal-close-button' onClick={onClose}>
            &times;
          </button>
        </div>
        <div className='custom-modal-body'>
          <div className='row'>
            {subDataIsLoading && <Loader />}
            {selectedSubTag && !subDataIsLoading && (
              <>
                <div className='col-md-12'>
                  {selectedSubTag.hasLogo && (
                    <img
                      src={`https://acurare-01.node.gennext.bio/store/locallogo?module=${selectedSubTag.title}`}
                      style={{width: '125px ', marginBottom: '20px'}}
                      alt={selectedSubTag.title}
                    />
                  )}
                </div>
                <div className='col-md-9'>
                  <div className='tag-desc-content'>
                    <MarkdownRenderer
                      markdownText={selectedSubTag.desc}
                      prefix={`https://${currentNode}.node.gennext.bio/modules/annotators/${selectedSubTag.title}/`}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <p>{selectedSubTag.short_desc}</p>
                  <p>
                    <b>Module version</b>: {selectedSubTag.latest_version}
                  </p>
                  <p>
                    <b>E-mail</b>: {selectedSubTag.developer.email}
                  </p>
                  <p>
                    <b>Citation</b>: {selectedSubTag.developer.citation}
                  </p>
                  <p>
                    <b>Organization</b>: {selectedSubTag.developer.organization}
                  </p>
                  <p className='fs-9'>
                    <a
                      href={selectedSubTag.developer.website}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {selectedSubTag.developer.website}
                    </a>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='custom-modal-footer'>
          {/* <button type='button' className='btn btn-light' onClick={onClose}>
            Close
          </button> */}
        </div>
      </div>
    </>
  )
}

export default CustomAnnotationItemDetailModal
