const PillIcon = ({ color = "#000000", width = 20, height = 20 }) => (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 400 400"
      fill={color} // Fill rengi burada dinamik olarak ayarlanıyor
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M281.548 32.055 C 262.879 33.165,245.545 40.395,231.645 52.872 C 229.343 54.938,53.724 230.634,52.163 232.432 C 23.722 265.201,25.415 313.761,56.064 344.311 C 87.177 375.325,137.336 376.130,169.529 346.132 C 172.403 343.453,346.369 169.354,347.687 167.837 C 363.128 150.061,370.187 127.585,367.588 104.470 C 362.748 61.432,324.789 29.484,281.548 32.055 M285.221 80.289 C 293.747 81.358,298.506 90.333,294.502 97.789 C 292.626 101.282,289.358 103.444,285.042 104.048 C 283.341 104.285,282.211 104.698,280.828 105.586 C 280.408 105.856,272.284 113.881,258.446 127.697 C 244.329 141.791,236.472 149.552,236.004 149.863 C 224.936 157.219,211.889 143.718,219.681 132.973 C 220.489 131.859,263.379 89.007,265.021 87.673 C 270.903 82.896,279.870 79.618,285.221 80.289 M189.459 275.168 C 146.412 318.236,144.553 320.079,142.385 321.828 C 128.476 333.046,109.538 335.135,93.549 327.212 C 68.764 314.931,60.473 284.008,75.787 260.966 C 78.328 257.144,76.094 259.436,123.599 211.911 L 167.715 167.777 199.959 200.090 L 232.203 232.403 189.459 275.168" 
        stroke="none" 
        fill={color} // Burada fill rengi değiştirilebilir
        fillRule="evenodd"
        />
      </g>
    </svg>
  );
  
  export default PillIcon;
  