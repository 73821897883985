import React, {FC, useEffect, useRef, useState} from 'react'
import {RunInformationStep, Step2} from './steps/RunInformationStep'
import {SampleFilesStep} from './steps/SampleFilesStep'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import {KTIcon} from '../../_metronic/helpers'
import {StepperComponent} from '../../_metronic/assets/ts/components'
import {SampleInformationStep} from './steps/SampleInformationStep'
import {ResultStep} from './steps/ResultStep'

const NewRunWizard = ({
  sampleData,
  setSampleData,
  selectedProcessType,
  setSelectedProcessType,
  userProcessTypes,
  setEnrichmentKits,
  fileType,
  enrichmentKits,
  setGenomeBuild,
  enrichmentKit,
  setEnrichmentKit,
  onRunCreate,
  qrCodeData,
  setQrCodeData,
  isPairedEnd,
  setIsPairedEnd,
  institutes,
  setInstitutes,
  physicians,
  setPhysicians,
  analysts,
  setAnalysts,
  setPhysiciansString,
  enrichmentOrRegion,
  setEnrichmentOrRegion,
  setAnalystsString,
  setInstitutesString,
  fileTypes,
  setFileType,
}) => {
  const stepperRef = useRef(null)
  const stepper = useRef(null)
  // const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState(inits)
  const [emptyField, setEmptyField] = useState({})
  const [disabledButton, setDisabledButton] = useState(false)
  const [isSubmitButton, setIsSubmitButton] = useState(false)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current)
  }

  function validateSampleId(value) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9_.-]{3,29}[a-zA-Z0-9]$/
    return regex.test(value)
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    console.log(stepper)
    stepper.current.goto(stepper.current.currentStepIndex - 1)
    setDisabledButton(false)
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
    setIsSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = (values, actions) => {
    if (!stepper.current) {
      return
    }
    setEmptyField({error: false, message: ''})
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      if (stepper.current.currentStepIndex === 2) {
        if (sampleData[0].id === '' && !validateSampleId(sampleData[0].id)) {
          setEmptyField({error: true, message: 'Please enter a valid SAMPLE ID'})
          return
        } else {
          stepper.current.goNext()
          return
        }
      } else if (stepper.current.currentStepIndex === 3) {
        console.log('sasass')
        // onRunCreate()
      } else {
        stepper.current.goNext()
      }
    } else {
      onRunCreate()
      // stepper.current.goto(1)
      actions.resetForm()
    }
    console.log(stepper.current, stepper.current.totalStepsNumber)
    setIsSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  function isButtonDisabled(sampleData, fileType, processType, stepper) {
    if (stepper?.current?.currentStepIndex === 1) {
      return false
    }
    if (stepper?.current?.currentStepIndex === 2) {
      if (sampleData[0].id === '' || !validateSampleId(sampleData[0].id)) {
        return true
      }
    }
    if (stepper?.current?.currentStepIndex === 3) {
      // Eğer fileType 'fastq' ise
      if (fileType === 'fastq') {
        if (['wgs', 'wes', 'tps', 'rna-seq', 'som-test-tumor'].includes(processType)) {
          return !sampleData[0].files?.fqR1 || !sampleData[0].files?.fqR2
        } else if (processType === 'som-test-rna') {
          return !sampleData[0].files?.fqR1RNA || !sampleData[0].files?.fqR2RNA
        } else if (processType === 'som-test-tumor-rna') {
          return (
            !sampleData[0].files?.fqR1 ||
            !sampleData[0].files?.fqR2 ||
            !sampleData[0].files?.fqR1RNA ||
            !sampleData[0].files?.fqR2RNA
          )
        }
      }
      // Eğer fileType 'bam' veya 'vcf' ise
      if (fileType === 'bam') {
        if (processType === 'som-test-rna') {
          return !sampleData[0].files?.bamRna
        } else if (processType === 'som-test-tumor-rna') {
          return !sampleData[0].files?.bam || !sampleData[0].files?.bamRNA
        }
        return !sampleData[0].files?.bam
      }
      if (fileType === 'vcf') {
        return !sampleData[0].files?.vcf
      }
    }

    // Diğer durumlarda buton disabled olmasın
    return false
  }
  console.log(stepper?.current?.currentStepIndex, stepper?.current?.totalStepsNumber)
  useEffect(() => {
    setDisabledButton(isButtonDisabled(sampleData, fileType, selectedProcessType, stepper))
  }, [sampleData, fileType, selectedProcessType, stepper, stepper?.current?.currentStepIndex])
  return (
    <div className=''>
      <div className=''>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column '
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Run Information</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Samples Information</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Samples Files</h3>
            </div>
            {/* 
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div> */}
          </div>

          <Formik initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto px-10 w-100  pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <RunInformationStep
                    sampleData={sampleData}
                    setSampleData={setSampleData}
                    selectedProcessType={selectedProcessType}
                    setSelectedProcessType={setSelectedProcessType}
                    userProcessTypes={userProcessTypes}
                    setEnrichmentKits={setEnrichmentKits}
                    fileType={fileType}
                    enrichmentKits={enrichmentKits}
                
                    isPairedEnd={isPairedEnd}
                    setIsPairedEnd={setIsPairedEnd}
                    enrichmentKit={enrichmentKit}
                    setEnrichmentKit={setEnrichmentKit}
                    setGenomeBuild={setGenomeBuild}
                    institutes={institutes}
                    setInstitutes={setInstitutes}
                    physicians={physicians}
                    setPhysicians={setPhysicians}
                    analysts={analysts}
                    setAnalysts={setAnalysts}
                    setEnrichmentOrRegion={setEnrichmentOrRegion}
                    setPhysiciansString={setPhysiciansString}
                    setAnalystsString={setAnalystsString}
                    setInstitutesString={setInstitutesString}
                    fileTypes={fileTypes}
                    setFileType={setFileType}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <SampleInformationStep
                    selectedProcessType={selectedProcessType}
                    sampleData={sampleData}
                    enrichmentOrRegion={enrichmentOrRegion}
                    setEnrichmentOrRegion={setEnrichmentOrRegion}
                    validateSampleId={validateSampleId}
                    userProcessTypes={userProcessTypes}
                    enrichmentKits={enrichmentKits}
                    qrCodeData={qrCodeData}
                    setQrCodeData={setQrCodeData}
                    enrichmentKit={enrichmentKit}
                    institutes={institutes}
                    setInstitutes={setInstitutes}
                    physicians={physicians}
                    setPhysicians={setPhysicians}
                    analysts={analysts}
                    setAnalysts={setAnalysts}
                    setPhysiciansString={setPhysiciansString}
                    setAnalystsString={setAnalystsString}
                    setInstitutesString={setInstitutesString}
                    setEnrichmentKit={setEnrichmentKit}
                    fileType={fileType}
                    setSampleData={setSampleData}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <SampleFilesStep
                    selectedProcessType={selectedProcessType}
                    sampleData={sampleData}
                    enrichmentOrRegion={enrichmentOrRegion}
                    fileType={fileType}
                    setSampleData={setSampleData}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <ResultStep />
                </div>
                <div className='error-message'>
                  {!!emptyField && emptyField.error && (
                    <div className='alert alert-danger my-5 d-flex align-items-center p-2'>
                      <KTIcon iconName='information-5' className='fs-2tx text-danger me-4' />

                      <div className='d-flex flex-column'>
                        <span>{emptyField.message}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='d-flex flex-stack pt-5'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>

                  <div>
                    <button
                      type='submit'
                      disabled={disabledButton}
                      className='btn btn-lg btn-primary me-3'
                      // onClick={() => {
                      //   if (stepper.current.currentStepIndex === stepper.current.totalStepsNumber) {
                      //     onRunCreate()
                      //   }
                      // }}
                    >
                      <span className='indicator-label'>
                        {(stepper?.current?.currentStepIndex !==
                          stepper?.current?.totalStepsNumber ||
                          stepper?.current?.currentStepIndex === undefined) &&
                          'Next'}

                        {(stepper?.current?.currentStepIndex ===
                          stepper?.current?.totalStepsNumber &&
                          stepper?.current?.currentStepIndex !== undefined) &&
                          'Start Upload'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {NewRunWizard}
