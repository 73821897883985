import React, {useState, useEffect, useRef} from 'react'
import './TableComponent.scss'
import {
  Column,
  Filters,
  GridOption,
  SlickgridReact,
  OperatorType,
  FieldType,
  SlickgridReactInstance,
} from 'slickgrid-react'
import {Loader} from '../../components/Loader'
import {
  GeneReferenceFormatter,
  avsnpFormatter,
  cancerVarFormatter,
  cosmicFormatter,
  detFormatter,
  detsFormatter,
  filterFormatter,
  icgcIdFormatter,
  omimFormatter,
  oncogenicFormatter,
  orphaNumberFormatter,
  phenoTypeMIM,
  filterObjects,
  canverEvTooltips,
  levelFormatter,
  VariantIdFormatter,
} from './Formatters'
import {Tooltip} from 'react-tooltip'
import {CustomTextFilter} from './Filters/CustomTextFilter'
import {CustomDropdownFilter} from './Filters/CustomMultiSelectFilter'

const TableComponent = ({
  data,
  setSelectedRows,
  setSelectedWidgetItem,
  tableHeader,
  gridOptions,
  setAllelicWidgetDatas,
  isFilterable = true,
  canMultiSelect = true,
  runId = '',
  autoHeight = true,
  hiddenColumns,
  setGridOptions,
  isClinic,
  gridId,
}) => {
  const [columnDefinitions, setColumnDefinitions] = useState<Column[]>([])
  useEffect(() => {
    if (data.length >= 1) {
      setGridOptions(undefined)
      defineGrid()
    }
  }, [data])
  // useEffect(() => {
  //   const demoContainer = document.getElementById('kt_app_sidebar_menu_wrapper');
  //   if (demoContainer) {
  //     const tableHeight = demoContainer.offsetHeight - 120; // Ana yüksekliği ayarla
  //     console.log('Calculated Table Height:', tableHeight);

  //     const slickPane = document.querySelector('.slick-pane') as HTMLElement; // slick-pane elemanını al
  //     const slickViewport = document.querySelector('.slick-viewport') as HTMLElement; // slick-viewport elemanını al

  //     if (slickPane) {
  //       slickPane.style.minHeight = `${tableHeight}px`; // slick-pane için tam yükseklik
  //     }

  //     if (slickViewport) {
  //       slickViewport.style.minHeight = `${tableHeight - 100}px`; // slick-viewport için azaltılmış yükseklik
  //     }
  //   }
  // }, [gridOptions]);

  const defineGrid = () => {
    const columnDefs = Object.keys(tableHeader).map((columnKey) =>
      createColumnDefinition(columnKey, data, tableHeader, isClinic, canMultiSelect, runId)
    )

    const gridOpts: GridOption = createGridOptions(
      gridId,
      canMultiSelect,
      autoHeight,
      isFilterable,
      isClinic
    )

    setColumnDefinitions(columnDefs)
    setGridOptions(gridOpts)
  }
  const onGrid1SelectedRowsChanged = (_e: Event, args: any) => {
    const grid = args && args.grid
    if (Array.isArray(args.rows)) {
      setSelectedRows(
        args.rows.map((idx: number) => {
          const item = grid.getDataItem(idx)
          return item
        })
      )
    }
  }
  const onCellClicked = (e, args) => {
    const grid = args && args.grid
    const rows = document.querySelectorAll('.slick-row')

    rows.forEach((row) => row.classList.remove('background-red'))
    if (e.target.parentElement?.classList.contains('slick-row')) {
      e.target.parentElement.classList.add('background-red')
    } else if (e.target.classList.contains('slick-row')) {
      e.target.classList.add('background-red')
    }

    const clickedData = grid.getDataItem(args.row)
    const alleleDatas = data.filter((item) => clickedData.alleleIds.includes(item.id))
    setSelectedWidgetItem(clickedData)
    setAllelicWidgetDatas(alleleDatas)
  }

  const onGridLoaded = (gridInstance: SlickgridReactInstance) => {
    if (isClinic && hiddenColumns?.length) {
      gridInstance.gridService.hideColumnByIds(hiddenColumns)
    }
  }

  return data.length === 0 || !gridOptions ? (
    <div className='card'>
      <div className='card-body text-center'>
        <Loader />
      </div>
    </div>
  ) : (
    <div id='demo-container' className='container-fluid mt-5'>
      <div id='grid-container' className='col-sm-12 custom-horizontal-scrollbar'>
        <SlickgridReact
          gridId={gridId}
          columnDefinitions={columnDefinitions}
          gridOptions={gridOptions}
          dataset={data}
          onSelectedRowsChanged={($event) =>
            onGrid1SelectedRowsChanged($event.detail.eventData, $event.detail.args)
          }
          onClick={($event) => {
            onCellClicked($event.detail.eventData, $event.detail.args)
          }}
          onReactGridCreated={(event) => onGridLoaded(event.detail)}
        />
        <Tooltip id='my-tooltip' />
      </div>
    </div>
  )
}

export default TableComponent

function createGridOptions(
  gridId: string,
  canMultiSelect: boolean,
  autoHeight: boolean,
  isFilterable: boolean,
  isClinic: boolean
) {
  const visibleRowCount = calculateVisibleRows(isFilterable)
  const filters =
    gridId === 'snvTable'
      ? [
          {
            columnId: 'Filter',
            searchTerms: ['PASS'],
          },
        ]
      : [
          {
            columnId: 'Filter',
            searchTerms: [
              'PASS',
              'clustered_events',
              'duplicate',
              'fragment',
              'multiallelic',
              'n_ratio',
              'orientation',
              'position',
              'slippage',
              'haplotype',
              'germline',
              'strict_strand',
            ],
          },
        ]
  return {
    autoResize: {container: '#demo-container', rightPadding: 10},
    autoHeight: false,
    minHeight: 500,
    enableAutoSizeColumns: true,
    enableAutoResize: true,
    enableFiltering: true,
    frozenColumn: canMultiSelect ? 0 : -1,
    enableRowSelection: canMultiSelect,
    enableCheckboxSelector: canMultiSelect,
    // createPreHeaderPanel: true,
    // showPreHeaderPanel: true,
    // preHeaderPanelHeight: isFilterable ? 28 : 0,
    enablePagination: true,
    pagination: {
      pageSizes: visibleRowCount
        ? [visibleRowCount, visibleRowCount + 5, visibleRowCount + 10]
        : [10],
      pageSize: visibleRowCount || 10, // Fallback to 10 if visibleRowCount is undefined or null
    },
    multiColumnSort: true,
    enableTreeData: false,
    checkboxSelector: {
      hideSelectAllCheckbox: true,
    },
    showCustomFooter: true,
    headerRowHeight: isFilterable ? 40 : 0,
    rowHeight: 30,
    presets: {
      filters: filters, // Directly compatible format
    },
  }
}

function createColumnDefinition(columnKey, data, tableHeader, isClinic, canMultiSelect, runId) {
  const filter = getFilter(columnKey, data, isClinic, runId)
  const additionalProps = getAdditionalProps(columnKey, isClinic, canMultiSelect)
  if (columnKey === 'external') {
    return {
      id: 'External',
      name: 'External',
      field: 'External',
      cssClass: 'cell-title text-center cursor-pointer',
      minWidth: 60,
      width: 60,
      filterable: false,
      sortable: false,
      formatter: (_row, _cell, _value, _columnDef, dataContext) => {
        return `
          <div class="external-dropdown-container">
            <i class="fas fa-ellipsis-v"></i> 
            <div class="external-dropdown hidden">
              <ul>
            
              </ul>
            </div>
          </div>
        `
      },
      onCellClick: (e, args) => {
        e.stopPropagation()

        const grid = args.grid
        const cellNode = grid.getCellNode(args.row, args.cell)
        if (!cellNode) return
        let dropdown = document.querySelector('.external-dropdown') as HTMLElement
        if (dropdown && dropdown.dataset.activeCell === `${args.row}-${args.cell}`) {
          dropdown.remove()
          return
        }

        document.querySelectorAll('.external-dropdown').forEach((el) => el.remove())

        dropdown = document.createElement('div')
        dropdown.className = 'external-dropdown'
        document.body.appendChild(dropdown)

        const rect = cellNode.getBoundingClientRect()
        let dropdownTop = rect.bottom + window.scrollY
        let dropdownLeft = rect.left + window.scrollX

        if (dropdownTop + 200 > window.innerHeight + window.scrollY) {
          dropdownTop = rect.top + window.scrollY - 210
          dropdown.classList.add('flip-up')
        } else {
          dropdown.classList.remove('flip-up')
        }

        dropdown.style.position = 'absolute'
        dropdown.style.top = `${dropdownTop}px`
        dropdown.style.left = `${dropdownLeft}px`
        dropdown.style.zIndex = '9999999'
        dropdown.style.background = '#fff'
        dropdown.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'
        dropdown.style.padding = '8px'
        dropdown.style.borderRadius = '6px'
        dropdown.style.fontSize = '14px'
        dropdown.style.width = '120px'

        dropdown.dataset.activeCell = `${args.row}-${args.cell}`
        const franklinUrl = `https://franklin.genoox.com/clinical-db/variant/snpTumor/${args.dataContext.Chrom}-${args.dataContext.Start}-${args.dataContext.Ref}-${args.dataContext.Alt}-hg38`
        const vamppUrl = `https://vamppscore.com/search-variant/${args.dataContext.vampp}`
        // remove p. from aaChange and check if is not empty
        const aaChange = args?.dataContext?.aaChange ? args.dataContext.aaChange.replace('p.', '') : null

        const oncoKbUrl = `https://www.oncokb.org/gene/${args.dataContext.Gene_refGene[0]}/${aaChange}?refGenome=GRCh38`
        // https://www.oncokb.org/gene/ABL1/E255K?refGenome=GRCh38
        dropdown.innerHTML = `
          <ul style="list-style: none; padding: 0; margin: 0;">
            <li style="padding: 5px;display:flex; align-items:center; gap:5px"> <a href="${vamppUrl}" target="_blank"> <i class="fa fa-link text-danger "></i> VAMPP</a></li>
            <li style="padding: 5px;display:flex; align-items:center; gap:5px">  <a href="${franklinUrl}" target="_blank"><i  class="fa fa-link text-danger "></i> Franklin</a></li>
            ${aaChange  ? `<li style="padding: 5px;display:flex; align-items:center; gap:5px">  <a href="${oncoKbUrl}" target="_blank"><i  class="fa fa-link text-danger "></i> OncoKB</a></li>` :''  }
          </ul>
        `

        dropdown.classList.add('show')

        setTimeout(() => {
          // Detect click on the document
          document.addEventListener(
            'click',
            (ev) => {
              if (!dropdown.contains(ev.target as Node) && !cellNode.contains(ev.target as Node)) {
                dropdown.remove()
              }
            },
            {once: true}
          )
        }, 10)

        // Close the dropdown when a link is clicked
        const links = dropdown.querySelectorAll('a')
        links.forEach((link) => {
          link.addEventListener('click', () => {
            dropdown.remove()
          })
        })
      },
    }
  }

  if (columnKey === 'igv') {
    return {
      id: columnKey,
      name: 'IGV',
      field: columnKey,
      cssClass: 'cell-title text-center cursor-pointer',
      minWidth: 40,
      width: 40,
      filterable: false,
      sortable: false,
      formatter: (row, cell, value, columnDef, dataContext) => {
        return `
        <i class="fas fa-external-link-alt"></i>
        `
      },
      onCellClick: (e, args) => {
        e.preventDefault()
        if (args.columnDef.field === 'igv') {
          window.open('/igv/' + runId + '&' + args.dataContext.igv + '&snv', '_blank')
        }
      },
    }
  }

  return {
    id: columnKey,
    name: tableHeader[columnKey]?.value,
    field: columnKey,
    cssClass: 'cell-title',
    minWidth: 120,
    filterable: true,
    sortable: true,
    filter,
    ...additionalProps,
    formatter: additionalProps?.formatter ?? undefined,
  }
}

function getFilter(columnKey, data, isClinic, runId) {
  const uniqueValues = Array.from(new Set(data.map((item) => item[columnKey])))
  const collection = uniqueValues.map((value) => ({value, label: value}))

  if (!columnKey) {
    console.error(' HATA: `columnKey` undefined!', columnKey)
    return undefined
  }
  if (!data || !Array.isArray(data)) {
    console.error(` HATA: ${columnKey} için veri eksik!`)
    return undefined
  }

  switch (columnKey) {
    case 'Chrom':
    case 'CancerVar_Tier':
    case 'Func_refGene':
    case 'ExonicFunc_refGene':
    case 'HighestLevel':
    case 'Oncogenic':
    case 'HighestSensitiveLevel':
    case 'HighestResistanceLevel':
    case 'MutationEffect':
    case 'HighestDxLevel':
    case 'HighestPxLevel':
    case 'Variant_Type':
      return {model: Filters.multipleSelect, collection}

    case 'Gene_refGene': // 🔥 Yeni özel filtreyi burada kullanıyoruz
      const uniqueGenes = Array.from(
        new Set(
          data
            .flatMap((item) => {
              if (Array.isArray(item.Gene_refGene)) {
                return item.Gene_refGene.flatMap((gene) => gene.split(';').map((g) => g.trim()))
                // ✅ Eğer array ise, içindeki her elemanı ";" ile böl
              } else {
                return [] // ❌ String veya array değilse (null, number vs.) boş geç
              }
            })
            .filter((gene) => gene.length > 0) // ✅ Boş stringleri kaldır
        )
      )
      console.log(uniqueGenes)
      return {
        model: CustomDropdownFilter,
        collection: uniqueGenes,
      }
    // case 'Gene_refGene': // 🔥 Yeni özel filtreyi burada kullanıyoruz
    //   return {
    //     model: CustomTextFilter, // Yeni özel filtre
    //   }

    case 'Filter':
      return {
        model: Filters.multipleSelect,
        collection: isClinic
          ? [{value: 'PASS', label: 'PASS'}]
          : filterObjects.map((item) => ({value: item.type, label: item.type})),
        operator: OperatorType.inContains,
      }

    case 'GeneInOncoKB':
      return {
        model: Filters.multipleSelect,
        collection: ['true', 'false'],
      }

    case 'DET':
    case 'exon':
      return {model: Filters.singleSelect, collection}

    case 'Start':
    case 'End':
    case 'tx':
      return {operator: OperatorType.startsWith}

    case 'CancerVar_Evidence':
      return {
        model: Filters.multipleSelect,
        collection: Object.keys(canverEvTooltips).map((item) => ({
          value: item,
          label: item,
        })),
        operator: OperatorType.contains,
      }

    case 'DETS':
    case 'FFPM':
    case 'AD':
    case 'AF':
    case 'DP':
      return {model: Filters.compoundInputText}

    default:
      return undefined
  }
}

function getAdditionalProps(columnKey, isClinic, canMultiSelect) {
  switch (columnKey) {
    case 'reportSelect':
      if (canMultiSelect) {
        return {
          frozen: true,
          formatter: null,
          minWidth: 30,
          name: '',
          filterable: false,
          sortable: false,
        }
      }
      break
    case 'Gene_refGene':
      return {
        formatter: GeneReferenceFormatter,
        onCellClick: handleLinkClick,
      }

    case 'ICGC_Id':
      return {
        formatter: icgcIdFormatter,
        onCellClick: handleLinkClick,
      }

    case 'avsnp150':
      return {
        formatter: avsnpFormatter,
        onCellClick: handleLinkClick,
      }

    case 'CancerVar_Tier':
      return {formatter: cancerVarFormatter}

    case 'cosmic91':
      return {
        formatter: cosmicFormatter,
        onCellClick: handleLinkClick,
      }

    case 'OMIM':
      return {
        formatter: omimFormatter,
        onCellClick: handleLinkClick,
      }

    case 'DET':
      return {formatter: detFormatter}

    case 'DETS':
      return {
        formatter: detsFormatter,
        type: FieldType.number,
      }

    case 'Oncogenic':
      return {
        formatter: oncogenicFormatter,
        onCellClick: handleLinkClick,
      }

    case 'PhenoTypeMIM':
      return {
        formatter: phenoTypeMIM,
        onCellClick: handleLinkClick,
      }

    case 'OrphaNumber':
      return {
        formatter: orphaNumberFormatter,
        onCellClick: handleLinkClick,
      }

    case 'Filter':
      return {formatter: filterFormatter}

    case 'Level1':
    case 'Level2':
    case 'Level3A':
    case 'Level3B':
    case 'Level4':
    case 'LevelR1':
    case 'LevelR2':
      return {formatter: levelFormatter}
    case 'VariantId':
      return {
        formatter: VariantIdFormatter,
        onCellClick: (e) => {
          e.preventDefault()
          if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
            window.open(e.target.getAttribute('href'), '_blank')
          }
        },
      }

    default:
      return {}
  }
}
const calculateVisibleRows = (isFilterable) => {
  // get table height

  const screenHeight = window.innerHeight

  const headerRowHeight = isFilterable ? 40 : 0

  const otherHeights = headerRowHeight + 40

  const usableHeight = screenHeight - otherHeights

  const rowHeight = 45

  const visibleRowCount = Math.floor(usableHeight / rowHeight)
  return visibleRowCount > 0 ? visibleRowCount : 1
}

function handleLinkClick(e) {
  e.preventDefault()
  const href = e.target.getAttribute('href')
  if (href && href !== '#') {
    window.open(href, '_blank')
  }
}
