import axios from 'axios'
import {KTSVG} from '../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {marked} from 'marked'
import {Loader} from '../Loader'
import CustomAnnotationItemDetailModal from './CustomAnnotationItemDetailModal'

const CustomAnnotationModal = ({
  currentNode,
  annotators,
  setAnnotators,
  selectedFamilyFilterButton,
  hasFilterData,
}) => {
  const [tags, setTags] = useState([])
  const [selectedSubTag, setSelectedSubTag] = useState(null)
  const [filterText, setFilterText] = useState('')
  const [subDataIsLoading, setSubDataIsLoading] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [defaultAnnotators, setDefaultAnnotators] = useState(
    process.env.REACT_APP_DEFAULT_ANNOTATORS !== null &&
      process.env.REACT_APP_DEFAULT_ANNOTATORS !== undefined
      ? process.env.REACT_APP_DEFAULT_ANNOTATORS.split(',')
      : []
  )
  const getData = async () => {
    try {
      const [remoteRequest, annotatorsRequest] = await Promise.all([
        axios.get(`https://${currentNode}.node.gennext.bio/store/remote`),
        axios.get(`https://${currentNode}.node.gennext.bio/submit/annotators`),
      ])

      const annotators = annotatorsRequest.data
      const result = {tags: []}
      const tagMap = {}

      Object.keys(remoteRequest.data.data).forEach((key) => {
        const item = remoteRequest.data.data[key]
        if (item.type === 'annotator') {
          if (!item.tags) return
          item.tags.forEach((tag) => {
            if (!tagMap[tag]) {
              tagMap[tag] = {
                label: tag,
                subTags: [],
              }
              result.tags.push(tagMap[tag])
            }
            if (annotators[key]) {
              tagMap[tag].subTags.push({
                label: key,
                description: item.description,
                title: item.title,
                latest_version: item.latest_version,
                developer: item.developer,
              })
            }
          })
        }
      })
      setTags(result.tags)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilterChange = (event) => {
    setFilterText(event.target.value)
  }

  const filterSubTags = () => {
    if (filterText.length < 3) return []

    const uniqueSubTags = new Set()
    const filtered = []
    tags.forEach((tag) => {
      tag.subTags.forEach((subTag) => {
        if (
          subTag.label.toLowerCase().includes(filterText.toLowerCase()) &&
          !uniqueSubTags.has(subTag.label)
        ) {
          uniqueSubTags.add(subTag.label)
          filtered.push(subTag)
        }
      })
    })
    return filtered
  }

  const getLogo = async (subTag) => {
    try {
      const tagLogo = await axios.get(
        `https://${currentNode}.node.gennext.bio/store/locallogo?module=${subTag}`,
        {responseType: 'blob'}
      )
      if (tagLogo.status === 200) {
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const addPrefixToImageUrls = (markdownText, prefix) => {
    return markdownText.replace(/!\[([^\]]*)\]\(([^)]+)\)/g, (match, alt, url) => {
      if (!url.startsWith('http')) {
        url = prefix + url
      }
      return `![${alt}](${url})`
    })
  }

  const MarkdownRenderer = ({markdownText, prefix}) => {
    const updatedMarkdown = addPrefixToImageUrls(markdownText, prefix)
    const createMarkup = () => {
      return {__html: marked(updatedMarkdown)}
    }

    useEffect(() => {
      const images = document.querySelectorAll('.markdown-content img')
      images.forEach((img) => {
        img.style.maxWidth = '100%'
      })
    }, [markdownText])

    return <div className='markdown-content' dangerouslySetInnerHTML={createMarkup()} />
  }

  const handleSubTagClick = async (subTag) => {
    setSubDataIsLoading(true)
    const tagDesc = await axios.get(
      `https://${currentNode}.node.gennext.bio/store/modules/${subTag.label}/latest/readme`
    )
    var hasLogo = await getLogo(subTag.label)
    setSelectedSubTag({
      title: subTag.label,
      desc: tagDesc.data,
      short_desc: subTag.description,
      latest_version: subTag.latest_version,
      developer: subTag.developer,
      hasLogo: hasLogo,
    })
    setSubDataIsLoading(false)
    setIsModalOpen(true)
  }

  const handleCheckboxChange = (subTagLabel) => {
    setAnnotators((prevAnnotators) => {
      if (prevAnnotators.includes(subTagLabel)) {
        return prevAnnotators.filter((annotator) => annotator !== subTagLabel)
      }
      return [...prevAnnotators, subTagLabel]
    })
  }

  return (
    <div className='modal fade bd-example-modal-lg' tabIndex={-1} id='CustomAnnotationModal'>
      <div
        className='modal-dialog'
        style={{
          width: 700,
          maxHeight: '600px',
          maxWidth: 'none',
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Custom Annotation</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body row justify-content-center'>
            <div
              className='col-md-11'
              style={{maxHeight: '600px', overflowY: 'auto', overflowX: 'hidden'}}
            >
              <div className='accordion-header d-flex gap-5 justify-content-between'>
                <input
                  type='text'
                  className='form-control w-50 my-3'
                  value={filterText}
                  onChange={handleFilterChange}
                  placeholder='Search...'
                />
                <div className='show-all-checkbox d-flex align-items-center gap-3 my-3 justify-content-end'>
                  Show All
                  <input type='checkbox' checked={showAll} onChange={() => setShowAll(!showAll)} />
                </div>
              </div>
              <div className='filtered-subtags d-flex flex-wrap mx-5'>
                {filterSubTags().map((subTag, index) => (
                  <div
                    key={index}
                    className='  d-flex align-items-center my-2'
                    style={{
                      width: '49%',
                    }}
                  >
                    <input
                      type='checkbox'
                      checked={
                        annotators.includes(subTag.label) ||
                        defaultAnnotators.includes(subTag.label)
                      }
                      disabled={defaultAnnotators.includes(subTag.label)}
                      onChange={() => handleCheckboxChange(subTag.label)}
                      className=''
                    />
                    <span
                      onClick={() => handleSubTagClick(subTag)}
                      className={
                        'cursor-pointer mx-2 fs7 ' +
                        (selectedSubTag?.title === subTag.label ? 'selected' : '')
                      }
                    >
                      {subTag.label}
                    </span>
                  </div>
                ))}
              </div>
              <div className='accordion' id='accordionTags'>
                {tags.map((tag, index) => (
                  <div className='accordion-item' key={index}>
                    <h2 className='accordion-header' id={`heading_${index}`}>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed text-capitalize'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapse_${index}`}
                        aria-expanded='false'
                        aria-controls={`collapse_${index}`}
                      >
                        {tag.title || tag.label}
                      </button>
                    </h2>
                    <div
                      id={`collapse_${index}`}
                      className={`accordion-collapse collapse ${showAll ? 'show' : ''}`}
                      aria-labelledby={`heading_${index}`}
                      data-bs-parent='#accordionTags'
                    >
                      <div
                        className='accordion-body'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '10px',
                        }}
                      >
                        {tag.subTags.map((subTag, subIndex) => (
                          <div
                            key={subIndex}
                            className='d-flex align-items-center my-2'
                            style={{
                              width: '49%',
                            }}
                          >
                            <input
                              type='checkbox'
                              disabled={defaultAnnotators.includes(subTag.label) }
                              checked={annotators.includes(subTag.label) || defaultAnnotators.includes(subTag.label)}
                              onChange={() => handleCheckboxChange(subTag.label)}
                              className=''
                            />
                            <span
                              onClick={() => handleSubTagClick(subTag)}
                              className={`cursor-pointer mx-2 fs-7 ${
                                selectedSubTag?.title === subTag.label ? 'selected' : ''
                              }`}
                            >
                              {subTag.title || subTag.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className='col-md-8'>
              {subDataIsLoading && <Loader />}
              {selectedSubTag && !subDataIsLoading && (
                <>
                  <div className='col-md-12'>
                    {selectedSubTag.hasLogo && (
                      <img
                        src={`https://acurare-01.node.gennext.bio/store/locallogo?module=${selectedSubTag.title}`}
                        style={{ width: '125px ', marginBottom: '20px' }}
                        alt={selectedSubTag.title}
                      />
                    )}
                  </div>
                  <div className='col-md-9'>
                    <div className='tag-desc-content'>
                      <MarkdownRenderer
                        markdownText={selectedSubTag.desc}
                        prefix={`https://${currentNode}.node.gennext.bio/modules/annotators/${selectedSubTag.title}/`}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <p>{selectedSubTag.short_desc}</p>
                    <p>
                      <b>Module version</b>: {selectedSubTag.latest_version}
                    </p>
                    <p>
                      <b>E-mail</b>: {selectedSubTag.developer.email}
                    </p>
                    <p>
                      <b>Citation</b>: {selectedSubTag.developer.citation}
                    </p>
                    <p>
                      <b>Organization</b>: {selectedSubTag.developer.organization}
                    </p>
                    <p className='fs-9'>
                      <a
                        href={selectedSubTag.developer.website}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {selectedSubTag.developer.website}
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div> */}
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
            {isModalOpen && (
              <CustomAnnotationItemDetailModal
                selectedSubTag={selectedSubTag}
                currentNode={currentNode}
                subDataIsLoading={subDataIsLoading}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomAnnotationModal
