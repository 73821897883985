import React, {useState, useEffect, useRef} from 'react'
import './TableComponent.scss'
import {
  Column,
  Filters,
  GridOption,
  SlickgridReact,
  OperatorType,
  FieldType,
  SlickgridReactInstance,
  Editors,
} from 'slickgrid-react'
import {Loader} from '../../components/Loader'
import {Tooltip} from 'react-tooltip'
import {
  GeneReferenceFormatter,
  avsnpFormatter,
  cancerVarFormatter,
  cosmicFormatter,
  detFormatter,
  detsFormatter,
  filterFormatter,
  icgcIdFormatter,
  omimFormatter,
  oncogenicFormatter,
  orphaNumberFormatter,
  phenoTypeMIM,
  filterObjects,
  canverEvTooltips,
  levelFormatter,
  VariantIdFormatter,
} from './Formatters'

const TableComponentClinicalPage = ({
  data,
  setSelectedRows,
  setSelectedWidgetItem,
  selectedRows,
  tableHeader,
  gridOptions,
  setAllelicWidgetDatas,
  runId,
  selectedWidgetItem,
  isFilterable = true,
  canMultiSelect = true,
  autoHeight = true,
  hiddenColumns,
  setGridOptions,
  isClinic,
  gridId,
}) => {
  const [columnDefinitions, setColumnDefinitions] = useState<Column[]>([])
  useEffect(() => {
    if (data.length >= 1) {
      defineGrid()
    }
  }, [])
  // useEffect(() => {
  //   const demoContainer = document.getElementById('kt_app_sidebar_menu_wrapper');
  //   if (demoContainer) {
  //     const tableHeight = demoContainer.offsetHeight - 120; a
  //     console.log('Calculated Table Height:', tableHeight);

  //     const slickPane = document.querySelector('.slick-pane') as HTMLElement;
  //     const slickViewport = document.querySelector('.slick-viewport') as HTMLElement;

  //     if (slickPane) {
  //       slickPane.style.minHeight = `${tableHeight}px`;
  //     }

  //     if (slickViewport) {
  //       slickViewport.style.minHeight = `${tableHeight - 100}px`;
  //     }
  //   }
  // }, [gridOptions]);
  useEffect(() => {
    const handleDropdownClick = (event) => {
      const linkElement = event.target.closest('.external-link')
      if (linkElement) {
        event.stopPropagation()
        const url = linkElement.getAttribute('data-url')
        if (url) {
          window.open(url, '_blank')
        }
      }
    }

    document.addEventListener('click', handleDropdownClick)

    return () => {
      document.removeEventListener('click', handleDropdownClick)
    }
  }, [])
  const defineGrid = () => {
    const columnDefs = Object.keys(tableHeader).map((columnKey) =>
      createColumnDefinition(
        columnKey,
        data,
        tableHeader,
        isClinic,
        canMultiSelect,
        runId,
        selectedRows,
        setSelectedRows
      )
    )

    const gridOpts: GridOption = createGridOptions(
      gridId,
      canMultiSelect,
      autoHeight,
      isFilterable,
      isClinic
    )

    setColumnDefinitions(columnDefs)
    setGridOptions(gridOpts)
  }
  // const onGrid1SelectedRowsChanged = (_e: Event, args: any) => {
  //   const grid = args && args.grid
  //   if (Array.isArray(args.rows)) {
  //     setSelectedRows(
  //       args.rows.map((idx: number) => {
  //         const item = grid.getDataItem(idx)
  //         return item
  //       })
  //     )
  //   }
  // }
  const onGrid1SelectedRowsChanged = (_e, args) => {
    const grid = args?.grid
    if (Array.isArray(args.rows)) {
      setSelectedRows(args.rows.map((idx) => grid.getDataItem(idx)))
    }
  }
  const onCellClicked = (e, args) => {
    const grid = args && args.grid
    const rows = document.querySelectorAll('.slick-row')
    const clickedData = grid.getDataItem(args.row)
    const alleleDatas = data.filter((item) => clickedData.alleleIds.includes(item.id))
    rows.forEach((row) => row.classList.remove('background-red'))
    let clickedRow = null
    if (e.target.parentElement?.classList.contains('slick-row')) {
      e.target.parentElement.classList.add('background-red')
      clickedRow = e.target.parentElement
    } else if (e.target.classList.contains('slick-row')) {
      e.target.classList.add('background-red')
      clickedRow = e.target
    } else if (e.target.parentElement?.parentElement?.classList.contains('slick-row')) {
      e.target.parentElement.parentElement.classList.add('background-red')
      clickedRow = e.target.parentElement.parentElement
    }
    if (clickedRow) {
      const button = document.querySelector('#ClinicDrawer_toggle') as HTMLButtonElement | null
      const closeButton = document.querySelector('#ClinicDrawer_close') as HTMLButtonElement | null
      const drawer = document.querySelector('#ClinicDrawer')

      if (button && drawer) {
        if (
          e.target.classList.contains('reportSelect') ||
          e.target?.classList.contains('external-wrapper') ||
          e.target?.classList.contains('external-dropdown-container')
        ) {
          if (!drawer.classList.contains('drawer-on')) {
            setSelectedWidgetItem({})
            setAllelicWidgetDatas([])
            return
          }
          return
        }

        if (clickedData?.id === selectedWidgetItem?.id && drawer.classList.contains('drawer-on')) {
          setSelectedWidgetItem({})
          setAllelicWidgetDatas([])
          closeButton?.click()
          return
        } else if (
          clickedData?.id !== selectedWidgetItem?.id &&
          drawer.classList.contains('drawer-on')
        ) {
          setSelectedWidgetItem(clickedData)
          setAllelicWidgetDatas(alleleDatas)
          return
        } else if (
          clickedData?.id === selectedWidgetItem?.id &&
          !drawer.classList.contains('drawer-on')
        ) {
          button.click()
          return
        }

        if (!drawer.classList.contains('drawer-on')) {
          button.click()
        } else {
          closeButton?.click()
        }
      }
    }

    setSelectedWidgetItem(clickedData)
    setAllelicWidgetDatas(alleleDatas)
  }

  const onGridLoaded = (gridInstance: SlickgridReactInstance) => {
    if (isClinic && hiddenColumns?.length) {
      gridInstance.gridService.hideColumnByIds(hiddenColumns)
    }
  }

  return data.length === 0 || !gridOptions ? (
    <div className='card'>
      <div className='card-body text-center'>
        <Loader />
      </div>
    </div>
  ) : (
    <div id='demo-container' className='container-fluid mt-5'>
      <div id='grid-container' className='col-sm-12 custom-horizontal-scrollbar'>
        <SlickgridReact
          gridId={gridId}
          columnDefinitions={columnDefinitions}
          gridOptions={gridOptions}
          dataset={data}
          onSelectedRowsChanged={($event) => {
            onGrid1SelectedRowsChanged($event.detail.eventData, $event.detail.args)
          }}
          onClick={($event) => {
            onCellClicked($event.detail.eventData, $event.detail.args)
          }}
          onReactGridCreated={(event) => onGridLoaded(event.detail)}
        />
        <Tooltip id='my-tooltip' />
      </div>
    </div>
  )
}

export default TableComponentClinicalPage

function createGridOptions(
  gridId: string,
  canMultiSelect: boolean,
  autoHeight: boolean,
  isFilterable: boolean,
  isClinic: boolean
) {
  const visibleRowCount = calculateVisibleRows(isFilterable)
  const filters =
    gridId === 'snvTable'
      ? [
          {
            columnId: 'Filter',
            searchTerms: ['PASS'],
          },
        ]
      : [
          {
            columnId: 'Filter',
            searchTerms: [
              'PASS',
              'clustered_events',
              'duplicate',
              'fragment',
              'multiallelic',
              'n_ratio',
              'orientation',
              'position',
              'slippage',
              'haplotype',
              'germline',
              'strict_strand',
            ],
          },
        ]
  return {
    autoResize: {container: '#demo-container', rightPadding: 10},
    autoHeight: false,
    minHeight: 500,
    enableAutoSizeColumns: true,
    enableAutoResize: true,
    enableFiltering: true,
    frozenColumn: canMultiSelect ? 0 : -1,
    enableRowSelection: canMultiSelect,
    enableCheckboxSelector: canMultiSelect,
    // createPreHeaderPanel: true,
    // showPreHeaderPanel: true,
    // preHeaderPanelHeight: isFilterable ? 28 : 0,
    enablePagination: true,
    pagination: {
      pageSizes: visibleRowCount
        ? [visibleRowCount, visibleRowCount + 5, visibleRowCount + 10]
        : [10],
      pageSize: visibleRowCount || 10, // Fallback to 10 if visibleRowCount is undefined or null
    },
    multiColumnSort: true,
    enableTreeData: false,
    checkboxSelector: {
      hideSelectAllCheckbox: true,
    },
    showCustomFooter: true,
    headerRowHeight: isFilterable ? 40 : 0,
    rowHeight: 30,
    presets: {
      filters: filters, // Directly compatible format
    },
  }
}
const plofKeywords = [
  'stopgain',
  'frameshift deletion',
  'frameshift insertion',
  'stoploss',
  'startlost',
  'start gained',
  'splicing site',
]
const missenseKeywords = ['nonsynonymous SNV']
const synonymousKeywords = ['synonymous SNV']
const otherKeywords = [
  'nonframeshift deletion',
  'nonframeshift substitution',
  'nonframeshift insertion',
  'unknown',
]
function createColumnDefinition(
  columnKey,
  data,
  tableHeader,
  isClinic,
  canMultiSelect,
  runId,
  selectedRows,
  setSelectedRows
) {
  const filter = getFilter(columnKey, data, isClinic)
  const additionalProps = getAdditionalProps(
    columnKey,
    isClinic,
    canMultiSelect,
    selectedRows,
    setSelectedRows
  )

  if (columnKey === 'external') {
    return {
      id: 'External',
      name: 'External',
      field: 'External',
      cssClass: 'cell-title text-center cursor-pointer',
      minWidth: 60,
      width: 60,
      filterable: true,
      sortable: true,
      formatter: (_row, _cell, _value, _columnDef, dataContext) => {
        return `
          <div class="external-dropdown-container">
            <i class="fas fa-ellipsis-v"></i> 
            <div class="external-dropdown hidden">
              <ul>
                
              </ul>
            </div>
          </div>
        `
      },
      onCellClick: (e, args) => {
        e.stopPropagation()

        const grid = args.grid
        const cellNode = grid.getCellNode(args.row, args.cell)
        if (!cellNode) return
        let dropdown = document.querySelector('.external-dropdown') as HTMLElement
        if (dropdown && dropdown.dataset.activeCell === `${args.row}-${args.cell}`) {
          dropdown.remove()
          return
        }

        document.querySelectorAll('.external-dropdown').forEach((el) => el.remove())

        dropdown = document.createElement('div')
        dropdown.className = 'external-dropdown'
        document.body.appendChild(dropdown)

        const rect = cellNode.getBoundingClientRect()
        let dropdownTop = rect.bottom + window.scrollY
        let dropdownLeft = rect.left + window.scrollX

        if (dropdownTop + 200 > window.innerHeight + window.scrollY) {
          dropdownTop = rect.top + window.scrollY - 210
          dropdown.classList.add('flip-up')
        } else {
          dropdown.classList.remove('flip-up')
        }

        dropdown.style.position = 'absolute'
        dropdown.style.top = `${dropdownTop}px`
        dropdown.style.left = `${dropdownLeft}px`
        dropdown.style.zIndex = '9999999'
        dropdown.style.background = '#fff'
        dropdown.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'
        dropdown.style.padding = '8px'
        dropdown.style.borderRadius = '6px'
        dropdown.style.fontSize = '14px'
        dropdown.style.width = '120px'

        dropdown.dataset.activeCell = `${args.row}-${args.cell}`
        const franklinUrl = `https://franklin.genoox.com/clinical-db/variant/snpTumor/${args.dataContext.Chrom}-${args.dataContext.Start}-${args.dataContext.Ref}-${args.dataContext.Alt}-hg38`
        const vamppUrl = `https://vamppscore.com/search-variant/${args.dataContext.vampp}`
        // remove p. from aaChange and check if is not empty
        const aaChange = args?.dataContext?.aaChange ? args.dataContext.aaChange.replace('p.', '') : null

        const oncoKbUrl = `https://www.oncokb.org/gene/${args.dataContext.Gene_refGene[0]}/${aaChange}?refGenome=GRCh38`
        // https://www.oncokb.org/gene/ABL1/E255K?refGenome=GRCh38
        dropdown.innerHTML = `
          <ul style="list-style: none; padding: 0; margin: 0;">
            <li style="padding: 5px;display:flex; align-items:center; gap:5px"> <a href="${vamppUrl}" target="_blank"> <i class="fa fa-link text-danger "></i> VAMPP</a></li>
            <li style="padding: 5px;display:flex; align-items:center; gap:5px">  <a href="${franklinUrl}" target="_blank"><i  class="fa fa-link text-danger "></i> Franklin</a></li>
            ${aaChange  ? `<li style="padding: 5px;display:flex; align-items:center; gap:5px">  <a href="${oncoKbUrl}" target="_blank"><i  class="fa fa-link text-danger "></i> OncoKB</a></li>` :''  }
          </ul>
        `

        dropdown.classList.add('show')

        setTimeout(() => {
          // Detect click on the document
          document.addEventListener(
            'click',
            (ev) => {
              if (!dropdown.contains(ev.target as Node) && !cellNode.contains(ev.target as Node)) {
                dropdown.remove()
              }
            },
            {once: true}
          )
        }, 10)

        // Close the dropdown when a link is clicked
        const links = dropdown.querySelectorAll('a')
        links.forEach((link) => {
          link.addEventListener('click', () => {
            dropdown.remove()
          })
        })
      },
    }
  }

  if (columnKey === 'igv') {
    return {
      id: columnKey,
      name: 'IGV',
      field: columnKey,
      cssClass: 'cell-title text-center cursor-pointer',
      minWidth: 40,
      width: 40,
      filterable: false,
      sortable: false,
      formatter: (row, cell, value, columnDef, dataContext) => {
        return `
        <i class="fas fa-external-link-alt"></i>
        `
      },
      onCellClick: (e, args) => {
        e.preventDefault()
        if (args.columnDef.field === 'igv') {
          window.open('/igv/' + runId + '&' + args.dataContext.igv + '&snv', '_blank')
        }
      },
    }
  }
  // if (columnKey === 'vampp') {
  //   return {
  //     id: columnKey,
  //     name: 'VAMPP',
  //     field: columnKey,
  //     cssClass: 'cell-title text-center cursor-pointer',
  //     minWidth: 50,
  //     width: 50,
  //     filterable: false,
  //     sortable: false,
  //     formatter: (row, cell, value, columnDef, dataContext) => {
  //       return `
  //       <i class="fas fa-external-link-alt"></i>
  //       `
  //     },
  //     onCellClick: (e, args) => {
  //       e.preventDefault()
  //       if (args.columnDef.field === 'vampp') {
  //         window.open('https://vamppscore.com/search-variant/' + args.dataContext.vampp, '_blank')
  //       }
  //     },
  //   }

  // }
  return {
    id: columnKey,
    name: tableHeader[columnKey]?.value,
    field: columnKey,
    cssClass: 'cell-title',
    minWidth: 120,
    filterable: true,
    sortable: true,
    filter,
    ...additionalProps,
    // formatter: additionalProps?.formatter ?? undefined,
    formatter: (row, cell, value, columnDef, dataContext) => {
      var extraClass = ''
      if (columnDef.field === 'reportSelect') {
        return `<input type="checkbox" class="reportSelect" data-id="${dataContext.id}" />`
      }

      if (plofKeywords.includes(dataContext.ExonicFunc_refGene)) {
        extraClass += ' plofBg'
      } else if (missenseKeywords.includes(dataContext.ExonicFunc_refGene)) {
        extraClass += ' missenseBg'
      } else if (synonymousKeywords.includes(dataContext.ExonicFunc_refGene)) {
        extraClass += ' synonymousBg'
      } else {
        extraClass += ' otherBg'
      }
      if (columnDef.field === 'CancerVar_Tier' && isClinic) {
        return `<div class="${extraClass}"
        data-tooltip-id='my-tooltip'
        data-tooltip-content='${dataContext.CancerVar_Evidence}'
                   
     >${value === null || value === undefined ? '.' : value}</div>`
      }
      return `<div class="${extraClass}"
      >${value === null || value === undefined ? '.' : value}</div>`
    },
  }
}

function getFilter(columnKey, data, isClinic) {
  const uniqueValues = Array.from(new Set(data.map((item) => item[columnKey])))
  const collection = uniqueValues.map((value) => ({value, label: value}))

  switch (columnKey) {
    case 'Chrom':
    case 'CancerVar_Tier':
    case 'Func_refGene':
    case 'ExonicFunc_refGene':
    case 'HighestLevel':
    case 'Oncogenic':
    case 'HighestSensitiveLevel':
    case 'HighestResistanceLevel':
    case 'MutationEffect':
    case 'HighestDxLevel':
    case 'HighestPxLevel':
    case 'Variant_Type':
      return {model: Filters.multipleSelect, collection}

    case 'Gene_refGene':
      return {
        model: Filters.multipleSelect,
        collection,
        operator: OperatorType.inContains,
      }

    case 'Filter':
      return {
        model: Filters.multipleSelect,
        collection: isClinic
          ? [{value: 'PASS', label: 'PASS'}]
          : filterObjects.map((item) => ({value: item.type, label: item.type})),
        operator: OperatorType.inContains,
      }

    case 'GeneInOncoKB':
      return {
        model: Filters.multipleSelect,
        collection: ['true', 'false'],
      }

    case 'DET':
    case 'exon':
      return {model: Filters.singleSelect, collection}

    case 'Start':
    case 'End':
    case 'tx':
      return {operator: OperatorType.startsWith}

    case 'CancerVar_Evidence':
      return {
        model: Filters.multipleSelect,
        collection: Object.keys(canverEvTooltips).map((item) => ({
          value: item,
          label: item,
        })),
        operator: OperatorType.contains,
      }

    case 'DETS':
    case 'FFPM':
    case 'AD':
    case 'AF':
    case 'DP':
      return {model: Filters.compoundInputText}

    default:
      return undefined
  }
}

function getAdditionalProps(columnKey, isClinic, canMultiSelect, selectedRows, setSelectedRows) {
  switch (columnKey) {
    case 'reportSelect':
      return {
        minWidth: 30,
        width: 30,
        name: '',
        filterable: false,
        sortable: false,
        formatter: (row, cell, value, columnDef, dataContext) => {
          const isChecked = selectedRows.some((row) => row.id === dataContext.id)
          return `<input type="checkbox" class="reportSelect" data-id="${dataContext.id}" ${
            isChecked ? 'checked' : ''
          } />`
        },
        onCellClick: (e, args) => {
          e.preventDefault()
          const checkbox = e.target.closest('input.reportSelect')

          if (checkbox) {
            const grid = args.grid
            const dataItem = grid.getDataItem(args.row)

            setSelectedRows((prevSelectedRows) => {
              let newSelectedRows

              if (prevSelectedRows.some((item) => item.id === dataItem.id)) {
                // Eğer zaten seçiliyse kaldır
                newSelectedRows = prevSelectedRows.filter((item) => item.id !== dataItem.id)
              } else {
                // Seçili değilse ekle
                newSelectedRows = [...prevSelectedRows, dataItem]
              }

              // **Checkbox durumunu güncellemek için state değişimi sonrası DOM'u da güncelle**
              setTimeout(() => {
                checkbox.checked = newSelectedRows.some((item) => item.id === dataItem.id)
              }, 0)

              return newSelectedRows
            })
          }
        },
        postRender: (cellNode, row, dataContext, colDef) => {
          // **Render sonrası, state'e göre checkbox'ın durumunu güncelle**
          const checkbox = cellNode.querySelector('input.reportSelect')
          if (checkbox) {
            checkbox.checked = selectedRows.some((row) => row.id === dataContext.id)
          }
        },
      }
    case 'adrd':
      return {
        width: 60,
        minWidth: 60,
      }
    case 'vaf':
      return {
        minWidth: 50,
        width: 50,
      }
    case 'Gene_refGene':
      return {
        formatter: GeneReferenceFormatter,
        onCellClick: handleLinkClick,
      }

    case 'ICGC_Id':
      return {
        formatter: icgcIdFormatter,
        onCellClick: handleLinkClick,
      }

    case 'avsnp150':
      return {
        formatter: avsnpFormatter,
        onCellClick: handleLinkClick,
      }

    case 'CancerVar_Tier':
      return {formatter: cancerVarFormatter}

    case 'cosmic91':
      return {
        formatter: cosmicFormatter,
        onCellClick: handleLinkClick,
      }

    case 'OMIM':
      return {
        formatter: omimFormatter,
        onCellClick: handleLinkClick,
      }

    case 'DET':
      return {formatter: detFormatter}

    case 'DETS':
      return {
        formatter: detsFormatter,
        type: FieldType.number,
      }

    case 'Oncogenic':
      return {
        formatter: oncogenicFormatter,
        onCellClick: handleLinkClick,
      }

    case 'PhenoTypeMIM':
      return {
        formatter: phenoTypeMIM,
        onCellClick: handleLinkClick,
      }

    case 'OrphaNumber':
      return {
        formatter: orphaNumberFormatter,
        onCellClick: handleLinkClick,
      }

    case 'Filter':
      return {formatter: filterFormatter}

    case 'Level1':
    case 'Level2':
    case 'Level3A':
    case 'Level3B':
    case 'Level4':
    case 'LevelR1':
    case 'LevelR2':
      return {formatter: levelFormatter}
    case 'VariantId':
      return {
        formatter: VariantIdFormatter,
        onCellClick: (e) => {
          e.preventDefault()
          if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
            window.open(e.target.getAttribute('href'), '_blank')
          }
        },
      }

    default:
      return {}
  }
}
const calculateVisibleRows = (isFilterable) => {
  // get table height

  const screenHeight = window.innerHeight

  const headerRowHeight = isFilterable ? 40 : 0

  const otherHeights = headerRowHeight + 40

  const usableHeight = screenHeight - otherHeights

  const rowHeight = 45

  const visibleRowCount = Math.floor(usableHeight / rowHeight)
  return visibleRowCount > 0 ? visibleRowCount : 1
}

function handleLinkClick(e) {
  e.preventDefault()
  const href = e.target.getAttribute('href')
  if (href && href !== '#') {
    window.open(href, '_blank')
  }
}
