import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
const ClinicAdvancedFilterModal = ({filters, setFilters}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  return (
    <div className='modal fade bd-example-modal-lg ' tabIndex={-1} id='ClinicAdvancedFilterModal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Advanced Filter </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></div>
          </div>
          <div className='modal-body'>
            <div className='card-body pt-2 row'>
              <div className='type-filter border-bottom  py-2 pb-5 my-3 '>
                <label className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    name='plof'
                    type='checkbox'
                    onChange={(e) => setFilters((prev) => ({...prev, tier1: e.target.checked}))}
                    defaultChecked={filters.plof}
                  />
                  <span className='fw-bold ps-2 fs-6'>Tier I strong </span>
                </label>

                <label className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    name='missense'
                    type='checkbox'
                    onChange={(e) => setFilters((prev) => ({...prev, tier2: e.target.checked}))}
                    defaultChecked={filters.missense}
                  />
                  <span className='fw-bold ps-2 fs-6'>Tier II potential</span>
                </label>

                <label className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    name='synonymous'
                    type='checkbox'
                    onChange={(e) =>
                      setFilters((prev) => ({...prev, tier3: e.target.checked}))
                    }
                    defaultChecked={filters.synonymous}
                  />
                  <span className='fw-bold ps-2 fs-6'>Tier III Uncertain</span>
                </label>
              </div>
              <div className='type-filter border-bottom  py-2  my-3 '>
                <label className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    name='plof'
                    type='checkbox'
                    onChange={(e) => setFilters((prev) => ({...prev, oncogenic: e.target.checked}))}
                    defaultChecked={filters.plof}
                  />
                  <span className='fw-bold ps-2 fs-6'>Oncogenic </span>
                </label>

                <label className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    name='missense'
                    type='checkbox'
                    onChange={(e) => setFilters((prev) => ({...prev, likelyOncogenic: e.target.checked}))}
                    defaultChecked={filters.missense}
                  />
                  <span className='fw-bold ps-2 fs-6'>Likely Oncogenic</span>
                </label>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClinicAdvancedFilterModal
