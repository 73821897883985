import React, {FC, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'
import OptionsSelect from '../../NewRun/OptionsSelect'
import {TagsInput} from 'react-tag-input-component'
import SwitchInput from '../../NewRun/SwitchInput'

const RunInformationStep = ({
  sampleData,
  setSampleData,
  selectedProcessType,
  setSelectedProcessType,
  userProcessTypes,
  setEnrichmentKits,
  fileType,
  enrichmentKits,
  enrichmentKit,
  setIsPairedEnd,
  isPairedEnd,
  setGenomeBuild,
  institutes,
  setInstitutes,
  physicians,
  setPhysicians,
  setEnrichmentKit,
  analysts,
  setAnalysts,
  setPhysiciansString,
  setEnrichmentOrRegion,
  setAnalystsString,
  setInstitutesString,
  fileTypes,
  setFileType,
}) => {
  var processTypeLogos = {
    wgs: 'WGS.png',
    wes: 'WES.png',
    tps: 'TPS.png',
    rnaseq: 'RNASeq.png',
    'som-test-tumor': 'TPS.png',
    'som-test-rna': 'RNAseq.png',
    'som-test-tumor-rna': 'dna-rna4.png',
  }
  const [selectedType, setSelectedType] = useState('germline')
  return (
    <div className='w-100'>
      <div
        className='row'
        style={{
          height: 'auto',
        }}
      >
        <div className='col-md-12'>
          <label className='w-100  ml-2 mb-2 input-label'>
            Process Type
            <span
              className='info-icon mx-2'
              data-tooltip-id='my-tooltip'
              data-tooltip-content=' Select the data type you want to process.'
            >
              <i className='fa fa-info'></i>
            </span>
          </label>
          {/* <OptionsSelect
            className='form-control mb-4 '
            options={userProcessTypes}
            selectRef={selectedProcessType}
            onchange={(e) => {
              setSampleData([
                {
                  id: '',
                  name: '',
                  files: {},
                  regionFile: {name: '.'},
                  sampleMeta: {},
                },
              ])
              setSelectedProcessType(e.target.value)
              const selectedKit = userProcessTypes.find((kit) => kit.type === e.target.value)
              if (selectedKit) {
                setEnrichmentKits(selectedKit.enrichmentKits)
              }
              setEnrichmentOrRegion('enrichment')
            }}
          /> */}
          <div className='d-flex'>
            <div className='col-md-2'>
              <div className='col-md-12'>
                <input
                  type='radio'
                  className={`btn-check `}
                  name='accountType'
                  onChange={(e) => {
                    setSelectedType('germline')

                    setSelectedProcessType('wgs')
                    const selectedKit = userProcessTypes.find((kit) => kit.type === 'som-test-rna')
                    if (selectedKit) {
                      setEnrichmentKits(selectedKit.enrichmentKits)
                    }
                    setEnrichmentOrRegion('enrichment')

                    document
                      .querySelectorAll('.selected-node')
                      .forEach((el) => el.classList.remove('selected-node'))
                  }}
                  id={`kt_create_account_form_account_type_germline`}
                />
                <label
                  className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack justify-content-start p-2 pt-4 m-3 ${
                    selectedType === 'germline' ? 'selected-node' : ''
                  } `}
                  htmlFor={`kt_create_account_form_account_type_germline`}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src='/media/Germline_V2.png' alt='node' height={100} className='' />

                  <span className='d-block fw-bold text-start mx-4'>
                    <span className='text-dark fw-bolder d-block fs-1 mb-2'>Germline</span>
                  </span>
                </label>
              </div>
              <div className='col-md-12'>
                <input
                  type='radio'
                  className={`btn-check `}
                  name='accountType'
                  onChange={(e) => {
                    setSelectedType('somatic')
                    setSampleData([
                      {
                        id: '',
                        name: '',
                        files: {},
                        regionFile: {name: '.'},
                        sampleMeta: {},
                      },
                    ])
                    setSelectedProcessType('som-test-rna')
                    const selectedKit = userProcessTypes.find((kit) => kit.type === 'som-test-rna')
                    if (selectedKit) {
                      setEnrichmentKits(selectedKit.enrichmentKits)
                    }
                    setEnrichmentOrRegion('enrichment')
                    document
                      .querySelectorAll('.selected-node')
                      .forEach((el) => el.classList.remove('selected-node'))
                  }}
                  id={`kt_create_account_form_account_type_somatic`}
                />
                <label
                  className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack justify-content-start p-2 pt-4 m-3 ${
                    selectedType === 'somatic' ? 'selected-node' : ''
                  } `}
                  htmlFor={`kt_create_account_form_account_type_somatic`}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src='/media/Tumor(somatic)_V2.png' alt='node' height={100} className='' />

                  <span className='d-block fw-bold text-start mx-4'>
                    <span className='text-dark fw-bolder d-block fs-1 mb-2'>Somatic</span>
                  </span>
                </label>
              </div>
              {/* <div className='col-md-12'>
                <input
                  type='radio'
                  className={`btn-check `}
                  name='accountType'
                  onChange={(e) => {
                    setSelectedType('prs')
                    setSampleData([
                      {
                        id: '',
                        name: '',
                        files: {},
                        regionFile: {name: '.'},
                        sampleMeta: {},
                      },
                    ])
                    setSelectedProcessType('prs')
                    const selectedKit = userProcessTypes.find((kit) => kit.type === 'prs')
                    if (selectedKit) {
                      setEnrichmentKits(selectedKit.enrichmentKits)
                    }
                    setEnrichmentOrRegion('enrichment')
                    document
                      .querySelectorAll('.selected-node')
                      .forEach((el) => el.classList.remove('selected-node'))
                  }}
                  id={`kt_create_account_form_account_type_prs`}
                />
                <label
                  className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack justify-content-start p-2 pt-4 m-3 ${
                    selectedType === 'prs' ? 'selected-node' : ''
                  } `}
                  htmlFor={`kt_create_account_form_account_type_prs`}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src='/media/Tumor(somatic)_V2.png' alt='node' height={100} className='' />

                  <span className='d-block fw-bold text-start mx-4'>
                    <span className='text-dark fw-bolder d-block fs-1 mb-2'>PRS</span>
                  </span>
                </label>
              </div> */}
            </div>
            <div className='col-md-10'>
              <div
                className='d-flex mt-2'
                style={{
                  flexWrap: 'wrap',
                }}
              >
                {userProcessTypes.map((item, index) => {
                  if (item.type === 'som-test-tumor' && selectedType === 'germline') return null
                  if (item.type === 'som-test-rna' && selectedType === 'germline') return null
                  if (item.type === 'som-test-tumor-rna' && selectedType === 'germline') return null
                  if (item.type === 'prs' && selectedType === 'germline') return null
                  if (item.type === 'wgs' && selectedType === 'somatic') return null
                  if (item.type === 'wes' && selectedType === 'somatic') return null
                  if (item.type === 'tps' && selectedType === 'somatic') return null
                  if (item.type === 'rnaseq' && selectedType === 'somatic') return null
                  if (item.type === 'prs' && selectedType === 'somatic') return null
                  if (item.type !== 'prs' && selectedType === 'prs') return null
                  if (item.type === 'prs' && selectedType === 'prs') {
                    return (
                      <div
                        className='col-md-12
                    '
                        onClick={(e) => {
                          setSampleData([
                            {
                              id: '',
                              name: '',
                              files: {},
                              regionFile: {name: '.'},
                              sampleMeta: {},
                            },
                          ])
                          setSelectedProcessType('prs')
                          const selectedKit = userProcessTypes.find((kit) => kit.type === item.type)
                          if (selectedKit) {
                            setEnrichmentKits(selectedKit.enrichmentKits)
                          }
                          setEnrichmentOrRegion('enrichment')
                        }}
                      >
                        <span
                          className={`btn btn-outline-dashed border d-flex flex-row justify-content-evenly align-items-center btn-sm m-1 ${
                            selectedProcessType === item.type ? 'bg-brand text-white' : ''
                          }`}
                          style={{
                            gap: '5px',
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              minWidth: '140px',
                            }}
                          >
                            <img
                              src={`/media/wes.png`}
                              height={60}
                              width={120}
                              alt=''
                              key={index}
                              style={{
                                filter: `${selectedProcessType === item.type ? 'invert(1)' : ''}`,
                              }}
                            />
                          </div>

                          <span
                            style={{
                              minWidth: '210px',
                              textAlign: 'left',
                            }}
                          >
                            Polygenic Risk Score
                          </span>
                        </span>
                      </div>
                    )
                  }
                  return (
                    <div
                      className='col-md-12
                      '
                      onClick={(e) => {
                        setSampleData([
                          {
                            id: '',
                            name: '',
                            files: {},
                            regionFile: {name: '.'},
                            sampleMeta: {},
                          },
                        ])
                        setSelectedProcessType(item.type)
                        const selectedKit = userProcessTypes.find((kit) => kit.type === item.type)
                        if (selectedKit) {
                          setEnrichmentKits(selectedKit.enrichmentKits)
                        }
                        setEnrichmentOrRegion('enrichment')
                      }}
                    >
                      <span
                        className={`btn btn-outline-dashed border d-flex flex-row justify-content-evenly align-items-center btn-sm m-1 ${
                          selectedProcessType === item.type ? 'bg-brand text-white' : ''
                        }`}
                        style={{
                          gap: '5px',
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            minWidth: '140px',
                          }}
                        >
                          <img
                            src={`/media/${processTypeLogos[item.type]}`}
                            height={60}
                            width={120}
                            alt=''
                            key={index}
                            style={{
                              filter: `${selectedProcessType === item.type ? 'invert(1)' : ''}`,
                            }}
                          />
                        </div>

                        <span
                          style={{
                            minWidth: '210px',
                            textAlign: 'left',
                          }}
                        >
                          {item.label}
                        </span>
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='text-danger mt-2'></div>
          </div>
        </div>
      </div>
      <div
        className='row'
        style={{
          height: 'auto',
        }}
      >
        {/* {selectedProcessType === 'prs' && (
          <div className='col-md-12 mb-5'>
            <TagsInput
              name='refferingPhys'
              value={institutes}
              onChange={setInstitutes}
              placeHolder='Enter Institue'
              onKeyUp={(e) => {
                if (!physicians) {
                  setInstitutesString([e.target.value])
                }
              }}
            />
          </div>
        )} */}

        {/* <div className='col-md-6 mb-5'>
          <TagsInput
            name='refferingPhys'
            value={physicians}
            onChange={setPhysicians}
            placeHolder='Enter Physicians (Optional)'
            onKeyUp={(e) => {
              if (!physicians) {
                setPhysiciansString([e.target.value])
              }
            }}
          />
        </div>
        <div className='col-md-6'>
          <TagsInput
            classNames={'mb-4'}
            name='refferingAnaylsts'
            value={analysts}
            onChange={setAnalysts}
            placeHolder='Enter Analysts (Optional)'
            onKeyUp={(e) => {
              if (!analysts) {
                setAnalystsString([e.target.value])
              }
            }}
          />
        </div> */}
        {selectedProcessType !== 'rnaseq' && selectedProcessType !== 'prs' && (
          <>
            <div className='col-md-4'>
              <label className='w-100 mt-5 ml-2 mb-2 input-label'>
                File Type
                <span
                  className='info-icon mx-2'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-html={`<center>Select the file type that you want to process.<br>Do not forget to select the reference genome version if BAM/VCF selected.</center>`}
                  data-tooltip-place={'right-start '}
                >
                  <i className='fa fa-info'></i>
                </span>
              </label>

              <div className='button-group mb-4'>
                {(selectedProcessType === 'som-test-rna' ||
                selectedProcessType === 'som-test-tumor' ||
                selectedProcessType === 'som-test-tumor-rna'
                  ? fileTypes.slice(0, 2)
                  : fileTypes
                ).map((fileTypeOption, index) => (
                  <span
                    key={index}
                    className={`btn ${
                      fileType === fileTypeOption.type ? 'btn-primary' : 'btn-secondary'
                    } mx-2`}
                    onClick={() => {
                      var resettedData = sampleData.map((item) => {
                        return {
                          ...item,
                          files: {},
                        }
                      })
                      setFileType(fileTypeOption.type)
                      setSampleData(resettedData)
                    }}
                    style={{width: '28%'}}
                  >
                    {fileTypeOption.label}
                  </span>
                ))}
              </div>
            </div>
          </>
        )}
        {(selectedProcessType === 'wgs' ||
          selectedProcessType === 'tps' ||
          selectedProcessType === 'wes') &&
          fileType === 'vcf' && (
            <div className='col-md-8 mt-5'>
              <label className='w-100  ml-2 mb-2 input-label'> Reference Genome </label>

              <select
                className={`form-select`}
                onChange={(e) => {
                  setGenomeBuild(e.target.value)
                }}
              >
                <option value={'hg38'} selected>
                  GRCh38 / hg38
                </option>
                <option value={'hg19'}>GRCh37 / hg19</option>
              </select>
            </div>
          )}
      </div>

      {/* {selectedProcessType !== 'wgs' &&
        selectedProcessType !== 'tps' &&
        selectedProcessType !== 'prs' &&
        selectedProcessType !== 'rnaseq' && (
          <label className='w-100 mb-3'>
            <label className='w-100  ml-2 mb-2 input-label'>
              {' '}
              Enrichment Kit{' '}
              <span
                className='info-icon mx-2'
                data-tooltip-id='my-tooltip'
                data-tooltip-html='<center> Select the enrichment kit used in the exome run. If you do not know which kit was used, we recommend using the "Combined Region File" option.<br> This file is created by combining all the kits in the options below.</center>'
                data-tooltip-place={'right-start '}
              >
                <i className='fa fa-info'></i>
              </span>
            </label>

            <select
              className={`form-select `}
              onChange={(e) => {
                const kit = enrichmentKits.find((k) => k.name === e.target.value)
                setEnrichmentKit(kit)
                console.log(kit, enrichmentKit)
              }}
              value={userProcessTypes[1].en}
            >
              <option disabled selected>
                Enrichment Kit
              </option>
              <option value={''}></option>
              {enrichmentKits?.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.company} - {item.title}
                </option>
              ))}
            </select>
          </label>
        )} */}
      {selectedProcessType === 'rnaseq' && (
        <SwitchInput
          label={'Is Single End'}
          onchange={() => setIsPairedEnd(!isPairedEnd)}
          className={'mx-5'}
          labelClass='linkage-label'
        />
      )}
      {/* <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Account Info</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Specify Team Size
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='1-1'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>1-1</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='2-10'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>2-10</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='10-50'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>10-50</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='50+'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>50+</span>
            </label>
          </div>
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Team Account Name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div>
      </div>

      <div className='mb-0 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Select Account Plan
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Monthly billing will be based on your account plan'
          ></i>
        </label>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='bank' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Company Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance your post flow
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='1' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Developer Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='2' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart-pie-4' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Testing Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance time travel rivers
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='3' />
            </span>
          </label>
        </div>
      </div> */}
    </div>
  )
}

export {RunInformationStep}
