import React, {useEffect} from 'react'
import FileInput from '../FileInput'
import OptionsSelect from '../OptionsSelect'

const SomTestRnaFormFiles = ({
  sampleData,
  setSampleData,
  selectedProcessType,
  fileType,
  enrichmentOrRegion,
}) => {
  return (
    <div>
      {sampleData.map((sample, index) => (
        <div
          className=' d-flex align-items-center  flex-wrap justify-content-between mt-5 p-2'
          key={index}
        >
          {fileType === 'fastq' && (
            <>
              <FileInput
                label={'Upload FASTQ R1 File '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR1RNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2RNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}

          {fileType === 'bam' && (
            <>
              <FileInput
                label={'Upload BAM File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bamRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {enrichmentOrRegion === 'region' &&
            (sampleData[0].files.regionsFileRNA === undefined ||
              sampleData[0].files.regionsFileRNA === null ||
              sampleData[0].files.regionsFileRNA === '' ||
              sampleData[0].files?.regionsFileRNA?.name === undefined) && (
              <FileInput
                label={'Upload Regions File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFileRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            )}
        </div>
      ))}
    </div>
  )
}

export default SomTestRnaFormFiles
