import React, { useEffect, useState } from "react";

const ProteinPaintChart = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!document.querySelector(`script[src="https://proteinpaint.stjude.org/bin/proteinpaint.js"]`)) {
      const script = document.createElement("script");
      script.src = "https://proteinpaint.stjude.org/bin/proteinpaint.js";
      script.charset = "utf-8";
      script.async = true;

      script.onload = () => {
        setLoading(false);
        if (window.runproteinpaint) {
          initProteinPaint();
        }
      };

      document.body.appendChild(script);
    } else {
      setLoading(false);
      initProteinPaint();
    }
  }, []);

  const initProteinPaint = () => {
    if (window.runproteinpaint) {
      window.runproteinpaint({
        host: "https://proteinpaint.stjude.org",
        holder: document.getElementById("protein-paint-container"),
        parseurl: true,
        noheader: true,
        width: 250,
        nobox: true,
        genome: "hg38",
        gene: "NRAS",
        tracks: [
          {
            type: "mds3",
            name: "custom nras",
            height: 200,
            width: 110,
            custom_variants: [
              {
                chr: "chr1",
                pos: 114713909,
                mname: "p.Q61K",
                class: "M",
                dt: 1,
                ref: "G",
                alt: "T",
              },
            ],
          },
        ],
      });
    }
  };

  return (
    <div>
      <h1>Protein Paint Visualization</h1>

      {loading && (
        <div className={` h-auto text-center  py-2 `}>
          <div className='spinner-grow text-primary m-auto py-2' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}

      <div id="protein-paint-container" style={{ width: "250px", height: "400px" }}></div>
    </div>
  );
};

export default ProteinPaintChart;
