import React, { useEffect, useRef } from 'react';

const ProteinPaintChart = () => {
  const chartRef = useRef(null);

  // Dinamik olarak JS ve CSS dosyalarını eklemek için fonksiyon
  const loadScript = (url, callback) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = callback; // Script yüklendiğinde callback'i çağır
    document.body.appendChild(script);
  };

  const loadCSS = (url) => {
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    // CSS'yi yükle
    loadCSS('https://proteinpaint.stjude.org/proteinpaint/proteinpaint.css');
    
    // JS'yi yükle ve tamamlandığında grafiği render et
    loadScript('https://proteinpaint.stjude.org/proteinpaint/proteinpaint.js', () => {
      if (typeof window.runproteinpaint === 'function') {
        window.runproteinpaint({
          host: "https://proteinpaint.stjude.org",
          holder: chartRef.current,
          parseurl: true,
          noheader: true,
          nobox: true,
          genome: "hg19",
          gene: "NM_016734",
          tracks: [
            {
              type: "mds3",
              name: "custom snvindel and fusion",
              custom_variants: [
                {
                  chr: "chr9",
                  pos: 37015165,
                  mname: "P80R",
                  class: "M",
                  dt: 1,
                  ref: "G",
                  alt: "C"
                },
                {
                  gene1: "PAX5",
                  chr1: "chr9",
                  pos1: 37002646,
                  strand1: "-",
                  gene2: "JAK2",
                  chr2: "chr9",
                  pos2: 5081726,
                  strand2: "+",
                  dt: 2,
                  class: "Fuserna",
                  sample: "Fusion Sample ID"
                },
                {
                  gene1: "ZCCHC7",
                  chr1: "chr9",
                  pos1: 37257786,
                  strand1: "-",
                  gene2: "PAX5",
                  chr2: "chr9",
                  pos2: 37024824,
                  strand2: "-",
                  dt: 5,
                  class: "SV",
                  sample: "SV Sample ID"
                }
              ]
            }
          ]
        });
      } else {
        console.error("runproteinpaint fonksiyonu mevcut değil!");
      }
    });

    // Cleanup - bileşen unmount olduğunda JS ve CSS dosyalarını kaldır
    return () => {
      const proteinpaintScript = document.querySelector('script[src="https://proteinpaint.stjude.org/proteinpaint/proteinpaint.js"]');
      const proteinpaintCSS = document.querySelector('link[href="https://proteinpaint.stjude.org/proteinpaint/proteinpaint.css"]');

      if (proteinpaintScript) {
        document.body.removeChild(proteinpaintScript);
      }
      if (proteinpaintCSS) {
        document.head.removeChild(proteinpaintCSS);
      }
    };
  }, []);

  return <div ref={chartRef} style={{ width: '100%', height: '500px' }}></div>;
};

export default ProteinPaintChart;
