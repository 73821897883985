import {useState, useEffect} from 'react'
import './TableComponent.scss' // custom CSS/SASS styling
import {
  Column,
  Filters,
  // Formatters,
  GridOption,
  // GridStateChange,
  // GridStateType,
  SlickgridReact,
  OperatorType,
  // TreeToggledItem,
  FieldType,
  SlickgridReactInstance,
  Formatters,
} from 'slickgrid-react'

import {Loader} from '../../components/Loader'
import '../../../node_modules/@slickgrid-universal/common/dist/styles/css/slickgrid-theme-bootstrap.css'
import {
  GeneReferenceFormatter,
  avsnpFormatter,
  cancerVarFormatter,
  cosmicFormatter,
  detFormatter,
  detsFormatter,
  filterFormatter,
  icgcIdFormatter,
  omimFormatter,
  oncogenicFormatter,
  orphaNumberFormatter,
  phenoTypeMIM,
  filterObjects,
  canverEvTooltips,
  levelFormatter,
  VariantIdFormatter,
} from './Formatters'
import {Tooltip} from 'react-tooltip'

// import {useThemeMode} from '../../_metronic/partials'

const TableComponent = ({
  data,
  // selectedRows,
  setSelectedRows,
  // selectedWidgetItem,
  setSelectedWidgetItem,
  tableHeader,
  gridOptions,
  setAllelicWidgetDatas,
  isFilterable = true,
  canMultiSelect = true,
  autoHeight = true,
  hiddenColumns,
  setGridOptions,
  isClinic,
  isTreeData,
  handleSubmitVariant,
  gridId,
}) => {
  // const [hasNoExpandCollapseChanged, setHasNoExpandCollapseChanged] = useState(true)
  // const [treeToggleItems, setTreeToggleItems] = useState<TreeToggledItem[]>([])
  const [columnDefinitions, setColumnDefinitions] = useState<Column[]>([])
  const onGrid1SelectedRowsChanged = (_e: Event, args: any) => {
    const grid = args && args.grid
    if (Array.isArray(args.rows)) {
      setSelectedRows(
        args.rows.map((idx: number) => {
          const item = grid.getDataItem(idx)
          return item
        })
      )
    }
  }
  useEffect(() => {
    if (data.length >= 1) {
      setGridOptions(undefined)
      defineGrid()
    }
  }, [])

  const plofKeywords = [
    'stopgain',
    'frameshift deletion',
    'frameshift insertion',
    'stoploss',
    'startlost',
    'start gained',
    'splicing site',
  ]
  const missenseKeywords = ['nonsynonymous SNV']
  const synonymousKeywords = ['synonymous SNV']
  const otherKeywords = [
    'nonframeshift deletion',
    'nonframeshift substitution',
    'nonframeshift insertion',
    'unknown',
  ]
  // useEffect(() => {
  //   console.log('gridOptions', gridOptions)
  // }, [gridOptions])
  const customSortFunction = (columnField, dataContextA, dataContextB, grid) => {
    const column = columnDefinitions.find((col) => col.field === columnField)
    if (column) {
      const valueA = parseFloat(dataContextA[column.field])
      const valueB = parseFloat(dataContextB[column.field])

      if (valueA === valueB) {
        return 0
      } else if (valueA < valueB) {
        return -1
      } else {
        return 1
      }
    } else {
      return 0
    }
  }
  function getGeneNameListUnique(data: {[key: string]: any}[], columnKey: string): string[] {
    const uniqueValues: string[] = []

    for (const item of data) {
      const values = item[columnKey] // columnKey ile belirtilen değeri al

      if (Array.isArray(values)) {
        for (const value of values) {
          if (!uniqueValues.includes(value)) {
            uniqueValues.push(value)
          }
        }
      }
    }

    return uniqueValues
  }

  const chromComparator = (a, b) => {
    const extractChromNumber = (chrom) => {
      const match = chrom.match(/^chr(\d+|[XYM])$/i)
      if (!match) return chrom
      const value = match[1]
      if (value === 'X') return 23
      if (value === 'Y') return 24
      if (value === 'M') return 25
      return parseInt(value, 10)
    }

    const chromA = extractChromNumber(a)
    const chromB = extractChromNumber(b)

    return chromA - chromB
  }
  const fusionChromComparator = (a, b) => {
    const extractChromNumber = (chrom) => {
      const match = chrom.match(/^chr(\d+|[XYM]):/i)
      if (!match) return 1000 // Eğer match bulunamazsa, büyük bir sayı döndürerek sıralamada en sona koyma
      const value = match[1]
      if (value === 'X') return 23
      if (value === 'Y') return 24
      if (value === 'M') return 25
      return parseInt(value, 10)
    }

    const chromA = extractChromNumber(a)
    const chromB = extractChromNumber(b)

    return chromA - chromB
  }

  function cbpComparator(a, b) {
    // Boş veya sadece nokta içeren değerleri sona at
    const isAEmpty = !a || a.trim() === '.'
    const isBEmpty = !b || b.trim() === '.'

    if (isAEmpty && isBEmpty) return 0
    if (isAEmpty) return 1
    if (isBEmpty) return -1

    // Stringleri boşluklara göre ayırarak bireysel CBP değerlerini al
    const arrA = a.split(' ')
    const arrB = b.split(' ')

    // En kısa dizi uzunluğunu bul
    const minLength = Math.min(arrA.length, arrB.length)

    // Bireysel CBP değerlerini karşılaştır
    for (let i = 0; i < minLength; i++) {
      const numA = parseInt(arrA[i].replace('CBP', ''), 10)
      const numB = parseInt(arrB[i].replace('CBP', ''), 10)

      if (numA < numB) return -1
      if (numA > numB) return 1
    }

    // Eğer tüm karşılaştırmalar eşitse, dizi uzunluğunu karşılaştır
    if (arrA.length < arrB.length) return -1
    if (arrA.length > arrB.length) return 1

    // Her şey eşitse, eşit olarak değerlendir
    return 0
  }

  const defineGrid = () => {
    const columnDefs = Object.keys(tableHeader).map((columnKey) => {
      let additionalProps = {}
      if (canMultiSelect) {
        if (columnKey === 'reportSelect') {
          additionalProps = {
            frozen: true,
            formatter: null,
            minWidth: 30,
            name: '',
            filterable: false,
            sortable: false,
          }
        }
      }

      let filter
      if (
        columnKey === 'Chrom' ||
        columnKey === 'Func_refGene' ||
        columnKey === 'ExonicFunc_refGene' ||
        columnKey === 'CancerVar_Tier' ||
        columnKey === 'Oncogenic' ||
        columnKey === 'HighestLevel' ||
        columnKey === 'HighestSensitiveLevel' ||
        columnKey === 'HighestResistanceLevel' ||
        columnKey === 'MutationEffect' ||
        columnKey === 'HighestDxLevel' ||
        columnKey === 'HighestPxLevel' ||
        columnKey === 'Variant_Type'
      ) {
        const uniqueValues = getUniqueValues(data, columnKey)
        const collection = uniqueValues.map((value) => ({value, label: value}))
        filter = {
          model: Filters.multipleSelect,
          collection,
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }
      if (columnKey === 'Gene_refGene') {
        const uniqueValues = getGeneNameListUnique(data, columnKey)
        const collection = uniqueValues
          .sort() // Alfabetik sıralama
          .map((value) => ({value, label: value}))
        filter = {
          model: Filters.multipleSelect,
          operator: OperatorType.inContains, // defaults to inclusive

          collection,
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }
      if (columnKey === 'DET') {
        const uniqueValues = getUniqueValues(data, columnKey)
        const collection = uniqueValues.map((value) => ({value, label: value}))

        filter = {
          model: Filters.singleSelect,
          collection,
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }

      if (columnKey === 'GeneInOncoKB') {
        filter = {
          model: Filters.multipleSelect,
          collection: ['true', 'false'],
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }
      if (columnKey === 'Start' || columnKey === 'End') {
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'Filter') {
        var filterItem = isClinic
          ? [
              {
                type: 'PASS',
                desc: 'multiple events are present on the same haplotype as the variant which is indicative of a false-positive call.',
              },
            ]
          : filterObjects
        const collection = filterItem.map((item) => {
          return {value: item.type, label: item.type}
        })

        filter = {
          model: Filters.multipleSelect,
          collection,
          filterOptions: {
            autoDropWidth: true,
          },
          operator: OperatorType.inContains,
        }
      }
      if (columnKey === 'CancerVar_Evidence') {
        const collection = Object.keys(canverEvTooltips).map((item) => {
          return {value: item, label: item}
        })

        filter = {
          model: Filters.multipleSelect,
          collection,
          filterOptions: {
            autoDropWidth: true,
          },
          operator: OperatorType.contains,
        }
      }
      if (columnKey === 'exon') {
        const uniqueValues = getUniqueValues(data, columnKey)
        const collection = uniqueValues.map((value) => ({value, label: value}))

        filter = {
          model: Filters.multipleSelect,
          collection,
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }
      if (columnKey === 'DETS') {
        filter = {
          model: Filters.compoundInputText,
          // operator: '>=',
        }
        additionalProps = {
          type: FieldType.number,
          formatter: detsFormatter,
        }
      }
      if (columnKey === 'FFPM') {
        filter = {
          model: Filters.compoundInputText,
        }
        additionalProps = {
          type: FieldType.number,
        }
      }
      if (columnKey === 'AD' || columnKey === 'AF' || columnKey === 'DP') {
        filter = {
          model: Filters.compoundInputText,
          // operator: '>=',
        }
        additionalProps = {
          type: FieldType.number,
        }
      }

      if (columnKey === 'variantId') {
        additionalProps = {
          formatter: VariantIdFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
      }
      if (columnKey === 'Gene_refGene') {
        additionalProps = {
          formatter: GeneReferenceFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
      }
      if (columnKey === 'cosmic91') {
        additionalProps = {
          formatter: cosmicFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
      }
      if (columnKey === 'OMIM') {
        additionalProps = {
          formatter: omimFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'tx') {
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'Phenotype_MIM') {
        additionalProps = {
          formatter: phenoTypeMIM,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'OrphaNumber') {
        additionalProps = {
          formatter: orphaNumberFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'Orpha') {
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'avsnp150') {
        additionalProps = {
          formatter: avsnpFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
      }
      if (columnKey === 'DET') {
        additionalProps = {
          formatter: detFormatter,
        }
      }

      if (columnKey === 'Oncogenic') {
        additionalProps = {
          formatter: oncogenicFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
      }
      if (columnKey === 'ICGC_Id') {
        additionalProps = {
          formatter: icgcIdFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              window.open(e.target.getAttribute('href'), '_blank')
            }
          },
        }
        filter = {
          operator: OperatorType.startsWith,
        }
      }
      if (columnKey === 'Filter') {
        additionalProps = {
          formatter: filterFormatter,
        }
      }
      if (columnKey === 'CancerVar_Evidence') {
        additionalProps = {
          formatter: cancerVarFormatter,
        }
      }
      if (
        columnKey === 'Level1' ||
        columnKey === 'Level2' ||
        columnKey === 'Level3A' ||
        columnKey === 'Level3B' ||
        columnKey === 'Level4' ||
        columnKey === 'LevelR1' ||
        columnKey === 'LevelR2'
      ) {
        additionalProps = {
          formatter: levelFormatter,
        }
      }
      // if (columnKey === 'Start' || columnKey === 'Chrom') {
      //   additionalProps = {
      //     columnGroup: 'Deneme Grubu',
      //   }
      // }
      // if (columnKey === 'Alt' || columnKey === 'Gene_refGene') {
      //   additionalProps = {
      //     columnGroup: 'Deneme Grubu 2',
      //   }
      // }
      return {
        id: columnKey,
        name: tableHeader[columnKey].value,
        columnGroup: tableHeader[columnKey].columnGroup,
        field: columnKey,
        cssClass: 'cell-title',
        minWidth: 120,
        // hide: true,
        filterable: isFilterable,
        // hidden: tableHeader[columnKey] === 'Start' ? true : false,
        sortable: true,
        customTooltip: {
          useRegularTooltip: true,
          useRegularTooltipFromFormatterOnly: true,
        },
        formatter: (row, cell, value, columnDef, dataContext) => {
          var extraClass = ''
          if (plofKeywords.includes(dataContext.ExonicFunc_refGene)) {
            extraClass += ' plofBg'
          }
          if (missenseKeywords.includes(dataContext.ExonicFunc_refGene)) {
            extraClass += ' missenseBg'
          }
          if (synonymousKeywords.includes(dataContext.ExonicFunc_refGene)) {
            extraClass += ' synonymousBg'
          }
          if (otherKeywords.includes(dataContext.ExonicFunc_refGene)) {
            extraClass += ' otherBg'
          }
          if (columnDef.field === 'CancerVar_Tier' && isClinic) {
            return `<div class="${extraClass}"
            data-tooltip-id='my-tooltip'
            data-tooltip-content='${dataContext.CancerVar_Evidence}'
                       
         >${value === null || value === undefined ? '.' : value}</div>`
          }
          return `<div class="${extraClass}"
          >${value === null || value === undefined ? '.' : value}</div>`
        },
        ...additionalProps,
        filter,
        ...(columnKey === 'Chrom'
          ? {
              sortComparer: (a, b, sortDirection) => {
                const result = chromComparator(a, b)
                return sortDirection === 1 ? result : -result
              },
            }
          : {}),
        ...(columnKey === 'RightBreakpoint'
          ? {
              sortComparer: (a, b, sortDirection) => {
                const result = fusionChromComparator(a, b)
                return sortDirection === 1 ? result : -result
              },
            }
          : {}),
        ...(columnKey === 'CancerVar_Evidence'
          ? {
              sortComparer: (a, b, sortDirection) => {
                const result = cbpComparator(a, b)
                return sortDirection === 1 ? result : -result
              },
            }
          : {}),
        // ...(columnKey === 'CancerVar_Evidence'
        //   ? {
        //       sortComparer: (a, b, sortDirection) => {
        //         console.log(sortDirection)
        //         const result = cbpComparator(a, b)
        //         return sortDirection === 1 ? result : -result
        //       },
        //     }
        //   : {}),
      }
    })
    const gridOpts: GridOption = {
      autoResize: {
        container: '#demo-container',
        rightPadding: 10,
      },
      autoHeight: autoHeight, // Yüksekliği dinamik hale getirmemek için bu ayarı kapatabilirsiniz

      enableAutoSizeColumns: true,
      enableAutoResize: true,
      enableFiltering: true,
      frozenColumn: canMultiSelect ? 0 : -1,
      enableRowSelection: canMultiSelect,
      enableCheckboxSelector: canMultiSelect,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: isFilterable ? 28 : 0,
      enableTreeData: false,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },

      enablePagination: true,
      pagination: {
        pageSizes: [20, 25, 50, 100],
        pageSize: 100,
      },
      multiColumnSort: true,
      showCustomFooter: true,
      headerRowHeight: isFilterable ? 40 : 0,
      rowHeight: 30,
      presets: {
        filters:
          gridId === 'snvTable'
            ? [
                {
                  columnId: 'Filter',
                  searchTerms: isClinic
                    ? ['PASS']
                    : [
                        'PASS',
                        'clustered_events',
                        'duplicate',
                        'fragment',
                        'multiallelic',
                        'n_ratio',
                        'orientation',
                        'position',
                        'slippage',
                        'haplotype',
                        'germline',
                        'strict_strand',
                      ],
                },
              ]
            : [],
      },
    }

    setColumnDefinitions(columnDefs)
    setGridOptions(gridOpts)
  }
  // const handleOnGridStateChanged = (gridStateChange: GridStateChange) => {
  //   setHasNoExpandCollapseChanged(false)

  //   if (gridStateChange?.change?.type === GridStateType.treeData) {
  //     setTreeToggleItems(gridStateChange?.gridState?.treeData?.toggledItems as TreeToggledItem[])
  //   }
  // }
  const onCellClicked = (e: any, args: any) => {
    const grid = args && args.grid
    const rows = document.querySelectorAll('.slick-row')

    if (e.target.parentElement.parentElement.classList.contains('slick-row')) {
      rows.forEach((row) => {
        row.classList.remove('background-red')
      })
      e.target.parentElement.parentElement.classList.add('background-red')
    } else if (e.target.parentElement.classList.contains('slick-row')) {
      rows.forEach((row) => {
        row.classList.remove('background-red')
      })
      e.target.parentElement.classList.add('background-red')
    } else if (e.target.classList.contains('slick-row')) {
      rows.forEach((row) => {
        row.classList.remove('background-red')
      })
      e.target.classList.add('background-red')
    }
    var clickedData = grid.getDataItem(args.row)
    const alleleDatas = data.filter((item) => clickedData.alleleIds.includes(item.id))
    setSelectedWidgetItem(clickedData)
    setAllelicWidgetDatas(alleleDatas)
  }
  const getUniqueValues = (data: {[key: string]: any}[], columnKey: string) => {
    const uniqueValues = Array()
    for (const item of data) {
      if (!uniqueValues.includes(item[columnKey])) {
        uniqueValues.push(item[columnKey])
      }
    }
    return uniqueValues
  }

  if (data.length < 1) {
    return <div className='  text-center'>There is no data</div>
  }
  const onGridLoaded = (gridInstance: SlickgridReactInstance) => {
    // hide columns dynamically
    if (isClinic) {
      if (hiddenColumns && hiddenColumns.length > 0) {
        // hiddenColumns.forEach((columnId) => {
        gridInstance.gridService.hideColumnByIds(hiddenColumns)
      }

      //   })
    }
  }

  return data.length === 0 || !gridOptions || gridOptions === undefined ? (
    <div className='card'>
      <div className='card-body text-center'>
        <Loader />
      </div>
    </div>
  ) : (
    <div id='demo-container' className='container-fluid mt-5' style={{overflow: 'hidden'}}>
      <div id='grid-container' className='col-sm-12 custom-horizontal-scrollbar'>
        <SlickgridReact
          gridId={gridId}
          columnDefinitions={columnDefinitions}
          gridOptions={gridOptions}
          dataset={data}
          // onGridStateChanged={($event) => handleOnGridStateChanged($event.detail)}
          onSelectedRowsChanged={($event) =>
            onGrid1SelectedRowsChanged($event.detail.eventData, $event.detail.args)
          }
          onClick={($event) => {
            onCellClicked($event.detail.eventData, $event.detail.args)
          }}
          onReactGridCreated={($event) => onGridLoaded($event.detail)}
        />
        <Tooltip id='my-tooltip' />
      </div>
    </div>
  )
}

export default TableComponent
