import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {RunsPage} from '../pages/RunsPage'
import {NewRunPage} from '../pages/NewRunPage'
import {FaqPage} from '../pages/FaqPage'
import {TablePage} from '../pages/TablePage'
import {RunDetailPage} from '../pages/RunDetailPage'
import {ReportsPage} from '../pages/ReportsPage'
import {NewRunDetailPage, SomaticRunDetailPage} from '../pages/SomaticRunDetailPage'
import {WithDataTransmitPage} from '../pages/WithDataTransmitPage'
import {WithDataTransmitPageNew} from '../pages/WithDataTransmitPageNew'
import {UserListPage} from '../pages/UserListPage'
import {UserRunListPage} from '../pages/UserRunListPage'
import {FileManagerPage} from '../pages/FileManagerPage'
import {SomaticDetailTable} from '../pages/SomaticDetailTable'
import {QcReport} from '../../components/RunDetail/QcReport'
import {JsonImportPage} from '../pages/JsonImportPage'
import {ChangeSomaticReportPage} from '../pages/ChangeSomaticReportPage'
import {CustomTry} from '../pages/CustomTry'
import {RunDetailTablePage} from '../pages/RunDetailTablePage'
import IGVApp from '../pages/igv'
import MorpheusHeatMap from '../pages/MorpheusPage'
import AlleleFreqPage from '../pages/AlleleFreqPage'
import {BestPracticesPage} from '../pages/BestPractices'
import {NewRunWizard} from '../../components/NewRunWizard/NewRunWizard'
import {SomaticDetailClinicPage} from '../pages/SomaticDetailClinicPage'
import {CreatePRSPage} from '../pages/CreatePRSPage'
import {PrsRunDetailPage} from '../pages/PrsRunDetailPage'
const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  const user = JSON.parse(localStorage.getItem('user'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='runs' element={<RunsPage />} />
        <Route path='new-run' element={<NewRunPage />} />
        <Route path='with-data-transmit' element={<WithDataTransmitPage />} />
        <Route path='with-data-transmit-new' element={<WithDataTransmitPageNew />} />
        {user.type === 'admin' && (
          <>
            <Route path='users-list' element={<UserListPage />} />
            <Route path='users-run-list' element={<UserRunListPage />} />
          </>
        )}
        <Route path='table' element={<TablePage />} />
        <Route path='create-prs' element={<CreatePRSPage />} />
        <Route path='run-detail-somatic/:id' element={<SomaticRunDetailPage />} />
        <Route path='run-detail-prs/:id' element={<PrsRunDetailPage />} />
        <Route path='somatic-detail/:id' element={<SomaticDetailTable />} />
        <Route path='somatic-detail-clinic/:id' element={<SomaticDetailClinicPage />} />
        <Route path='reports' element={<ReportsPage />} />
        <Route path='new-run-wizard' element={<NewRunWizard />} />
        <Route path='run-detail-table/:id' element={<RunDetailTablePage />} />
        <Route path='change-somatic-report-template' element={<ChangeSomaticReportPage />} />
        <Route path='file-manager' element={<FileManagerPage />} />
        <Route path='morpheus' element={<MorpheusHeatMap />} />
        <Route path='afb' element={<AlleleFreqPage />} />
        <Route path='qc-report' element={<QcReport />} />
        <Route path='run-detail/:id' element={<RunDetailPage />} />
        <Route path='faq' element={<FaqPage />} />
        <Route path='best-practices' element={<BestPracticesPage />} />
        <Route path='json-import' element={<JsonImportPage />} />
        <Route path='igv' element={<IGVApp />} />
        <Route path='custom-try' element={<CustomTry />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
