import React from "react";
import Chart from "react-apexcharts";

const CosmicChart = ({ cosmicData }) => {
    const total = cosmicData.reduce((sum, item) => sum + item[1], 0);

    const sortedData = [...cosmicData].sort((a, b) => b[1] - a[1]);

    const top19 = sortedData.slice(0, 19);
    const others = sortedData.slice(19);

    const otherTotal = others.reduce((sum, item) => sum + item[1], 0);

    const finalData = [...top19, ["Other", otherTotal]];

    const categories = finalData.map(item => item[0]);
    const percentages = finalData.map(item => (Math.round((item[1] / total) * 100)));
    const values = finalData.map(item => item[1]); // 🔥 Gerçek sayıları kullan!

    const maxPercentage = Math.max(...percentages.map(Number)) + 3;
    console.log(percentages)
    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "60%",
                distributed: true,
            },
        },
        dataLabels: {
            enabled: false,
            formatter: (val) => `${val}%`,
            offsetY: -10,
            style: {
                fontSize: "8px",
                fontWeight: "bold",
            },
        },
        xaxis: {
            categories: categories,
            labels: {
                rotate: -45,
                style: {
                    fontSize: "8px",
                    fontWeight: "bold",
                },
                trim: false,
                formatter: (val) => val.length > 10 ? `${val.substring(0, 20)}...` : val, 
            },
        },
        yaxis: {
            title: {
                text: "Cosmic Average (%)",
            },
            min: 0,
            max: maxPercentage,
        },
        tooltip: {
            y: {
                formatter: (val, { dataPointIndex }) => {
                    return `Count: ${values[dataPointIndex]}`; // Burada metni kesme, tam hali göster
                },
            },
            x: {
                formatter: (val, { dataPointIndex }) => categories[dataPointIndex], // Tooltipte tam kategori adını göster
            },
        },
        grid: {
            padding: {
                left: 10,
                right: 10,
            },
        },
        colors: [
            "#E93645",
        ],
        legend: {
            show: false,
        },
    };

    const series = [
        {
            name: "",
            data: percentages,
        },
    ];

    return <Chart options={options} series={series} type="bar" height={350} width={800} />;
};

export default CosmicChart;
