import React, {useEffect} from 'react'
import FileInput from '../FileInput'

const RnaseqFormFiles = ({sampleData, setSampleData, selectedProcessType}) => {
  return (
    <div>
      {sampleData.map((sample, index) => (
        <div
          className=' d-flex align-items-center  flex-wrap justify-content-between mt-5 p-2'
          key={index}
        >
          <FileInput
            label={'Upload FASTQ R1 File'}
            onchange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].files.fqR1 = e.target.files[0]
              setSampleData(clonedSampleData)
            }}
          />
          <FileInput
            label={'Upload FASTQ R2 File'}
            onchange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].files.fqR2 = e.target.files[0]
              setSampleData(clonedSampleData)
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default RnaseqFormFiles
