import axios from './index'

const getAllRuns = async () => {
  const res = await axios.get(`/runs`)
  return res
}

const getRunDetail = async (runId) => {
  const res = await axios.get(`/runs/${runId}`)
  return res
}

const getGenesList = async () => {
  const res = await axios.get(`https://gennext-edge.farplane.dev/api/v1/geneslists`)
  return res
}
const getGenesListSingle = async (id) => {
  const res = await axios.get(`https://gennext-edge.farplane.dev/api/v1/geneslists/${id}`)
  return res
}
const getUserProcessTypes = async (runId) => {
  const res = await axios.get(`/user/processtypes`)
  return res
}
const getFilterInfo = async (runId) => {
  const res = await axios.get(`/runs/${runId}/filterinfo`)
  return res
}

const updateRunIsSolved = (runIsSolved, runId) => {
  axios.patch(`/runs/${runId}`, {
    isSolved: !runIsSolved,
  })
}

const updateRunClinicalDiagnosis = (clinicalDiagnosis, runId, sampleId) => {
  axios.patch(`/runs/${runId}`, {
    samples: [
      {
        id: sampleId,
        clinicalDiagnosis,
      },
    ],
  })
}
const updateRunSpecimenType = (specimenType, runId, sampleId) => {
  axios.patch(`/runs/${runId}`, {
    samples: [
      {
        id: sampleId,
        specimenType,
      },
    ],
  })
}
const updateRunSpecimenSite = (specimenSite, runId, sampleId) => {
  axios.patch(`/runs/${runId}`, {
    samples: [
      {
        id: sampleId,
        specimenSite,
      },
    ],
  })
}

const runFilterGeneration = async (data) => {
  const response = await axios.post(`/filter`, data).catch(function (error) {
    return error
  })
  return response
}
const annotateFilter = async (runId, data) => {
  const response = await axios.post(`/runs/${runId}/annotate`, data).catch(function (error) {
    return error
  })
  return response
}
const createFilterSession = async (data, baseUrl) => {
  const response = await axios
    .post(`${baseUrl}/result/service/createfiltersession`, data)
    .catch(function (error) {
      return error
    })
  return response
}
const checkFilterStatusCravat = async (prefix, runId, userId, baseUrl) => {
  console.log(baseUrl)
  const response = await axios
    .get(`${baseUrl}/result/service/statusping?username=${userId}&job_id=${prefix}_${runId}`)
    .catch(function (error) {
      return error
    })
  return response
}
const getRunDetailTableData = async (baseUrl, data, reqPath) => {
  // try {

  // } catch (error) {

  // }
  const res = await axios.post(`${baseUrl}/germline/${reqPath}`, data).catch(function (error) {
    return error
  })
  return res
}
const getFilterSession = async (data, baseUrl) => {
  const response = await axios
    .post(`${baseUrl}/result/service/getfiltersession`, data, {
      headers: {
        contentType: 'multipart/form-data',
      },
    })
    .catch(function (error) {
      return error
    })
  return response
}
const updateRunComment = (comment, runId) => {
  axios.patch(`/runs/${runId}`, {
    comments: comment,
  })
}
const updateRunPhysician = (refferingPhysState, runId, sampleId) => {
  axios.patch(`/runs/${runId}`, {
    samples: [
      {
        id: sampleId,
        physicians: refferingPhysState,
      },
    ],
  })
}
const updateRunAnalyst = (analysts, runId, sampleId) => {
  axios.patch(`/runs/${runId}`, {
    samples: [
      {
        id: sampleId,
        analysts: analysts,
      },
    ],
  })
}
const updateRunPhenotypes = (phenotypes, runId, sampleId) => {
  axios.patch(`/runs/${runId}`, {
    samples: [
      {
        id: sampleId,
        phenotypes: phenotypes,
      },
    ],
  })
}

const createRun = async (data) => {
  const response = await axios.post(`/runs`, data).catch(function (error) {
    return error
  })
  return response
}

const startRun = async (runId) => {
  const response = await axios.get(`/runs/${runId}/start`)
  return response.data
}

const deleteRun = async (runId) => {
  const response = await axios.delete(`/runs/${runId}`)
  return response.data
}

const getTinyRuns = async (id) => {
  const res = await axios.get(`/tinyruns?id=${id}`)
  return res
}
const saveGeneList = async (data) => {
  const res = await axios.post(`https://gennext-edge.farplane.dev/api/v1/geneslists`, data)
  // const res = await axios.post(`/geneslists`,data)
  return res
}
const deleteGenesList = async (data) => {
  console.log(data)
  const res = await axios
    .delete(`https://gennext-edge.farplane.dev/api/v1/geneslists`, {
      data: {
        genesListTitle: data,
      },
    })
    .catch(function (error) {
      console.log(error)
    })
  return res
}

const RunsService = {
  updateRunIsSolved,
  updateRunComment,
  updateRunPhysician,
  updateRunAnalyst,
  updateRunClinicalDiagnosis,
  updateRunSpecimenSite,
  updateRunSpecimenType,
  getRunDetail,
  getGenesList,
  getGenesListSingle,
  getAllRuns,
  createRun,
  getFilterInfo,
  getRunDetailTableData,
  startRun,
  getTinyRuns,
  runFilterGeneration,
  updateRunPhenotypes,
  deleteGenesList,
  createFilterSession,
  annotateFilter,
  checkFilterStatusCravat,
  getFilterSession,
  getUserProcessTypes,
  deleteRun,
  saveGeneList,
}

export default RunsService
