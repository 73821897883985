import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import somaticService from '../../service/somatic';
import RunsService from '../../service/runs';
import { PageTitle } from '../../_metronic/layout/core'

const loadIgv = async () => {
    const igvModule = await import('igv/dist/igv.esm');
    return igvModule.default || igvModule;
};

const IGVPage = () => {
    const { igvParams } = useParams(); // 📌 URL’den IGV koordinatlarını al
    const container = useRef(null);
    const runId = igvParams.split('&')[0];
    const locus = igvParams.split('&')[1];

    const [browser, setBrowser] = useState(null);
    const [igv, setIgv] = useState(null);
    const [loading, setLoading] = useState(true);
    const [runDetails, setRunDetails] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 460); // 100px padding
    console.log(window.innerHeight)
    useEffect(() => {
        const fetchIgv = async () => {
            const igvInstance = await loadIgv();
            setIgv(igvInstance);
        };
        fetchIgv();
    }, []);

    useEffect(() => {
        if (igv && !browser) {
            createBrowser();
        }
    }, [igv]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 460); // Ekran yüksekliğini güncelle
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const createBrowser = async () => {
        if (!igv || browser) return;

        setLoading(true);
        const runDetails = await RunsService.getRunDetail(runId);
        setRunDetails(runDetails.data);

        const igvOptions = {
            reference: {
                id: 'hg38',
                name: 'Human (GRCh38/hg38)',
                fastaURL: 'https://igv.org/genomes/data/hg38/hg38.fa',
                indexURL: 'https://igv.org/genomes/data/hg38/hg38.fa.fai',
            },
            locus: locus,
            tracks: [
                {
                    type: 'alignment',
                    format: 'bam',
                    oauthToken: JSON.parse(localStorage.getItem('user')).token,
                    url: `https://${runDetails?.data?.onNode}.node.gennext.bio/api/v0/files/alignment/${runId}?type=bam&sampleName=${runDetails?.data?.samples[0]?.sampleId}`,
                    indexURL: `https://${runDetails?.data?.onNode}.node.gennext.bio/api/v0/files/alignment/${runId}/index?type=bam&sampleName=${runDetails?.data?.samples[0]?.sampleId}`,
                    name: runDetails?.data?.samples[0]?.sampleId,
                    height: windowHeight // Dinamik yükseklik
                },
                {
                    name: "MANE Transcripts",
                    format: "bigbed",
                    url: "https://hgdownload.soe.ucsc.edu/gbdb/hg38/mane/mane.bb",
                    visibilityWindow: -1
                },
                {
                    name: "Refseq Select",
                    format: "refgene",
                    url: "https://hgdownload.soe.ucsc.edu/goldenPath/hg38/database/ncbiRefSeqSelect.txt.gz"
                },
            ]
        };

        const newBrowser = await igv.createBrowser(container.current, igvOptions);
        setBrowser(newBrowser);
        setLoading(false);
    };

    return (
        <div className='card '>
            <PageTitle breadcrumbs={[]}>
                {runDetails?.id !== undefined ? runDetails.samples[0].sampleId + ' - IGV' : 'IGV'}
            </PageTitle>
            {loading && <div className='loading-spinner'><p>Loading IGV...</p></div>}
            <div ref={container}className='mx-10 mt-5'></div>
        </div>
    );
};

export default IGVPage;
