import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import prsData from '../../static/prsData'
import prsData2 from '../../static/PrsData2.json'
import TraidChart from './TraidChart'
import LinkList from './LinkList'

const TraitDetailModal = () => {
  const [selectedPrsData, setSelectedPrsData] = useState(prsData)
  const [loading, setLoading] = useState(false) // Loading state
  const [isNonNull, setIsNonNull] = useState(false) // Chart type state
  console.log(prsData2)
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [selectedPrsData])

  const handleChartRendered = (isRendered) => {
    if (isRendered) {
      setLoading(false) // Eğer grafik çizildiyse yükleme durumunu kaldır
    }
  }
  function humanReadableKey(key) {
    // Camel case -> keyNameExample -> Key Name Example
    const camelCaseToSpaces = key.replace(/([a-z0-9])([A-Z])/g, '$1 $2')

    // Snake case -> key_name_example -> Key Name Example
    const snakeCaseToSpaces = camelCaseToSpaces.replace(/_/g, ' ')

    // İlk harfi büyük yapma
    return snakeCaseToSpaces
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }
  return (
    <div className='modal fade bd-example-modal-lg ' tabIndex={-1} id='TraitDetailModal'>
      <div className='modal-dialog' style={{maxWidth: '90%'}}>
        <div className='modal-content'>
          <div className='modal-header justify-content-end' style={{padding: '0px 20px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body' style={{paddingBottom: 0}}>
            <div className='d-flex justify-content-between align-items-center mb-5'>
              <h4>{selectedPrsData.metadata.trait_mapped}</h4>
              <div className='pgs-meta'>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '10px',
                    padding: '10px',
                    paddingTop: '0',
                  }}
                >
                  {Object.entries(selectedPrsData.metadata)
                    .filter(
                      ([key]) =>
                        key !== 'txt' &&
                        key !== 'HmPOS_build' &&
                        key !== 'HmPOS_match_pos' &&
                        key !== 'HmPOS_match_chr'
                    ) // 'txt' anahtarını filtreliyoruz
                    .map(([key, value]) => (
                      <div
                        key={key}
                        style={{border: '1px solid #ccc', padding: '10px', textAlign: 'center'}}
                      >
                        <strong>{humanReadableKey(key)}</strong>:{' '}
                        {key === 'citation' ? (
                          <a
                            href={`https://doi.org/${value ? value.split('doi:')[1] : ''}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {value}
                          </a>
                        ) : (
                          value
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <h4>
                <span
                  className={`badge badge-${
                    selectedPrsData.PRS < 1
                      ? 'danger'
                      : selectedPrsData.PRS < 1.3
                      ? 'warning'
                      : 'success'
                  } fs-4`}
                >
                  PRS : {selectedPrsData.prs.toFixed(3)}
                </span>
              </h4>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                {/* Switch button */}
                <div className='form-check form-switch mb-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='flexSwitchCheckDefault'
                    checked={isNonNull}
                    onChange={() => setIsNonNull(!isNonNull)} // Switch toggle
                  />
                  <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                    Sorted by Non Null Effect
                  </label>
                </div>
                {loading ? (
                  <div style={{textAlign: 'center', padding: '50px'}}>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <TraidChart
                      data={selectedPrsData}
                      type={'scatter'}
                      nonNull={isNonNull}
                      prsScore={selectedPrsData.prs.toFixed(3)}
                      onChartRendered={handleChartRendered} // Chart rendered kontrol
                    />
                  </>
                )}
              </div>
              <div className='col-md-5'>
                <LinkList
                  data={selectedPrsData.matched_entries}
                  calcRiskScore={selectedPrsData.calc_risk_score}
                  aleles={selectedPrsData.alleles}
                />
              </div>
            </div>
          </div>
          <div
            className='modal-footer'
            style={{padding: '2px 20px', paddingTop: '10px', paddingBottom: '10px'}}
          >
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => {
                setLoading(true) // Loading state true
                setSelectedPrsData(prsData2)
              }}
            >
              Next <i className='fa fa-chevron-right'></i>
            </button>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TraitDetailModal
