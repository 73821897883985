import React from 'react'

export default function FileInput({
  label,
  onchange,
  multiple,
  required = false,
  accept = '.fq.gz,.fastq.gz,.gz',
  id,
}) {
  if (
    label === 'Upload Regions File' ||
    label === 'Upload Regions File (DNA)' ||
    label === 'Upload Regions File (RNA)'
  ) {
    accept = '.bed'
  }
  if (
    label === 'Upload BAM File' ||
    label === 'Upload BAM File (DNA)' ||
    label === 'Upload BAM File (RNA)'
  ) {
    accept = '.bam'
  }
  if (label === 'Upload VCF') {
    accept = '.vcf,.vcf.gz'
  }
  return (
    <div className=' col-md-6 p-3 '>
      <div className='col-md-12'>
        <label className='text'>
          <h5>{label} </h5>
        </label>
      </div>
      <div className='col-md-12'>
        <input
          className='form-control'
          type='file'
          multiple={multiple}
          onChange={onchange}
          name={'file ' + Math.random(1000)}
          accept={accept}
          required={false}
        />
      </div>
    </div>
  )
}
