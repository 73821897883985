import axios from './index'

const getSelectedOncokbDatasSNV = async (genomicLocation) => {
  const res = await axios.get(
    `/annotate/somatic/byGenomicChange?genomicLocation=${genomicLocation}&referenceGenome=GRCh38`
  )
  return res
}
const getSelectedOncokbDatasSVandFusion = async (
  geneNameA,
  geneNameB,
  structuralVariantType,
  isFusion
) => {
  const res = await axios.get(
    `/annotate/somatic/structuralVariants?hugoSymbolA=${geneNameA}&hugoSymbolB=${geneNameB}&structuralVariantType=${structuralVariantType}&isFunctionalFusion=${isFusion}&referenceGenome=GRCh38`
  )
  return res
}
const getSomaticVariants = async (runId, sampleId, datatype, isAdvanced) => {
  const res = await axios.post(`/variants/somatic`, {
    runId,
    sampleId,
    datatype,
    isAdvanced,
  })
  return res
}
const getCosmic = async (cosvId) => {
  const res = await axios.get(`/annotate/nih/cosmic/search?terms=${cosvId}&cf=LegacyMutationID&df=`)
  return res
}
const getSomaticDetail = async (sampleId) => {
  const res = await axios.get(`/samples/${sampleId}`)
  return res
}
const getPMDetails = async (pmIds) => {
  const res = await axios.post(`/annotate/nih/pubmed/articles`, {
    pmids: pmIds,
  })
  return res
}
const getAFDetails = async (data) => {
  const res = await axios.get(
    `/annotate/allelefreq?chrom=${data.chr.split('r')[1]}&pos=${data.pos}&ref=${data.ref}&alt=${
      data.alt
    }&refver=${data.refver}&isIndel=true`
  )
  return res
}
const getIsppData = async (data) => {
  try {
    const res = await axios.get(
      `/annotate/dbnsfp?chrom=${data.chr.split('r')[1]}&pos=${data.pos}&ref=${data.ref}&alt=${
        data.alt
      }&isIndel=false`
    )
    return res
  } catch (error) {
    return error
  }
}
const searchAFB = async (data, variant = false) => {
  try {
    const res = await axios.post(`afd/query${variant ? '/variant' : ''}`, data)
    return res
  } catch (error) {
    return error
  }
}
const getClinicCosmic = async (data, ) => {
  try {
    const res = await axios.post(`annotate/cosmic`, data)
    return res
  } catch (error) {
    return error
  }
}

const somaticService = {
  getSelectedOncokbDatasSNV,
  getSelectedOncokbDatasSVandFusion,
  getSomaticVariants,
  getClinicCosmic,
  searchAFB,
  getAFDetails,
  getIsppData,
  getPMDetails,
  getCosmic,
  getSomaticDetail,
}

export default somaticService
