import WgsFormFiles from '../../NewRun/Forms/WgsFormFiles'
import WesFormFiles from '../../NewRun/Forms/WesFormFiles'
import TpsFormFiles from '../../NewRun/Forms/TpsFormFiles'
import RnaseqFormFiles from '../../NewRun/Forms/RnaseqFormFiles'
import SomTestRnaFormFiles from '../../NewRun/Forms/SomTestRnaFormFiles'
import SomTestTumorFormFiles from '../../NewRun/Forms/SomTestTumorFormFiles'
import SomTestTumorRnaFormFiles from '../../NewRun/Forms/SomTestTumorRnaFormFiles'
import PrsFormFiles from '../../NewRun/Forms/PrsFormFiles'

const SampleFilesStep = ({
  selectedProcessType,
  sampleData,
  fileType,
  setSampleData,
  enrichmentOrRegion,
}) => {
  return (
    <div className='w-100'>
      <h2 className='fw-bolder text-dark'>Sample Files</h2>
      <div className='text-gray-400 fw-bold fs-6'>
        If you need more info, please check out
        <a href='/dashboard' className='link-primary fw-bolder'>
          {' '}
          Help Page
        </a>
        .
      </div>

      {selectedProcessType === 'wgs' && (
        <WgsFormFiles
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'wes' && (
        <WesFormFiles
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
          enrichmentOrRegion={enrichmentOrRegion}
        />
      )}
      {selectedProcessType === 'tps' && (
        <TpsFormFiles
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'rnaseq' && (
        <RnaseqFormFiles
          sampleData={sampleData}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'som-test-rna' && (
        <SomTestRnaFormFiles
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          enrichmentOrRegion={enrichmentOrRegion}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'som-test-tumor' && (
        <SomTestTumorFormFiles
          sampleData={sampleData}
          fileType={fileType}
          enrichmentOrRegion={enrichmentOrRegion}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'som-test-tumor-rna' && (
        <SomTestTumorRnaFormFiles
          sampleData={sampleData}
          fileType={fileType}
          enrichmentOrRegion={enrichmentOrRegion}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'prs' && (
        <PrsFormFiles
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
        />
      )}
    </div>
  )
}

export {SampleFilesStep}
