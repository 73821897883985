/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {CustomCheck} from '../../CustomCheck'

const DuoFamilyFilter = ({
  tinyRuns,
  sampleId,
  setFamilyFilterDatas,
  familyFilterDatas,
}) => {
  const [caseChecked, setCaseChecked] = useState(familyFilterDatas.filterDetails.caseCheckStatus)
  // familyFilterDatas.filterDetails.caseCheckStatus = caseChecked
  return (
    <div className='familyFilterBody my-5'>
      <div className='familyFilterParents col-md-6'>
        <div className='familyItem d-flex align-items-center my-2'>
          <label className='w-55'>Proband :</label>
          <span className='w-100'> {sampleId}</span>
          <div
            className='form-check form-switch form-switch-sm  form-check-custom form-check-solid invisible '
            style={{marginLeft: '5px'}}
          >
            <CustomCheck
              checkType={'case'}
              setFamilyFilterDatas={setFamilyFilterDatas}
              familyFilterDatas={familyFilterDatas}
              checkStatus={caseChecked}
              setCheckStatus={setCaseChecked}
            />
          </div>
        </div>
        <div className='familyItem d-flex align-items-center my-2'>
          <label className='w-55'>Case :</label>
          <select
            className='form-select  form-select-solid form-select-sm  w-100'
            onChange={(e) => {
              familyFilterDatas.filterDetails.caseSelect = e.target.value
              setFamilyFilterDatas(familyFilterDatas)
            }}
          >
            <option>Select</option>
            {tinyRuns?.map((run, key) => (
              <option
                key={key}
                selected={familyFilterDatas.filterDetails.caseSelect === run?.samples[0].id}
                value={run?.samples[0].id}
              >
                {run?.samples[0]?.sampleId}
              </option>
            ))}
          </select>
          <div
            className='form-check form-switch form-switch-sm  form-check-custom form-check-solid'
            style={{marginLeft: '5px'}}
          >
            <CustomCheck
              checkType={'case'}
              setFamilyFilterDatas={setFamilyFilterDatas}
              familyFilterDatas={familyFilterDatas}
              checkStatus={caseChecked}
              setCheckStatus={setCaseChecked}
            />
          </div>
        </div>
      </div>
      <div className='familyFilterCheckboxes '>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              onChange={(e) => {
                familyFilterDatas.filterDetails.dominant = e.target.checked
                setFamilyFilterDatas(familyFilterDatas)
              }}
              defaultChecked={familyFilterDatas.filterDetails.dominant}
            />
            <span
              className='fw-bold ps-2 fs-6'
              data-tooltip-id='my-tooltip'
              data-tooltip-content='  Returns heterozygous variants of the proband.'
            >
              Dominant
            </span>
          </label>
        </div>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              onChange={(e) => {
                familyFilterDatas.filterDetails.recessive = e.target.checked
                setFamilyFilterDatas(familyFilterDatas)
              }}
              defaultChecked={familyFilterDatas.filterDetails.recessive}
            />
            <span
              className='fw-bold ps-2 fs-6'
              data-tooltip-id='my-tooltip'
              data-tooltip-content='  Returns homozygous, hemizygous variants of the proband.'
            >
              Recessive
            </span>
          </label>
        </div>
      </div>
      {/* <div className='familyFilterCheckboxes '>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <span className='fw-bold ps-2 fs-6'>In Filter : 0</span>
          </label>
        </div>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <span className='fw-bold ps-2 fs-6 text-success'>Include : 0</span>
          </label>
        </div>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <span className='fw-bold ps-2 fs-6 text-danger'>Exclude : 0</span>
          </label>
        </div>
      </div> */}
      <Tooltip id='my-tooltip' />
    </div>

    //  Card End
  )
}

export {DuoFamilyFilter}
