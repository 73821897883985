/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  roh: any
  totalReads: any
  meanCoverage: any
  medianCoverage: any
  gender: any
  runId: any
  contamination: any
  currentNode: any
  dataType: any
  sampleId: any
  coverage: any
  readLenght: any
}

const QCSummary: React.FC<Props> = ({currentNode, sampleId, runId, coverage, dataType}) => {
  let coverageItems = Object.keys(coverage)
    .filter(
      (key) =>
        !key.includes('meanCoverage') &&
        !key.includes('gender') &&
        !key.includes('contamination') &&
        !key.includes('medianCoverage') &&
        !key.includes('avgReadLength') &&
        !key.includes('roh') &&
        !key.includes('dataSourceType') &&
        !key.includes('totalReads')
    )
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: coverage[key],
      })
    }, {})
  const intl = useIntl()
  const checkData = (data) => {
    if (data) {
      return data
    } else if (data === 0) {
      return 0.0
    } else {
      return 'N/A'
    }
  }
  return (
    //  Card Start
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'QCSUMMARY'})}</span>
        </h3>
      </div>
      <div className='card-body py-0 px-2 '>
        <div className='timeline-label my-4'>
          <div className='timeline-item mb-2'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Mean Covarage :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              {coverage.meanCoverage === 'N/A' || coverage.meanCoverage === null
                ? 'N/A'
                : coverage.meanCoverage?.toFixed(2)}
            </div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item mb-2'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Median Covarage :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              {coverage.medianCoverage === 'N/A' || coverage.totalReads === null
                ? 'N/A'
                : coverage.medianCoverage?.toFixed(2)}
            </div>
          </div>
          {/* <div className='meanCovaragesItem d-flex justify-content-between text-center '>
            {coverage &&
              Object.keys(coverageItems)?.map((keyName, index) => (
                <div className='meanCovaragesItem p-2 d-flex flex-column border w-100' key={index}>
                  <label className='fs-8'> {keyName}</label>
                  <span className='meanCoverageValue'>
                    {coverageItems[keyName]?.toFixed(2)}
                    {coverageItems[keyName] === null ? '0%' : '%'}{' '}
                  </span>
                </div>
              ))}

         
          </div> */}
          <div className='meanCovaragesItem row row-cols-5 text-center m-0'>
            {coverage &&
              Object.keys(coverageItems)?.map((keyName, index) => (
                <div className='p-2 d-flex flex-column border' key={index}>
                  <label className='fs-8'>{keyName}</label>
                  <span className='meanCoverageValue'>
                    {coverageItems[keyName] !== null
                      ? `${coverageItems[keyName]?.toFixed(2)}%`
                      : '0%'}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Total Read :
            </div>

            <div className='fw-mormal timeline-content text-muted ps-3'>
              {coverage.totalReads === 'N/A' || coverage.totalReads === null
                ? 'N/A'
                : coverage.totalReads?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Average Read Length :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              {coverage.avgReadLength === 'N/A' || coverage.avgReadLength === null
                ? 'N/A'
                : coverage.avgReadLength?.toFixed(2)}
            </div>
          </div>
        </div>
        {/* <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              ROH Summary :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              {coverage.roh === 'N/A' || coverage.roh === null ? 'N/A' : coverage.roh?.toFixed(2)}
            </div>
          </div>
        </div> */}
        {/* <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>Gender :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              {coverage.gender === null ? 'N/A' : coverage.gender}
            </div>
          </div>
        </div> */}
       
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Contamination :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span className=''>
                {checkData(coverage.contamination) === 'N/A'
                  ? 'N/A'
                  : coverage.contamination?.toFixed(2) + '%'}
              </span>
              <span className='mx-2'>
                <i
                  className='fa fa-info-circle'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-html='The contamination value reported represents the FREEMIX score from VerifyBamID,<br> which estimates the proportion of DNA in your sample that may come from a foreign source.<br> This percentage indicates how much of the sample potentially contains DNA from a different individual.'
                  data-tooltip-place='bottom'
                ></i>
                {checkData(coverage.contamination) === 'N/A' ? (
                  ''
                ) : coverage.contamination > 2 ? (
                  <i
                    className='fa fa-exclamation-circle mx-1 text-warning'
                    data-tooltip-id='my-tooltip'
                    data-tooltip-html={`
                          High contamination detected (>2%). This sample shows signs of significant contamination which may adversely affect variant calling and downstream analyses.<br>
                          When contamination exceeds 2%, it's recommended to examine the data carefully, potentially re-sequence the sample, or exclude it from analysis.<br>
                          For low-coverage data, check the difference between FREELK1 and FREELK0 values in the QC report - a larger difference provides stronger evidence of contamination,<br>
                          especially when there are a substantial number of bases with depth 2 or higher.
                          `}
                    data-tooltip-place='bottom'
                  ></i>
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end px-0'>
        <a
          href={`${
            dataType === 'vcf'
              ? ''
              : `https://${currentNode}.node.${process.env.REACT_APP_NODE_URL}/quality/${runId}/report?sampleId=${sampleId}`
          }`}
          target='_blank'
          rel='noreferrer'
          className={`btn btn-primary ${dataType === 'vcf' ? 'disabled' : ''}`}
        >
          {' '}
          QC Report<i className='fa fa-arrow-down ml-5'></i>
        </a>
      </div>
    </div>
    //  Card End
  )
}

export {QCSummary}
