import React from 'react'
import Chart from 'react-apexcharts'

const TraidChart = ({data, type, nonNull, prsScore}) => {
  let chartData = data.matched_entries.map((item, index) => {
    const value = data.calc_risk_score[index]
    return data.alleles[index] === 0 || data.alleles[index] === undefined ? 0.0 : value
  })

  if (nonNull) {
    chartData = chartData
      .filter((value) => value !== 0 && value !== undefined)
      .sort((a, b) => a - b)
  }

  const minValue = Math.min(...chartData)
  const maxValue = Math.max(...chartData)
  const yAxisMin = minValue - 0.05
  const yAxisMax = maxValue + 0.05
  const series = [
    {
      name: 'βz values',
      data: chartData.map((value, index) => ({x: index + 1, y: value})),
    },
  ]
  const options = {
    chart: {
      id: `${type}-chart`,
    },
    xaxis: {
      categories: Array.from({length: chartData.length}, (_, i) => i + 1),
      title: {
        text: nonNull ? 'variant sorted by non-null effect' : '',
      },
      min: 0,
      max: chartData.length + 1, 
      tickAmount: 10, // Belirli aralıklarla etiketleme yapar
      labels: {
        formatter: function (val) {
          return Math.round(val) // Ondalık olmadan yuvarlanmış sayıları gösterir
        },
        style: {
          fontSize: '10px', // İstediğiniz font boyutunu belirleyebilirsiniz
        },
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      tickAmount: 5, // Y ekseni için toplam 5 kademeli çizgi gösterir
      decimalsInFloat: 2, // Y ekseni değerlerinin noktadan sonra iki basamak gösterilmesini sağlar
      title: {
        text: 'βz where PRS = exp(Σ βi z)',
      },
    },
    markers: {
      size: 6,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    title: {
      text: `breast carcinoma (PGP#4), PRS ${prsScore}`,
      align: 'center',
      style: {
        fontSize: '16px',
        color: '#263238',
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0, // Y ekseninde 0 noktasına yatay bir çizgi ekler
          borderColor: '#71c6fd',
          strokeDashArray: 0,
          label: {
            borderColor: '#71c6fd',
            style: {
              color: '#fff',
              background: '#71c6fd',
            },
          },
        },
      ],
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const value = series[seriesIndex][dataPointIndex]
        var indexx
        const variantIndex = data.calc_risk_score.filter((item, index) => {
          if (item === value) {
            indexx = index
          }
        })
        console.log(indexx)
        const variantInfo = data.matched_entries[indexx]
        const chromosomeInfo = `Chr${variantInfo[1][0]}`
        const position = variantInfo[1][1]
        const rsID = variantInfo[0][0]
        const change = `${variantInfo[1][3]}>${variantInfo[1][2]}`

        return (
          '<div style="padding: 5px; background: #1e3d7c; color: #fff; border-radius: 5px;">' +
          `<strong>${value.toFixed(3)}</strong><br/>` +
          `<span>${chromosomeInfo}</span>.${position}:<span>${change}</span><br/>` +
          `<span style="color: #999;">${rsID}</span>` +
          '</div>'
        )
      },
    },
  }

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Chart options={options} series={series} type={type} width='100%' height='400' />
    </div>
  )
}

export default TraidChart
