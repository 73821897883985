import { Column, Filter, FilterArguments, FilterCallback, OperatorType, OperatorString, SearchTerm } from "slickgrid-react";

export class CustomDropdownFilter implements Filter {
    private _filterArgs!: FilterArguments;
    private _callback!: FilterCallback;
    private _value: SearchTerm[] = [];
    private _options: string[] = [];
    private _dropdownContainer!: HTMLDivElement;
    private _button!: HTMLButtonElement;
    private _searchInput!: HTMLInputElement;
    private _checkboxList!: HTMLDivElement;
    private _icon!: HTMLDivElement;
    private _placeholder!: HTMLSpanElement;

    columnDef!: Column;
    grid!: any;
    callback!: FilterCallback;
    operator: OperatorType | OperatorString = OperatorType.inCollection;

    init(args: FilterArguments) {
        this._filterArgs = args;
        this.columnDef = args.columnDef;
        this.grid = args.grid;
        this._callback = args.callback;
        this._options = args.columnDef.filter?.collection || [];

        // ✅ Buton oluştur
        this._button = document.createElement("button");
        this._button.className = "ms-choice"; // ✅ Doğru class verildi

        // ✅ Placeholder (🔎︎ ikonu)
        this._placeholder = document.createElement("span");
        this._placeholder.className = "ms-placeholder";
        this._placeholder.title = "";
        this._placeholder.textContent = "🔎︎";

        // ✅ Açılır ikon
        this._icon = document.createElement("div");
        this._icon.className = "ms-icon ms-icon-caret";
        this._icon.style.background = "#444";

        // ✅ Buton içeriğini güncelle
        this.updateButtonText();
        this._button.appendChild(this._icon); // ✅ İkonu en sona ekle

        this._dropdownContainer = document.createElement("div");
        this._dropdownContainer.style.position = "absolute";
        this._dropdownContainer.style.display = "none";
        this._dropdownContainer.style.background = "#fff";
        this._dropdownContainer.style.border = "1px solid #ccc";
        this._dropdownContainer.style.padding = "10px 15px";
        this._dropdownContainer.style.boxShadow = "0px 4px 6px rgba(0,0,0,0.1)";
        this._dropdownContainer.style.zIndex = "99999999";
        this._dropdownContainer.style.width = "200px";
        this._dropdownContainer.style.maxHeight = "250px";
        this._dropdownContainer.style.overflowY = "auto";

        this._searchInput = document.createElement("input");
        this._searchInput.type = "text";
        this._searchInput.placeholder = "Search...";
        this._searchInput.style.width = "100%";
        this._searchInput.style.padding = "5px";
        this._searchInput.style.marginBottom = "5px";
        this._searchInput.style.border = "1px solid #ccc";

        this._checkboxList = document.createElement("div");
        this.updateCheckboxList();

        this._button.addEventListener("click", (event) => {
            event.stopPropagation();
            const appPage = document.querySelector(".app-page");
            if (appPage && !appPage.contains(this._dropdownContainer)) {
                appPage.appendChild(this._dropdownContainer);
            }

            this._dropdownContainer.style.position = "absolute";
            this._dropdownContainer.style.top = `${this._button.getBoundingClientRect().bottom + window.scrollY}px`;
            this._dropdownContainer.style.left = `${this._button.getBoundingClientRect().left}px`;
            this._dropdownContainer.style.display = this._dropdownContainer.style.display === "none" ? "block" : "none";
        });

        this._searchInput.addEventListener("input", () => {
            this.updateCheckboxList(this._searchInput.value);
        });

        this._dropdownContainer.appendChild(this._searchInput);
        this._dropdownContainer.appendChild(this._checkboxList);

        if (args.filterContainerElm) {
            args.filterContainerElm.appendChild(this._button);
        }

        document.addEventListener("click", (event) => {
            if (!this._dropdownContainer.contains(event.target as Node) && event.target !== this._button) {
                this._dropdownContainer.style.display = "none";
            }
        });

        // ✅ **Başlangıçta buton metnini güncelle**
        this.updateButtonText();
    }

    updateCheckboxList(filterText: string = "") {
        this._checkboxList.innerHTML = "";
        this._options
            .filter(option => option.toLowerCase().includes(filterText.toLowerCase()))
            .forEach(option => {
                const label = document.createElement("label");
                label.style.display = "flex";
                label.style.alignItems = "center";
                label.style.gap = "5px";
                label.style.marginBottom = "5px";

                const checkbox = document.createElement("input");
                checkbox.type = "checkbox";
                checkbox.value = option;
                checkbox.style.marginRight = "5px";

                checkbox.checked = this._value.includes(option);
                checkbox.addEventListener("change", () => {
                    if (checkbox.checked) {
                        this._value.push(option);
                    } else {
                        this._value = this._value.filter(val => val !== option);
                    }
                    this.applyFilter();
                    this.updateButtonText();
                });

                label.appendChild(checkbox);
                label.appendChild(document.createTextNode(option));
                this._checkboxList.appendChild(label);
            });
    }

    updateButtonText() {
        const selectedCount = this._value.length;
        const totalCount = this._options.length;

        // ✅ Buton içeriğini temizle
        this._button.innerHTML = "";

        if (selectedCount === 0) {
            // **Hiçbir şey seçilmemişse, sadece 🔎︎ ikonlu `ms-placeholder` ekle**
            this._button.appendChild(this._placeholder);
        } else {
            // **Seçiliyse, "1 of 20 selected..." gibi yazı ekle**
            const buttonText = document.createElement("span");
            buttonText.textContent = `${selectedCount} of ${totalCount} selected...`;
            this._button.appendChild(buttonText);
        }

        // ✅ Butona açılır ikon ekle
        this._button.appendChild(this._icon);
    }

    applyFilter() {
        console.log("Filter applied with values:", this._value);

        this._callback(undefined, {
            columnDef: this.columnDef,
            operator: this.operator,
            searchTerms: this._value,
            shouldTriggerQuery: true,
        });

        // const gridData = this.grid.getData();

        // for (let index = 0; index < gridData.length; index++) {
        //     let cellValue = gridData[index][this.columnDef.field];

        //     if (!cellValue) continue; // ✅ Eğer hücre boşsa atla

        //     let valuesToCheck: string[];

        //     // ✅ Eğer hücre bir array ise düzleştir ve string olarak işle
        //     if (Array.isArray(cellValue)) {
        //         valuesToCheck = cellValue.flatMap(val =>
        //             String(val).split(";").map(v => v.trim().toLowerCase())
        //         );
        //     } else {
        //         // ✅ Eğer hücre bir string ise `;` ile bölerek işlem yap
        //         valuesToCheck = String(cellValue).toLowerCase().split(";").map(v => v.trim());
        //     }

        //     // ✅ Tüm seçili değerler için kontrol yap
        //     const isMatch = this._value.length > 0 && this._value.every(selectedValue =>
        //         valuesToCheck.some(val => val.includes(String(selectedValue).toLowerCase()))
        //     );

        //     // ✅ Satırları filtreleme işlemi yap
        //     this.grid.getDataItem(index).__filteredOut = !isMatch;
        // }

        // this.grid.invalidate(); // ✅ Grid'i güncelle
        // this.grid.render(); // ✅ Grid'i tekrar çiz
    }


    clear() {
        this._value = [];
        this.applyFilter();
        this.updateButtonText();
    }

    destroy() {
        this.clear();
    }

    getValues(): SearchTerm[] {
        return this._value;
    }

    setValues(values: SearchTerm | SearchTerm[], operator?: OperatorType | OperatorString) {
        if (values === null || values === undefined) {
            this._value = [];
        } else {
            this._value = Array.isArray(values) ? values.map(String) : [String(values)];
        }
        this.updateCheckboxList();
        this.updateButtonText();
    }
}
