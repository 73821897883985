import React, {useEffect} from 'react'
import FileInput from '../FileInput'

const WesForm = ({
  sampleData,
  setSampleData,
  selectedProcessType,
  fileType,
  validateSampleId,
  enrichmentKits,
  setEnrichmentKit,
  enrichmentOrRegion,
  setEnrichmentOrRegion,
  userProcessTypes,
  enrichmentKit,
}) => {
  useEffect(() => {
    if (sampleData.length === 0) {
      setSampleData([
        {
          id: '',
          name: '',
          // fqR1: '',
          // fqR2: '',
          files: {},
          regionFile: null,
        },
      ])
    }
  }, [sampleData])

  return (
    <div>
      <button
        className='btn btn-primary mb-1'
        disabled={true}
        onClick={() =>
          setSampleData((prev) => [
            ...prev,
            {
              id: '',
              name: '',
              // fqR1: '',
              // fqR2: '',
              files: {},
              regionFile: null,
            },
          ])
        }
      >
        +
      </button>

      {sampleData.map((sample, index) => (
        <div className=' mt-2 p-2' key={index}>
          {index > 0 && (
            <button
              className='btn btn-primary mb-1 pull-right'
              onClick={() => {
                const cloned = [...sampleData]
                cloned.splice(index, 1)
                setSampleData(cloned)
              }}
            >
              X
            </button>
          )}
          <div className='row'>
            <div className='col-md-6'>
              <label className='form-label fs-6 fw-bold  my-0'>
                Sample ID{' '}
                <i
                  className='fa fa-info-circle '
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content='Sample ID’s accept alphanumeric characters, dots, hyphens, and underscores. They cannot start or end with a dot, hyphen, or underscore.'
                  data-tooltip-place={'right-start '}
                  style={{
                    marginRight: '5px',
                  }}
                ></i>
              </label>

              <input
                className='form-control mb-4'
                placeholder='Sample ID'
                value={sample.id}
                style={{
                  border: !validateSampleId(sampleData[index].id)
                    ? '1px solid red'
                    : '1px solid #03b821',
                }}
                required={false}
                onChange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].id = e.target.value
                  setSampleData(clonedSampleData)
                }}
              />
            </div>

            <div className='col-md-6'>
              <label className='form-label fs-6 fw-bold  my-0'>Sample Name </label>
              <input
                className='form-control mb-4'
                placeholder='Sample Name (Optional) '
                required={false}
                value={sample.name}
                onChange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].name = e.target.value
                  setSampleData(clonedSampleData)
                }}
              />
            </div>
          </div>

          {/* {fileType === 'fastq' && (
            <>
              <FileInput
                label={'Upload FASTQ R1 File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  console.log(clonedSampleData)
                  clonedSampleData[index].files.fqR1 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {fileType === 'bam' && (
            <>
              <FileInput
                label={'Upload BAM File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bam = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {fileType === 'vcf' && (
            <>
              <FileInput
                label={'Upload VCF'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.vcf = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )} */}
        </div>
      ))}
      <div className='d-flex mt-5 mb-5'>
        <label className='form-label fs-6 fw-bold  my-0'>Enrichment Kit</label>

        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input enrich-or-region mx-3'
            type='checkbox'
            value='enrichmentOrRegion'
            onChange={(e) => {
              if (e.target.checked) {
                setEnrichmentOrRegion('region')
              } else {
                setEnrichmentOrRegion('enrichment')
              }
            }}
            name='enrichmentOrRegion'
          />
        </div>
        <label className='form-label fs-6 fw-bold  my-0'>Region</label>
      </div>

      {enrichmentOrRegion === 'enrichment' && (
        <select
          className={`form-select mb-4 `}
          onChange={(e) => {
            const kit = enrichmentKits.find((k) => k.name === e.target.value)
            setEnrichmentKit(kit)
            const clonedSampleData = [...sampleData]
            clonedSampleData[0].enrichmentKit = e.target.value
            setSampleData(clonedSampleData)
          }}
          value={userProcessTypes[1].en}
        >
          <option disabled selected>
            Enrichment Kit
          </option>
          <option value={''}></option>
          {/* <option value={''}></option> */}
          {enrichmentKits?.map((item, index) => (
            <option key={index} value={item.name}>
              {item.company} - {item.title}
            </option>
          ))}
        </select>
      )}
      {enrichmentOrRegion === 'region' && (
        <div className='mt-5 d-flex'>
          <FileInput
            label={'Upload Regions File'}
            onchange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[0].files.regionsFile = e.target.files[0]
              setSampleData(clonedSampleData)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default WesForm
