import React, {useMemo, useState} from 'react'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../_metronic/helpers'
import {AdvancedFilterTable} from './AdvancedFilterTable'
import {useAdvencedFilters} from '../hooks'
import {useIntl} from 'react-intl'
import {Loader} from './Loader'
import {StatisticsWidget6} from '../_metronic/partials/widgets'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import RunsService from '../service/runs'

const RunsTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {
    clearAdvancedFilter,
    advancedFilterSubmitHandler,
    changeCheck,
    runsDatas,
    setFilters,
    changeCheckNode,
    filters,
    processTypes,
    userNodes,
    xFilters,
    tableIsLoading,
    getData,
  } = useAdvencedFilters()

  function replaceNullsWithNA(obj) {
    for (let key in obj) {
      if (obj[key] === null) {
        obj[key] = 'N/A'
      } else if (typeof obj[key] === 'object') {
        replaceNullsWithNA(obj[key])
      }
    }
  }

  const ExpandedComponent = ({data}) => {
    replaceNullsWithNA(data)

    if (data?.status !== 'Completed') {
      return (
        <div className='mt-5 mb-5 px-2'>
          {data?.status === 'Error' && (
            <h4 className='page-heading d-flex text-dark fw-bold fs-3 my-0 mt-5 flex-column justify-content-center'>
              This run has failed.{' '}
            </h4>
          )}
          {data?.status === 'Canceled' && (
            <h4 className='page-heading d-flex text-dark fw-bold fs-3 my-0 mt-5 flex-column justify-content-center'>
              Canceled by User Request.{' '}
            </h4>
          )}
          {data?.status === 'Running' && (
            <div>
              <h4 className='page-heading d-flex text-dark fw-bold fs-3 my-0 mt-5 flex-column justify-content-center'>
                This run is currently running click to see available process data.{' '}
              </h4>
              <br></br>
              <a className='btn btn-primary' href={`/run-detail/${data?.id}`}>
                <i className='fa fa-eye '></i> Details
              </a>
            </div>
          )}
          {data?.status === 'Submitted' && (
            <div>
              <StatisticsWidget6
                className='card-xl-stretch mb-xl-8'
                color='warning'
                title='Submitted'
                description=''
                progress='0%'
              />
            </div>
          )}
        </div>
      )
    }

    var html
    if (
      data?.processType === 'som-test-tumor-rna' ||
      data?.processType === 'som-test-tumor' ||
      data?.processType === 'som-test-rna'
    ) {
      html =
        ` 
  <h4 class="page-heading d-flex text-dark fw-bold fs-3 my-0 mt-5 flex-column justify-content-center"> Run Informations </h4><br>
  <div class='d-flex my-4 flex-row flex-wrap'>


    <div class='col-md-3 d-flex align-items-center my-3'>
      <span class='bullet bullet-vertical h-40px bg-primary'></span>
      <div class='flex-grow-1 mx-5'>
        <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
          Enrichment Kit
        </a>
        <span class='text-muted fw-semibold d-block'>` +
        data?.enrichmentKit +
        `</span>
      </div>
    </div>

    <div class='col-md-3 d-flex align-items-center my-3'>
      <span class='bullet bullet-vertical h-40px bg-primary'></span>
      <div class='flex-grow-1 mx-5'>
        <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
          Genome Assembly
        </a>
        <span class='text-muted fw-semibold d-block'>` +
        data?.genomeBuild +
        `</span>
      </div>
    </div>
    
    <div class='col-md-3 d-flex align-items-center my-3'>
      <span class='bullet bullet-vertical h-40px bg-primary'></span>
      <div class='flex-grow-1 mx-5'>
        <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
           Total Read Count
        </a>
        <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality['totalReads']
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        `</span>
      </div>
    </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
      Clinical Diagnosis
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].clinicalDiagnosis +
        `</span>
    </div>
  </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
      Specimen Type
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].specimenType +
        `</span>
    </div>
  </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
      Specimen Site
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].specimenSite +
        `</span>
    </div>
  </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
      Panel Type
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].panelType +
        `</span>
    </div>
  </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
      Received Date
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].receivedDate +
        `</span>
    </div>
  </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
      Collection Date
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].collectionDate +
        `</span>
    </div>
  </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
      <span class='bullet bullet-vertical h-40px bg-primary'></span>
      <div class='flex-grow-1 mx-5'>
        <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
           Contamination
        </a>
        <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality?.contamination +
        `</span>
      </div>
    </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
         30x
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality['30X'] +
        `</span>
    </div>
  </div>
  <div class='col-md-3 d-flex align-items-center my-3'>
  <span class='bullet bullet-vertical h-40px bg-primary'></span>
  <div class='flex-grow-1 mx-5'>
    <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
       50x
    </a>
    <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality['50X'] +
        `</span>
  </div>
</div>
  `
    } else {
      html =
        ` 
    <h4 class="page-heading d-flex text-dark fw-bold fs-3 my-0 mt-5 flex-column justify-content-center"> Run Informations </h4><br>
    <div class='d-flex my-4 flex-row flex-wrap'>


      <div class='col-md-3 d-flex align-items-center my-3'>
        <span class='bullet bullet-vertical h-40px bg-primary'></span>
        <div class='flex-grow-1 mx-5'>
          <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
            Enrichment Kit
          </a>
          <span class='text-muted fw-semibold d-block'>` +
        data?.enrichmentKit +
        `</span>
        </div>
      </div>

      <div class='col-md-3 d-flex align-items-center my-3'>
        <span class='bullet bullet-vertical h-40px bg-primary'></span>
        <div class='flex-grow-1 mx-5'>
          <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
            Genome Assembly
          </a>
          <span class='text-muted fw-semibold d-block'>` +
        data?.genomeBuild +
        `</span>
        </div>
      </div>

      
      <div class='col-md-3 d-flex align-items-center my-3'>
        <span class='bullet bullet-vertical h-40px bg-primary'></span>
        <div class='flex-grow-1 mx-5'>
          <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
             Total Read Count
          </a>
          <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality['totalReads']
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        `</span>
        </div>
      </div>
      <div class='col-md-3 d-flex align-items-center my-3'>
        <span class='bullet bullet-vertical h-40px bg-primary'></span>
        <div class='flex-grow-1 mx-5'>
          <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
             Contamination
          </a>
          <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality?.contamination +
        `</span>
        </div>
      </div>
 
      <div class='col-md-3 d-flex align-items-center my-3'>
      <span class='bullet bullet-vertical h-40px bg-primary'></span>
      <div class='flex-grow-1 mx-5'>
        <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
           30x
        </a>
        <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality['30X'] +
        `</span>
      </div>
    </div>
    <div class='col-md-3 d-flex align-items-center my-3'>
    <span class='bullet bullet-vertical h-40px bg-primary'></span>
    <div class='flex-grow-1 mx-5'>
      <a href='#' class='text-gray-800 text-hover-primary fw-bold fs-6'>
         50x
      </a>
      <span class='text-muted fw-semibold d-block'>` +
        data?.samples[0].sampleQuality['50X'] +
        `</span>
    </div>
  </div>
    `
    }
    if (
      data?.processType === 'Somatic Testing (DNA+RNA)' ||
      data?.processType === 'Somatic Testing (DNA)' ||
      data?.processType === 'Somatic Testing (RNA)'
    ) {
      html += `<div class="col-md-12 d-flex"> <a class='btn btn-primary my-5' href='/run-detail-somatic/${data?.id}'><i class="fa fa-eye "></i> Show Detail</a>     `
    } else {
      html += `<div class="col-md-12 d-flex"> <a class='btn btn-primary my-5' href='/run-detail/${data?.id}'><i class="fa fa-eye "></i> Show Detail</a>     `
    }
    html += `   </div>  `
    // html += ` <a class='btn btn-primary my-5 mx-2' href='/run-detail/${data?.id}'><i class="fa fa-trash "></i> Delete Run</a>   </div>  `

    return <div dangerouslySetInnerHTML={{__html: html}} className='card-body' />
  }

  const subHeaderComponent = useMemo(() => {
    return (
      <>
        <div className='card-toolbar py-4'>
          <button
            type='button'
            className='btn btn-primary '
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
            Advanced Filter
          </button>
          <AdvancedFilterTable
            processType={processTypes}
            xFilters={xFilters}
            changeCheck={changeCheck}
            userNodes={userNodes}
            changeCheckNode={changeCheckNode}
            advancedFilterSubmitHandler={advancedFilterSubmitHandler}
            setFilters={setFilters}
            filters={filters}
            clearAdvancedFilter={clearAdvancedFilter}
          />
        </div>
      </>
    )
  }, [
    filters,
    advancedFilterSubmitHandler,
    changeCheck,
    changeCheckNode,
    clearAdvancedFilter,
    userNodes,
    processTypes,
    setFilters,
    xFilters,
  ])
  let clickSortSelector = {
    sampleId: 0,
    processType: 0,
    status: 0,
    isSolved: 0,
    meanCoverage: 0,
  }
  const customSort = (rows, selector, direction) => {
    var rowsArray = selector.toString().split('.')
    var rowId = rowsArray[rowsArray.length - 1]
    var count = clickSortSelector[rowId] + 1
    if (count === 3) {
      count = 0
      clickSortSelector[rowId] = count
      return runsDatas
    } else {
      clickSortSelector[rowId] = count
      return rows.sort((rowA, rowB) => {
        const valueA = typeof selector === 'string' ? rowA[selector] : selector(rowA)
        const valueB = typeof selector === 'string' ? rowB[selector] : selector(rowB)
        if (direction === 'asc') {
          if (valueA < valueB) return -1
          if (valueA > valueB) return 1
          return 0
        } else if (direction === 'desc') {
          if (valueA > valueB) return -1
          if (valueA < valueB) return 1
          return 0
        }
      })
    }
  }
  const columns = [
    {
      name: 'Sample Id',
      selector: (row) => row.samples[0].sampleId,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Process Type',
      selector: (row) => row.processType,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
      cell: (row) => {
        const statusClass =
          row.status === 'Completed'
            ? 'badge   badge-light-success'
            : row.status === 'Error' || row.status === 'Canceled'
            ? 'badge   badge-light-danger'
            : 'badge   badge-light-warning'

        return <span className={`${statusClass}`}>{row.status.toString().toUpperCase()}</span>
      },
    },
    {
      name: 'Is Solved',
      selector: (row) => row.isSolved,
      sortable: true,
      filterable: true,
      cell: (row) => {
        return (
          <span className={`text-${row.isSolved === 'Yes' ? 'success' : 'danger'}`}>
            {row.isSolved}
          </span>
        )
      },
    },

    {
      name: 'Mean Coverage',
      selector: (row) => row.samples[0].sampleQuality.meanCoverage,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Actions',
      sortable: true,
      filterable: true,
      cell: (row) => {
        return (
          <div className='m-0'>
            <a
              href='#'
              className={clsx('btn btn-sm btn-flex fw-bold')}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <i className='fa fa-chevron-down'></i>
            </a>
            <div className='menu menu-sub menu-sub-dropdown w-100px w-md-200px' data-kt-menu='true'>
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Run Actions</div>
              </div>

              <div className='separator border-gray-200'></div>

              <div className='px-7 py-5'>
                <div className='mb-10'>
                  <button
                    className='btn btn-secondary'
                    onClick={() => {
                      Swal.fire({
                        html:
                          'Are you sure to ' + row.status === 'Running'
                            ? '  Are you sure to Cancel'
                            : ' Are you sure to Delete ' +
                              ' <strong>' +
                              row?.samples[0].sampleId +
                              '</strong> ?',
                        icon: 'warning',
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          await RunsService.deleteRun(row?.id)
                          Swal.fire(
                            row?.samples[0].sampleId + ' ' + row.status === 'Running'
                              ? ' Cancelled successfully'
                              : ' Removed  successfully!',
                            '',
                            'success'
                          )
                          getData()
                        }
                      })
                    }}
                  >
                    {' '}
                    <i className='fa fa-trash'></i>
                    {row.status === 'Running' ? 'Cancel' : ' Delete '} Run
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },

    // {
    //   name: 'Details',
    //   cell: (row) => {
    //     if (row.status === 'Submitted') {
    //       return (
    //         <span
    //           onClick={() => {
    //             Swal.fire({
    //               html:
    //                 'Are you sure to delete <strong>' + row?.samples[0].sampleId + '</strong> ?',
    //               icon: 'warning',
    //               confirmButtonText: 'Yes',
    //               showCancelButton: true,
    //               cancelButtonText: 'No',
    //             }).then(async (result) => {
    //               if (result.isConfirmed) {
    //                 await RunsService.deleteRun(row?.id)
    //                 Swal.fire(row?.samples[0].sampleId + ' Removed successfully!', '', 'success')
    //                 getData()
    //               }
    //             })
    //           }}
    //           className='btn  btn-secondary '
    //         >
    //           <i className='fa fa-close mx-2 '></i>
    //         </span>
    //       )
    //     } else if (
    //       row.status !== 'Error' &&
    //       row.status !== 'Canceled' &&
    //       row.status !== 'Submitted'
    //     ) {
    //       if (
    //         row.processType === 'som-test-tumor-rna' ||
    //         row.processType === 'som-test-tumor' ||
    //         row.processType === 'som-test-rna'
    //       ) {
    //         return (
    //           <span
    //             onClick={() => navigate(`/run-detail-somatic/${row?.id || row?.id}`)}
    //             className='btn  btn-primary '
    //           >
    //             <i className='fa fa-eye mx-2 '></i>
    //           </span>
    //         )
    //       } else {
    //         return (
    //           <span
    //             onClick={() => navigate(`/run-detail/${row?.id || row?.id}`)}
    //             className='btn  btn-primary '
    //           >
    //             <i className='fa fa-eye mx-2 '></i>
    //           </span>
    //         )
    //       }
    //     }
    //   },
    // },
  ]

  return (
    <div className='card'>
      <div className='card-body p-4'>
        {tableIsLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            {subHeaderComponent}
            <DataTable
              className='table-striped custom-filtered-table-runs'
              pagination
              expandableRows
              sortFunction={customSort}
              expandableRowsComponent={ExpandedComponent}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              onRowClicked={(row) => {}}
              columns={columns}
              data={runsDatas}
              expandOnRowClicked
            />
          </>
        )}
      </div>
    </div>
  )
}

export default RunsTable
