/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon} from '../../_metronic/helpers'
const Notifications = ({className}) => {
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title fw-bold text-dark'>Notifications</h3>
      </div>
      <div className='card-body pt-5 notifications-list'>
        <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
          <span className=' text-warning me-5'>
            <KTIcon iconName='abstract-26' className='text-warning fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-8'>
              Run #23 Completed
            </a>
            <span className='text-muted fw-semibold d-block fs-9'>2 Days Ago</span>
          </div>
        </div>
        <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
          <span className=' text-success me-5'>
            <KTIcon iconName='abstract-26' className='text-success fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-8'>
              Verison Updated to V2.0.1
            </a>
            <span className='text-muted fw-semibold d-block fs-9'>2 Days Ago</span>
          </div>
        </div>
        <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
          <span className=' text-danger me-5'>
            <KTIcon iconName='abstract-26' className='text-danger fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-8'>
              ClinVar Annatotator Updated
            </a>
            <span className='text-muted fw-semibold d-block fs-9'>2 Days Ago</span>
          </div>
        </div>
        <div className='d-flex align-items-center bg-light-info rounded p-5'>
          <span className=' text-info me-5'>
            <KTIcon iconName='abstract-26' className='text-info fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-8'>
              New Genome Assembly Now Available
            </a>
            <span className='text-muted fw-semibold d-block fs-9'>2 Days Ago</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Notifications}
