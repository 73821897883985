import React, {useState} from 'react'
import clsx from 'clsx'
import DataTable from 'react-data-table-component-with-filter'

const TraidList = ({selectedTraidList, setSelectedTraidList}) => {
  // Orijinal datayı tanımlayın
  const originalData = [
    {
      id: 1,
      status: 'Completed',
      score: 0.8,
      point: 3,
      trait:
        'lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua1',
    },
    {
      id: 2,
      status: 'Error',
      score: 1.2,
      trait:
        'lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua2',
      point: 2,
    },
    {
      id: 3,
      score: 1.3,
      point: 5,
      trait:
        'lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua3',
      status: 'Running',
    },
    {
      id: 4,
      score: 0.3,
      status: 'Canceled',
      trait:
        'lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua4',
      point: 4,
    },
    {
      id: 5,
      score: 1.8,
      status: 'Submitted',
      trait:
        'lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      point: 1,
    },
  ]

  // Filtrelenmiş datayı tutmak için state
  const [filteredData, setFilteredData] = useState(originalData)
  const [searchTerm, setSearchTerm] = useState('')

  // Arama filtresi fonksiyonu
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase()
    setSearchTerm(term)

    const filtered = originalData.filter((item) => item.trait.toLowerCase().includes(term))
    setFilteredData(filtered)
  }

  // Genişlikleri belirleyen columns yapısı
  const columns = [
    {
      name: 'Point',
      selector: (row) => row.point,
      sortable: true,
      filterable: false,
      width: '120px',
      cell: (row) => {
        const stars = []
        for (let i = 0; i < row.point; i++) {
          stars.push(
            <span
              style={{
                color: 'orange',
              }}
              key={i}
            >
              &#9733;
            </span>
          )
        }
        return <span>{stars}</span>
      },
    },
    {
      name: 'Trait',
      selector: (row) => row.trait,
      sortable: true,
      filterable: false,
      width: '900px',
    },
    {
      name: 'Score',
      selector: (row) => row.score,
      sortable: true,
      filterable: false,
      width: '150px',
      cell: (row) => {
        return (
          <span
            className={clsx('badge', {
              'badge-danger': row.score <= 1,
              'badge-warning': row.score < 1.3,
              'badge-success': row.score >= 1.3,
            })}
          >
            PRS = {row.score}{' '}
            {row.score < 1 ? (
              <i
                className='fa fa-chevron-down'
                style={{
                  color: 'white',
                  marginLeft: 5,
                }}
              ></i>
            ) : row.score < 1.3 ? (
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 'bold',
                }}
              >
                {`-`}
              </span>
            ) : (
              <i
                className='fa fa-chevron-up'
                style={{
                  color: 'white',
                  marginLeft: 5,
                }}
              ></i>
            )}
          </span>
        )
      },
    },
  ]

  const handleChange = ({selectedRows}) => {
    setSelectedTraidList(selectedRows)
  }

  return (
    <div className={`card px-6`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>Trait List</h3>
        <div className='py-4 px-2' style={{
          width: '65%',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='Search Trait...'
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className='card-toolbar align-items-center border-0 mt-4 py-0 px-2  '>
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#TraitDetailModal'
          >
            Detail
          </button>
        </div>
      </div>

      {/* Arama Kutusu */}

      <div className='card-body d-flex flex-wrap py-10 px-2 w-100'>
        <DataTable
          className='table-striped custom-filtered-table-prs-run'
          pagination
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer',
                backgroundColor: 'var(--table-bg)',
                '&:hover': {
                  backgroundColor: '#f3f3f3',
                },
              },
            },
          }}
          onRowClicked={(row) => {}}
          columns={columns}
          data={filteredData}
          selectableRows
          onSelectedRowsChange={handleChange}
          expandOnRowClicked
        />
      </div>
    </div>
  )
}

export {TraidList}