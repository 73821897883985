export let snvHeader = {
  // reportSelect: 'Child',
  Chrom: 'Chromosome',
  Start: 'Start Position',
  End: 'End Position',
  Ref: 'Reference',
  Alt: 'Alternative',
  Gene_refGene: 'Gene Reference',
  Func_refGene: 'Function',
  ExonicFunc_refGene: 'Exonic Function',
  tx: 'Transcript',
  exon: 'Exon',
  txChange: 'Alteration',
  aaChange: 'Amino Acid Change',
  Variant_Type: 'Variant Type',
  avsnp150: 'dbSNP Id',
  clinvar: 'ClinVar Annotation',
  CancerVar_Tier: 'CancerVar Tier',
  CancerVar_Evidence: 'CancerVar Evidence',
  cosmic91: 'COSMIC',
  OMIM: 'OMIM',
  Phenotype_MIM: 'Phenotype MIM',
  OrphaNumber: 'Orpha Number',
  Orpha: 'Orpha',
  ICGC_Id: 'ICGC Identifier',
  AD: 'Allelic Depth',
  AF: 'Allele Fraction',
  DP: 'Read Depth',
  DET: 'Detection',
  DETS: 'Detection Score',
  GeneInOncoKB: 'Gene in OncoKB',
  Oncogenic: 'Oncogenic',
  Level1: 'Level 1',
  Level2: 'Level 2',
  Level3A: 'Level 3A',
  Level3B: 'Level 3B',
  Level4: 'Level 4',
  LevelR1: 'Level R1',
  LevelR2: 'Level R2',
  Filter: 'Quality Filter',
  HighestLevel: 'Highest Level',
  HighestSensitiveLevel: 'Highest Sensitive Level',
  HighestResistanceLevel: 'Highest Resistance Level',
  TxCitations: 'Citations',
}
// "GT": "Genotype",

export let svHeader = {
  reportSelect: 'Child',
  igv: 'IGV',
  Chrom: 'SV Chromosome',
  Start: 'SV Start',
  End: 'SV End',
  Length: 'SV Length',
  SVType: 'SV Type',
  Ref: 'Reference',
  Alt: 'Alternative',
  CytoBand: 'Cytoband',
  GeneName: 'Gene Name',
  AnnotationMode: 'Annotation Mode',
  Tx: 'Transcription',
  TxStart: 'Transcription Start',
  TxEnd: 'Transcription End',
  OMIMID: 'OMIM ID',
  OMIMPhenotype: 'OMIM Phenotype',
  OMIMInheritance: 'OMIM Inheritance',
  GnomADPLI: 'GnomAD pLI',
  ExACPLI: 'ExAC pLI',
  ACMGClass: 'ACMG Classification',
  OncoKBSVType: 'OncoKB SV Type',
  OncoKBGene: 'OncoKB Gene',
  MutationEffect: 'Mutation Effect',
  MutationEffectCitations: 'Mutation Effect Citations',
  Oncogenic: 'Oncogenic',
  GeneInOncoKB: 'Gene in OncoKB',
  VariantInOncoKB: 'Variant in OncoKB',
  Level1: 'Level 1',
  Level2: 'Level 2',
  Level3A: 'Level 3A',
  Level3B: 'Level 3B',
  Level4: 'Level 4',
  LevelR1: 'Level R1',
  LevelR2: 'Level R2',
  HighestLevel: 'Highest Level',
  HighestSensitiveLevel: 'Highest Sensitive Level',
  HighestResistanceLevel: 'Highest Resistance Level',
  TxCitations: 'Tx Citations',
  LevelDx1: 'Level Dx1',
  LevelDx2: 'Level Dx2',
  LevelDx3: 'Level Dx3',
  HighestDxLevel: 'Highest Dx Level',
  DxCitations: 'Dx Citations',
  LevelPx1: 'Level Px1',
  LevelPx2: 'Level Px2',
  LevelPx3: 'Level Px3',
  HighestPxLevel: 'Highest Px Level',
  PxCitations: 'Px Citations',
}

export let runDetailHeader = {
  reportSelect: 'Child',
  SV_chrom: 'SV Chromosome',
  SV_start: 'SV Start',
  SV_end: 'SV End',
  SV_length: 'SV Length',
  SV_type: 'SV Type',
  REF: 'Reference',
  ALT: 'Alternative',
  CytoBand: 'Cytoband',
  Gene_name: 'Gene Name',
  Annotation_mode: 'Annotation Mode',
  Tx: 'Transcription',
  TxStart: 'Transcription Start',
  TxEnd: 'Transcription End',
  OMIMID: 'OMIM ID',
  OMIMPhenotype: 'OMIM Phenotype',
  OMIMInheritance: 'OMIM Inheritance',
  GnomADPLI: 'GnomAD pLI',
  ExACPLI: 'ExAC pLI',
  ACMGClass: 'ACMG Classification',
  OncoKBSVType: 'OncoKB SV Type',
  OncoKBGene: 'OncoKB Gene',
  MutationEffect: 'Mutation Effect',
  MutationEffectCitations: 'Mutation Effect Citations',
  Oncogenic: 'Oncogenic',
  GeneInOncoKB: 'Gene in OncoKB',
  VariantInOncoKB: 'Variant in OncoKB',
  Level1: 'Level 1',
  Level2: 'Level 2',
  Level3A: 'Level 3A',
  Level3B: 'Level 3B',
  Level4: 'Level 4',
  LevelR1: 'Level R1',
  LevelR2: 'Level R2',
  HighestLevel: 'Highest Level',
  HighestSensitiveLevel: 'Highest Sensitive Level',
  HighestResistanceLevel: 'Highest Resistance Level',
  TxCitations: 'Tx Citations',
  LevelDx1: 'Level Dx1',
  LevelDx2: 'Level Dx2',
  LevelDx3: 'Level Dx3',
  HighestDxLevel: 'Highest Dx Level',
  DxCitations: 'Dx Citations',
  LevelPx1: 'Level Px1',
  LevelPx2: 'Level Px2',
  LevelPx3: 'Level Px3',
  HighestPxLevel: 'Highest Px Level',
  PxCitations: 'Px Citations',
}

export let fusionHeader = {
  // reportSelect: 'Child',
  Fusion: 'Fusion',
  LeftGene: 'Left Gene',
  LeftBreakpoint: 'Left Breakpoint',
  RightGene: 'Right Gene',
  RightBreakpoint: 'Right Breakpoint',
  FFPM: 'FFPM',
  Annots: 'Annotations',
  MutationEffect: 'Mutation Effect',
  MutationEffectCitations: 'Mutation Effect Citations',
  Oncogenic: 'Oncogenic',
  Level1: 'Level 1',
  Level2: 'Level 2',
  Level3A: 'Level 3A',
  Level3B: 'Level 3B',
  Level4: 'Level 4',
  LevelR1: 'Level R1',
  LevelR2: 'Level R2',
  HighestLevel: 'Highest Level',
  HighestSensitiveLevel: 'Highest Sensitive Level',
  HighestResistanceLevel: 'Highest Resistance Level',
  TxCitations: 'Treatment Citations',
  LevelDx1: 'Level Dx1',
  LevelDx2: 'Level Dx2',
  LevelDx3: 'Level Dx3',
  HighestDxLevel: 'Highest Dx Level',
  DxCitations: 'Diagnostic Citations',
  LevelPx1: 'Level Px1',
  LevelPx2: 'Level Px2',
  LevelPx3: 'Level Px3',
  HighestPxLevel: 'Highest Px Level',
  PxCitations: 'Prognostic Citations',
}

export let afbHeader = {
  variantId: 'Variant ID',
  rsid: 'rsID',
  vepAnnotation: 'VEP Annotation',
  vepClinicalSignificance: 'benign',
  alleleCount: 'Allele Count',
  alleleNumber: 'Allele Number',
  alleleFrequency: 'Allele Frequency',
  nHomozygotes: 'Number of Homozygotes',
}
export let afbVariantHeader = {
  phenotypeId: 'PhenoType ID',
  alleleCount: 'Allele Count',
  alleleNumber: 'Allele Number',
  alleleFrequency: 'Allele Frequency',
  nHomozygotes: 'Number of Homozygotes',
}
