/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import PrsForm from '../../components/NewRun/Forms/PrsForm'

const CreatePRSPage: FC = () => {
  // sampleData,
  // setSampleData,
  // validateSampleId,
  // fileType,
  // qrCodeData,
  // setQrCodeData,

  const [sampleData, SetSampleData] = useState([])
  const [fileType, SetFileType] = useState('')
  const [qrCodeData, SetQrCodeData] = useState('')
  const [validateSampleId, SetValidateSampleId] = useState(false)

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PRS'})}</PageTitle>
      <PrsForm
        sampleData={sampleData}
        fileType={fileType}
        qrCodeData={qrCodeData}
        setQrCodeData={SetQrCodeData}
        validateSampleId={validateSampleId}
        setSampleData={SetSampleData}
      />
    </>
  )
}

export {CreatePRSPage}
