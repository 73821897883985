/* eslint-disable jsx-a11y/anchor-is-valid */

import Swal from 'sweetalert2'

const NewVaritanFiltering = ({
  processType,
  currentRunId,
  sampleId,
  clickedHPOItems,
  selectedHPOItem,
  previewStr,
  selectedGeneList,
  geneSymbolListStr,
}) => {
  const generateUrlWithUserCredentianls = (type, clinic = false) => {

    if (selectedHPOItem.id !== undefined) {
      return '#'
    }
    var url =  clinic ? 'somatic-detail-clinic' : 'somatic-detail'
    if (clickedHPOItems.length === 1 && previewStr.length === 0) {
      return `/${url}/${currentRunId}&${sampleId}&${type}`
    }
    if (clickedHPOItems.length > 0 || geneSymbolListStr.length > 0) {
      if (selectedGeneList.length === undefined) {
        return '#'
      } else {
        return `/${url}/${currentRunId}&${sampleId}&${type}&${selectedGeneList[0].genesListId}`
      }
    } else {
      return `/${url}/${currentRunId}&${sampleId}&${type}`
    }
  }
  const handleClick = (event, id, clinic = false) => {
    event.preventDefault()
    const url = generateUrlWithUserCredentianls(id, clinic)
    if (url === '#') {
      Swal.fire('Warning!', 'You must save if you want to apply your genelist.', 'warning')
    } else {
      window.open(url, '_blank')
    }
  }
  return (
    // Sample Information Card Start
    <div className={`card px-6`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Variants</span>
        </h3>
      </div>
      <div className='variantFilteringBody card-body d-flex flex-wrap py-10 px-2 w-100'>
        {(processType === 'som-test-tumor-rna' || processType === 'som-test-tumor') && (
          <>
            <div className='col-md-3 my-5 text-center'>
              <a
                // href={`/somatic-detail/${currentRunId}&${sampleId}&snv`}
                onClick={(event) => {
                  handleClick(event, 'snv', 'clinic')
                }}
                target='_blank'
                className='btn btn-dark btn-lg  '
                rel='noreferrer'
              >
                {' '}
                Clinical SNVs/Short INDEL
              </a>
            </div>
            <div className='col-md-3 my-5 text-center'>
              <a
                // href={`/somatic-detail/${currentRunId}&${sampleId}&snv`}
                onClick={(event) => {
                  handleClick(event, 'snv')
                }}
                target='_blank'
                className='btn btn-dark btn-lg  '
                rel='noreferrer'
              >
                {' '}
                SNVs/Short INDELs
              </a>
            </div>

            <div className='col-md-3 my-5 text-center'>
              <a
                // href={`/somatic-detail/${currentRunId}&${sampleId}&sv`}
                onClick={(event) => {
                  handleClick(event, 'sv')
                }}
                target='_blank'
                className='btn btn-dark btn-lg  '
                rel='noreferrer'
              >
                {' '}
                Structural Variants / CNVs
              </a>
            </div>
          </>
        )}
        {(processType === 'som-test-tumor-rna' || processType === 'som-test-rna') && (
          <div className='col-md-3 my-5 text-center'>
            <a
              // href={`/somatic-detail/${currentRunId}&${sampleId}&fusion`}
              onClick={(event) => {
                handleClick(event, 'fusion')
              }}
              target='_blank'
              className='btn btn-dark btn-lg  '
              rel='noreferrer'
            >
              {' '}
              Fusion Genes
            </a>
          </div>
        )}
      </div>
    </div>
    // Sample Information Card End
  )
}

export {NewVaritanFiltering}
