import React, {useEffect} from 'react'
import FileInput from '../FileInput'
import OptionsSelect from '../OptionsSelect'

const SomTestTumorRnaFormFiles = ({sampleData, setSampleData, selectedProcessType, fileType,enrichmentOrRegion}) => {
  return (
    <div>
      {sampleData.map((sample, index) => (
        <div
          className=' d-flex align-items-center  flex-wrap justify-content-between mt-5 '
          key={index}
        >
          {fileType === 'fastq' && (
            <>
              <FileInput
                label={'Upload FASTQ R1 File (DNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR1 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File (DNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R1 File (RNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR1RNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File (RNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2RNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (DNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (RNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFileRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}

          {fileType === 'bam' && (
            <>
              <FileInput
                label={'Upload BAM File (DNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bam = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload BAM File (RNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bamRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (DNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (RNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFileRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default SomTestTumorRnaFormFiles
