import React, { useEffect, useState } from "react";

const ProteinModal = ({ isOpen, onClose, gene, data, sampleName }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (isOpen) {
      if (!document.querySelector(`script[src="https://proteinpaint.stjude.org/bin/proteinpaint.js"]`)) {
        const script = document.createElement("script");
        script.src = "https://proteinpaint.stjude.org/bin/proteinpaint.js";
        script.charset = "utf-8";
        script.async = true;

        script.onload = () => {
          setLoading(false);
          if (window.runproteinpaint) {
            clearProteinPaint();
            initProteinPaint();
          }
        };

        document.body.appendChild(script);
      } else {
        setLoading(false);
        clearProteinPaint();
        initProteinPaint();
      }
    }
  }, [isOpen]);

  const clearProteinPaint = () => {
    const container = document.getElementById("protein-paint-container");
    if (container) {
      container.innerHTML = "";
    }
  };
  const initProteinPaint = () => {
    const classMap = {
      "functionalMapping": {
        "exonic": "E",
        "splicing": "L",
        "ncRNA": "noncoding",
        "UTR5": "Utr5",
        "UTR3": "Utr3",
        "intronic": "Intron",
        "upstream": null,
        "downstream": null,
        "intergenic": "X"
      },
      "exonicFunctionalMapping": {
        "frameshift insertion": "F",
        "frameshift deletion": "F",
        "frameshift block substitution": "F",
        "stopgain": "N",
        "stoploss": "M",
        "nonframeshift insertion": "I",
        "nonframeshift deletion": "D",
        "nonframeshift block substitution": "M",
        "nonsynonymous SNV": "M",
        "synonymous SNV": "S",
        "unknown": "X"
      }
    }

    if (window.runproteinpaint) {

      const custom_variants = data.map((d) => {
        const dataClass = d.Func_refGene === 'exonic' ? classMap.exonicFunctionalMapping[d.ExonicFunc_refGene] : classMap.functionalMapping[d.Func_refGene];

        return {
          chr: d.Chrom,
          pos: d.Start,
          mname: d.aaChange,
          class: dataClass,
          dt: 1,
          ref: d.Ref,
          alt: d.Alt,
        }
      });
      window.runproteinpaint({
        host: "https://proteinpaint.stjude.org",
        holder: document.getElementById("protein-paint-container"),
        parseurl: true,
        noheader: true,
        // hidegenelegend: true,
        nobox: true,
        genome: "hg38",
        gene: gene,
        tracks: [
          {
            type: "mds3",
            name: sampleName || gene,
            custom_variants
            // custom_variants: [
            //   {
            //     "chr": "chr3",
            //     "pos": 89472514,
            //     // "mname": "p.R914H",
            //     "class": "m",
            //     "dt": 1,
            //     "ref": "G",
            //     "alt": "A",
            //   },
            //   {
            //     "chr": "chr3",
            //     "pos": 89472543,
            //     // "mname": "p.W924R",
            //     "class": "m",
            //     "dt": 1,
            //     "ref": "T",
            //     "alt": "C"
            //   }
            // ],
          },
        ],
      });
    }
  };

  return (
    <div className={`modal fade ${isOpen ? 'show d-block' : 'd-none'}`} tabIndex="-1" role="dialog">
      {isOpen && <div className="modal-backdrop fade show" onClick={onClose}></div>}

      <div className="modal-dialog modal-lg" role="document" style={{
        maxWidth: "75%",
        minWidth: "50%",
        zIndex: 9999,
      }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title d-flex align-items-center gap-4">
              <span className="fst-italic">{gene}</span>
              <span>Protein Paint Visualization</span>
            </h5>
            <button type="button" className="btn btn-primary py-1 px-2" onClick={onClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{
            overflow: "auto",
          }}>
            {loading && (
              <div className="h-auto text-center py-2">
                <div className='spinner-grow text-primary m-auto py-2' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            )}
            <div id="protein-paint-container" style={{ width: "100%", height: "600px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProteinModal;
