import React, {useState} from 'react'
import FileInput from '../FileInput'
import QRCodeScanner from './QRCodeScanner'

const PrsForm = ({
  sampleData,
  setSampleData,
  validateSampleId,
  fileType,
  qrCodeData,
  setQrCodeData,
}) => {
  const [accordionStep, setAccordionStep] = useState(1)
  const [isStep1Complete, setIsStep1Complete] = useState(false)
  const [isStep2Complete, setIsStep2Complete] = useState(false)
  const [isManualEntry, setIsManualEntry] = useState(false)

  return (
    <div className='row'>
      <div className='card card-custom'>
        <div className='card-body'>
          <div className='accordion' id='kt_accordion_1'>
            {/* Step 1: Scan QR */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                <button
                  className={`accordion-button fs-4 fw-bold ${
                    accordionStep === 1 ? '' : 'collapsed'
                  }`}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_1'
                  aria-expanded={accordionStep === 1}
                  aria-controls='kt_accordion_1_body_1'
                  disabled={isStep1Complete}
                >
                  {isStep1Complete || accordionStep > 1 ? (
                    <i className='fa fa-check-circle text-success mx-2'></i>
                  ) : (
                    ''
                  )}
                  Scan QR
                </button>
              </h2>
              <div
                id='kt_accordion_1_body_1'
                className={`accordion-collapse collapse ${accordionStep === 1 ? 'show' : ''}`}
                aria-labelledby='kt_accordion_1_header_1'
                data-bs-parent='#kt_accordion_1'
              >
                <div className='accordion-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <QRCodeScanner
                        qrCodeData={qrCodeData}
                        setQrCodeData={setQrCodeData}
                        setIsStep1Complete={setIsStep1Complete}
                        setAccordionStep={setAccordionStep}
                      />
                    </div>
                    <div className='col-md-8'>
                      <h4> Scan Your QR Code</h4>
                      <p>
                        Position your QR code over the camera to complete the first step. Once the
                        scan is successful, you’ll instantly access the relevant information.
                      </p>
                      <ul>
                        <li>
                          <strong>Keep the Camera Steady:</strong> Hold your camera steady while
                          scanning the QR code to ensure a clear image.
                        </li>
                        <li>
                          <strong>Adequate Lighting:</strong> Make sure there is enough light on the
                          QR code. Insufficient lighting may make scanning difficult.
                        </li>
                        <li>
                          <strong>Full Code Visibility:</strong> The entire QR code should be
                          visible on the camera screen. Avoid holding the code too far or too close.
                        </li>
                        <li>
                          <strong>Camera Permission:</strong> If the scanner is within an app,
                          ensure you have granted camera access.
                        </li>
                        <li>
                          <strong>Code Clarity:</strong> If there are scratches or blurry areas on
                          the QR code, it may not be readable. Ensure the code is clean and clear.
                        </li>
                      </ul>
                      <button
                        className='btn btn-secondary'
                        onClick={() => setIsManualEntry(!isManualEntry)}
                      >
                        Enter Code Manually
                      </button>
                      {isManualEntry && (
                        <>
                          <input
                            className='form-control mt-3 mb-4'
                            placeholder='Enter Code'
                            onChange={(e) => setQrCodeData(e.target.value)}
                            value={qrCodeData}
                          />
                          <button
                            className='btn btn-primary'
                            onClick={() => {
                              setAccordionStep(2)
                              setIsStep1Complete(true)
                            }}
                          >
                            Continue <i className='fa fa-arrow-right'></i>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Step 2: Template */}
            <div className='accordion-item mt-3'>
              <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                <button
                  className={`accordion-button fs-4 fw-bold ${
                    accordionStep === 2 ? '' : 'collapsed'
                  }`}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_2'
                  aria-expanded={accordionStep === 2}
                  aria-controls='kt_accordion_1_body_2'
                  disabled={accordionStep < 2}
                >
                  {isStep2Complete || accordionStep > 2 ? (
                    <i className='fa fa-check-circle text-success mx-2'></i>
                  ) : (
                    ''
                  )}
                  Template
                </button>
              </h2>
              <div
                id='kt_accordion_1_body_2'
                className={`accordion-collapse collapse ${accordionStep === 2 ? 'show' : ''}`}
                aria-labelledby='kt_accordion_1_header_2'
                data-bs-parent='#kt_accordion_1'
              >
                <div className='accordion-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label fs-6 fw-bold'>Template Input</label>
                      <input
                        className='form-control mb-4'
                        placeholder='Enter Template Info'
                        onChange={(e) => console.log(e.target.value)}
                      />
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          setAccordionStep(3)
                          setIsStep2Complete(true)
                        }}
                      >
                        Continue <i className='fa fa-arrow-right'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Step 3: Sample Informations */}
            <div className='accordion-item mt-3'>
              <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                <button
                  className={`accordion-button fs-4 fw-bold ${
                    accordionStep === 3 ? '' : 'collapsed'
                  }`}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_3'
                  aria-expanded={accordionStep === 3}
                  aria-controls='kt_accordion_1_body_3'
                  disabled={accordionStep < 3}
                >
                  Sample Informations
                </button>
              </h2>
              <div
                id='kt_accordion_1_body_3'
                className={`accordion-collapse collapse ${accordionStep === 3 ? 'show' : ''}`}
                aria-labelledby='kt_accordion_1_header_3'
                data-bs-parent='#kt_accordion_1'
              >
                <div className='accordion-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label fs-6 fw-bold'>Sample ID</label>
                      <input
                        className='form-control mb-4'
                        placeholder='Sample ID'
                        value={qrCodeData}
                        disabled
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className='form-label fs-6 fw-bold'>Name</label>
                      <input className='form-control mb-4' placeholder='Name' />
                    </div>
                    <div className='col-md-6'>
                      <label className='form-label fs-6 fw-bold'>Surname</label>
                      <input className='form-control mb-4' placeholder='Surname' />
                    </div>
                    <div className='col-md-6'>
                      <label className='form-label fs-6 fw-bold'>Birthday</label>
                      <input className='form-control mb-4' placeholder='Birthday' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrsForm
