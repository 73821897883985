import React, {useEffect} from 'react'
import FileInput from '../FileInput'

const WesFormFiles = ({
  sampleData,
  setSampleData,
  selectedProcessType,
  fileType,
  enrichmentOrRegion,
}) => {
  console.log(sampleData,enrichmentOrRegion)
  return (
    <div>
      {sampleData.map((sample, index) => (
        <div
          className=' d-flex align-items-center  flex-wrap justify-content-between mt-5 p-2'
          key={index}
        >
          {fileType === 'fastq' && (
            <>
              <FileInput
                label={'Upload FASTQ R1 File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  console.log(clonedSampleData)
                  clonedSampleData[index].files.fqR1 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {fileType === 'bam' && (
            <>
              <FileInput
                label={'Upload BAM File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bam = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {fileType === 'vcf' && (
            <>
              <FileInput
                label={'Upload VCF'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.vcf = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {enrichmentOrRegion === 'region' &&
            fileType !== 'vcf' &&
            (sampleData[0].files.regionsFile === undefined ||
              sampleData[0].files.regionsFile === null ||
              sampleData[0].files.regionsFile === '' ||
              sampleData[0].files?.regionsFile?.name === undefined) && (
              <FileInput
                label={'Upload Regions File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            )}
        </div>
      ))}
    </div>
  )
}

export default WesFormFiles
